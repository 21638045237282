import { SvgAttr as SvgAttribute } from '../../utils/icon.interface';

export function GPay(props: SvgAttribute) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      {...props}
      fill="none"
    >
      <title>GPay</title>
      <path d="M9 18A9 9 0 1 0 9 0a9 9 0 0 0 0 18Z" fill="#fff" />
      <path
        d="M9 18a8.959 8.959 0 0 1-4.735-1.344L10.295.092A8.999 8.999 0 0 1 9 18Z"
        fill="#6E7BF2"
      />
      <path
        d="M5.947 8.96a1.907 1.907 0 0 0-.046-.45H3.83v.819h1.216a1.08 1.08 0 0 1-.451.715l-.004.027.654.508.046.004c.415-.384.656-.95.656-1.622Z"
        fill="#4285F4"
      />
      <path
        d="M3.83 11.117c.595 0 1.095-.196 1.46-.534l-.695-.54a1.304 1.304 0 0 1-.765.221 1.33 1.33 0 0 1-1.255-.917l-.026.003-.68.526-.01.024a2.2 2.2 0 0 0 1.97 1.217Z"
        fill="#34A853"
      />
      <path
        d="M2.574 9.348a1.35 1.35 0 0 1-.002-.874v-.028l-.69-.536-.023.01a2.201 2.201 0 0 0 0 1.981l.715-.553Z"
        fill="#FBBC05"
      />
      <path
        d="M3.83 7.559c.316-.005.621.112.852.329l.623-.608c-.4-.375-.929-.58-1.476-.574-.835 0-1.597.47-1.971 1.217l.713.553c.18-.544.686-.914 1.259-.917Z"
        fill="#EB4335"
      />
      <path
        d="M9.42 9.157v1.603h-.513V6.8h1.359c.327-.007.644.116.88.342a1.14 1.14 0 0 1 0 1.677 1.231 1.231 0 0 1-.88.337h-.847Zm0-1.87V8.67h.858a.674.674 0 0 0 .506-.205.671.671 0 0 0 .019-.95l-.005-.005-.014-.013a.67.67 0 0 0-.506-.21h-.859Zm3.274.675c.379 0 .678.1.897.301.219.2.328.476.328.827v1.67h-.49v-.377h-.021c-.212.31-.495.465-.848.465a1.1 1.1 0 0 1-.754-.266.846.846 0 0 1-.303-.663.803.803 0 0 1 .32-.669c.213-.166.498-.248.855-.248.26-.01.52.047.751.165V9.05a.576.576 0 0 0-.211-.45.727.727 0 0 0-.495-.184.788.788 0 0 0-.679.359l-.45-.282c.245-.354.612-.531 1.1-.531ZM12.03 9.93c0 .132.063.255.17.332a.627.627 0 0 0 .398.133.816.816 0 0 0 .576-.238.741.741 0 0 0 .255-.56c-.16-.125-.383-.19-.67-.19a.869.869 0 0 0-.52.15.452.452 0 0 0-.209.373Zm4.703-1.88-1.71 3.904h-.53l.635-1.366-1.126-2.538h.557l.814 1.946h.01l.792-1.946h.558Z"
        fill="#fff"
      />
    </svg>
  );
}
