import '#lib/authentication/authedFetchInterceptor';
import '../global.css';

import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { AppProps } from 'next/app';
import Script from 'next/script';

import { BuyerCannotCheckoutAlert } from '#components/BuyerCannotCheckoutAlert';
import { FeatureFlagProvider } from '#components/FeatureFlagProvider';
import { RootErrorBoundary } from '#components/RootErrorBoundary';
import {
  AuthenticationProvider,
  useAuthentication,
} from '#contexts/Authentication';
import {
  ConsentBanner,
  ConsentProvider,
  useConsent,
  useIsConsentBannerDisabled,
} from '#contexts/Consent';
import { DatadogInit } from '#lib/datadog/datadog.client';
import { environment } from '#lib/environment.mjs';
import { FetchError } from '#lib/error';
import { AnalyticsProvider } from '#lib/report/AnalyticsProvider';
import { GTagScript } from '#lib/report/Gtag';

import { VerifiedRedirect } from '../components/pages/VerificationPage/VerifiedRedirect';

if (environment.NEXT_PUBLIC_ENABLE_MSW) {
  require('../test/initMocks');
}

/**
 * We only want to load the support widget if we're in production, and if the user has logged in
 * This won't hide the widget if a user logs out again. But, we mostly care about hiding
 * it from users that have never registered at all.
 */
function SupportWidget() {
  const { status } = useAuthentication();
  const isConsentBannerDisabled = useIsConsentBannerDisabled();
  const { consent } = useConsent();

  const isConsentAccepted =
    (consent.status === 'ready' && consent.value.functional) ||
    isConsentBannerDisabled;

  const isVisible =
    environment.NEXT_PUBLIC_ENABLE_SUPPORT_WIDGET &&
    status === 'authenticated' &&
    isConsentAccepted;
  if (!isVisible) return null;

  const HUBSPOT_SNIPPET_SRC = '//js.hs-scripts.com/139645065.js';
  return (
    <Script
      id="hs-script-loader"
      src={HUBSPOT_SNIPPET_SRC}
      strategy="lazyOnload"
    />
  );
}

function TrustpilotScript() {
  const isConsentBannerDisabled = useIsConsentBannerDisabled();
  const { consent } = useConsent();

  const isConsentAccepted =
    consent.status === 'ready' && consent.value.marketing;

  const shouldLoadScript = isConsentAccepted || isConsentBannerDisabled;

  return shouldLoadScript ? (
    <Script
      id="trustpilot-script-loader"
      src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      strategy="lazyOnload"
    />
  ) : null;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        // There's no point retrying if the error is a 404
        if (error instanceof FetchError && error.context.statusCode === 404) {
          return false;
        }

        // Otherwise retry up to 3 times
        return failureCount < 3;
      },
    },
  },
});

const QogitaApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={pageProps.dehydratedState}>
        <AuthenticationProvider>
          <FeatureFlagProvider initialState={pageProps.featureFlagState}>
            <ConsentProvider>
              <AnalyticsProvider
                writeKey={environment.NEXT_PUBLIC_SEGMENT_WRITE_KEY}
              >
                <VerifiedRedirect />
                <RootErrorBoundary>
                  <Component {...pageProps} />
                  <BuyerCannotCheckoutAlert />
                </RootErrorBoundary>
              </AnalyticsProvider>
              <GTagScript />
              <SupportWidget />
              <TrustpilotScript />
              <ConsentBanner />
              <DatadogInit />
            </ConsentProvider>
          </FeatureFlagProvider>
        </AuthenticationProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </HydrationBoundary>
    </QueryClientProvider>
  );
};

export default QogitaApp;
