import { ShoppingCartIcon } from '@heroicons/react/24/solid';
import Link from 'next/link';

import { useActiveCart } from '#hooks/api/useActiveCart';
import { getShortenedValueWithSuffix } from '#lib/number';

export const MastheadCart = (): JSX.Element => {
  const { data: activeCart } = useActiveCart();
  const cartLinesCount = activeCart?.cartlinesCount ?? 0;

  return (
    <Link
      href="/cart/"
      className="rounded-full hover:bg-gray-900 hover:bg-opacity-10"
      aria-label="Your cart"
    >
      {cartLinesCount > 0 ? (
        <div className="relative">
          <ShoppingCartIcon className="h-6 w-6 fill-current sm:h-8 sm:w-8" />
          <div className="bg-primary-700 absolute -top-0.5 right-0 rounded-full p-0.5 font-mono text-xs font-bold leading-none text-white antialiased">
            {getShortenedValueWithSuffix(cartLinesCount, 1)}
          </div>
        </div>
      ) : (
        <ShoppingCartIcon className="h-6 w-6 fill-current sm:h-8 sm:w-8" />
      )}
    </Link>
  );
};
