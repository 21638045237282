import { datadogLogs } from '@datadog/browser-logs';

import { isDatadogInitialized } from './datadog';
import { Queue } from './queue';

if (!process.env.NEXT_PUBLIC_VERCEL_ENV) {
  throw new Error('NEXT_PUBLIC_VERCEL_ENV is not defined');
}

const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) {
    return error.message;
  }
  if (typeof error === 'string') {
    return error;
  }

  return 'Unable to determine error message';
};

const errorQueue = new Queue<{
  errorMessage: string;
  context?: Record<string, unknown>;
  errorInstance?: Error;
}>();

export const logError = (error: unknown, context?: Record<string, unknown>) => {
  const errorMessage = getErrorMessage(error);
  const errorInstance = error instanceof Error ? error : undefined;

  switch (process.env.NEXT_PUBLIC_VERCEL_ENV) {
    case 'production':
      if (isDatadogInitialized) {
        datadogLogs.logger.error(errorMessage, context, errorInstance);
      } else {
        errorQueue.enqueue({ errorMessage, context, errorInstance });
      }
      break;

    case 'preview':
      if (isDatadogInitialized) {
        datadogLogs.logger.error(errorMessage, context, errorInstance);
      } else {
        errorQueue.enqueue({ errorMessage, context, errorInstance });
      }
      console.error(error);
      break;

    default:
      console.error(error);
  }
};

export const flushErrorQueue = () => {
  errorQueue.flush(({ errorMessage, context, errorInstance }) => {
    datadogLogs.logger.error(errorMessage, context, errorInstance);
  });
};

const warningQueue = new Queue<{
  errorMessage: string;
  context?: Record<string, unknown>;
  errorInstance?: Error;
}>();

export const logWarning = (
  error: unknown,
  context?: Record<string, unknown>,
) => {
  const errorMessage = getErrorMessage(error);
  const errorInstance = error instanceof Error ? error : undefined;

  switch (process.env.NEXT_PUBLIC_VERCEL_ENV) {
    case 'production':
      if (isDatadogInitialized) {
        datadogLogs.logger.warn(errorMessage, context, errorInstance);
      } else {
        warningQueue.enqueue({ errorMessage, context, errorInstance });
      }
      break;

    case 'preview':
      if (isDatadogInitialized) {
        datadogLogs.logger.warn(errorMessage, context, errorInstance);
      } else {
        warningQueue.enqueue({ errorMessage, context, errorInstance });
      }
      console.warn(error);
      break;

    default:
      console.warn(error);
  }
};

export const flushWarningQueue = () => {
  warningQueue.flush(({ errorMessage, context, errorInstance }) => {
    datadogLogs.logger.warn(errorMessage, context, errorInstance);
  });
};
