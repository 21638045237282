import 'flag-icons/css/flag-icons.min.css';

import { logError } from '@qogita/logging';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import { useState } from 'react';

import { ErrorBox, InformationBox } from '#components/Boxes';
import { CountryFieldOld } from '#components/CountryFieldOld';
import { CurrencyFieldOld } from '#components/CurrencyFieldOld';
import { Modal } from '#components/Modal';
import { useInfo } from '#hooks/api/useInfo';
import {
  LocalisationPreferences,
  useLocalisationPreferences,
} from '#hooks/api/useLocalisationPreferences';
import { useUpdateLocalisationPreferences } from '#hooks/api/useUpdateLocalisationPreferences';
import { INFO_DATA_SNAPSHOT } from '#lib/info';

const UPDATE_LOCALISATION_ERROR_CATCH_ALL =
  'There was a problem updating your localisation preferences. Please try again or speak to a member of our team if the issue persists.';

type MastheadLocalisationProps = {
  className?: string;
};
export const MastheadLocalisation = ({
  className,
}: MastheadLocalisationProps) => {
  const { data: infoData } = useInfo();
  const info = infoData ?? INFO_DATA_SNAPSHOT;
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const { country, currency } = useLocalisationPreferences();
  const { setLocalisation, isUpdatingProfile, updateProfileError } =
    useUpdateLocalisationPreferences();

  const handleProfileSubmit = async ({
    country,
    currency,
  }: LocalisationPreferences) => {
    try {
      await setLocalisation({
        country,
        currency,
      });
      setShowUpdateDialog(false);
    } catch (error) {
      logError(error);
    }
  };
  const countryCodeClass = `fi-${country?.toLowerCase()}` ?? 'fi-gb';
  return (
    <>
      <button
        type="button"
        className={clsx(
          'fib fis border-info-700 hidden h-[1.625rem] w-[1.625rem] items-center justify-center overflow-hidden rounded-full border shadow-[inset_0_0_0_1px_rgba(255,255,255)] sm:flex',
          className,
          countryCodeClass,
        )}
        onClick={() => setShowUpdateDialog(true)}
        aria-label="Update shipping location and currency"
      />
      <Modal
        open={showUpdateDialog}
        onClose={() => {
          setShowUpdateDialog(false);
        }}
        size="normal"
      >
        <Modal.Header>
          <Modal.Title>Update shopping preferences</Modal.Title>
        </Modal.Header>
        <Formik<LocalisationPreferences>
          initialValues={{
            country,
            currency,
          }}
          onSubmit={handleProfileSubmit}
          enableReinitialize
        >
          {({ values }) => (
            <Form>
              <Modal.Body>
                <div className="flex flex-col gap-6 pl-3 md:w-11/12">
                  <p>
                    Select your current shipping location or choose your
                    preferred payment currency.
                  </p>
                  <div>
                    <label
                      className="mb-2 block cursor-pointer text-sm font-bold"
                      htmlFor="country"
                    >
                      Shipping country
                    </label>
                    <CountryFieldOld
                      id="country"
                      className="block w-full rounded border p-2 shadow-inner"
                      name="country"
                      required
                    />
                    {values.country !== undefined &&
                    !info.allowedShippingCountries.includes(values.country) ? (
                      <InformationBox className="mt-2 p-2">
                        We currently cannot ship to this region. You can still
                        browse our catalog.
                      </InformationBox>
                    ) : null}
                  </div>
                  <div>
                    <label
                      className="mb-2 block cursor-pointer text-sm font-bold"
                      htmlFor="currency"
                    >
                      Currency
                    </label>
                    <CurrencyFieldOld
                      id="currency"
                      className="mb-2 block w-full rounded border p-2 shadow-inner"
                      name="currency"
                      required
                    />
                  </div>
                </div>
                {updateProfileError ? (
                  <ErrorBox className="p-2 text-sm">
                    {UPDATE_LOCALISATION_ERROR_CATCH_ALL}
                  </ErrorBox>
                ) : null}
              </Modal.Body>
              <Modal.Footer>
                <Modal.Actions>
                  <button
                    type="button"
                    className="rounded px-4 py-2 shadow"
                    onClick={() => setShowUpdateDialog(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-primary-700 rounded px-4 py-2 text-white shadow disabled:opacity-60"
                    disabled={isUpdatingProfile}
                  >
                    Save
                  </button>
                </Modal.Actions>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
