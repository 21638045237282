import { yupResolver } from '@hookform/resolvers/yup';
import { logWarning } from '@qogita/logging';
import { Anchor, Button, QogitaLogoWordmark } from '@qogita/ui';
import clsx from 'clsx';
import Link from 'next/link';
import { ReactNode, useRef } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { ErrorBox, SuccessBox } from '#components/Boxes';
import {
  AmexIcon,
  ApplePayIcon,
  GooglePayIcon,
  IdealIcon,
  InstagramIcon,
  LinkedinIcon,
  MasterCardIcon,
  TwitterIcon,
  UnionPayIcon,
  VisaIcon,
  WhatsAppIcon,
} from '#components/Icons';
import { LinkExternal } from '#components/LinkExternal';
import { useAuthentication } from '#contexts/Authentication';
import {
  CookieSettingsModelContent,
  Modal,
  ModalTrigger,
  useConsent,
  useIsConsentBannerDisabled,
} from '#contexts/Consent';
import { useCreateSubscriber } from '#hooks/api/useCreateSubscriber';

import { NextAnchor } from './NextAnchor';

const date = new Date();
const SUCCESS_MESSAGE = 'Thank you for subscribing.';

const Subscribe = () => {
  const {
    mutate: createSubscriber,
    isPending,
    isSuccess,
    error,
  } = useCreateSubscriber();

  const schema = Yup.object({
    email: Yup.string()
      .required('Email is required')
      .email('Must be a valid email address'),
  });

  const defaultValues = {
    email: '',
  };
  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const onSubmit = handleSubmit(
    (data) => {
      createSubscriber(
        { data },
        {
          onSuccess: () => {
            reset();
          },
        },
      );
    },
    (error) => logWarning('Subscribe form client validation failed', error),
  );

  return (
    <div className="flex w-full flex-col sm:w-auto">
      <div>
        <h2 className="q-text-body-base-bold mb-2">
          Subscribe to our newsletter
        </h2>
        <p className="mb-6 text-gray-700">
          Get notified of new products, special promotions, sales, and more.
        </p>
      </div>
      <div>
        {isSuccess ? (
          <SuccessBox className="mb-2 p-2 text-sm">
            {SUCCESS_MESSAGE}
          </SuccessBox>
        ) : null}
        {error ? (
          <ErrorBox className="mb-2 p-2 text-sm">{error.message}</ErrorBox>
        ) : null}
        <form onSubmit={onSubmit}>
          <div className="flex flex-1 flex-col gap-2 sm:flex-row">
            <label className="sr-only" htmlFor="email-address">
              Email address
            </label>
            <input
              {...register('email')}
              id="email-address"
              name="email"
              type="email"
              required
              placeholder="Email address"
              className={clsx(
                'w-full rounded border outline-none placeholder:text-base placeholder:text-gray-500 focus:ring-2 focus:ring-offset-1 disabled:cursor-not-allowed disabled:border-gray-200 disabled:text-gray-400 disabled:placeholder-gray-400 lg:w-96',
                {
                  'focus:ring-primary-700 border-gray-300 focus:border-gray-300 ':
                    !errors.email?.message,
                  'border-error-700 focus:border-error-700 focus:ring-error-700':
                    errors.email?.message,
                },
              )}
            />
            <Button
              type="submit"
              isLoading={isPending}
              variant="primaryOutlined"
            >
              Subscribe
            </Button>
          </div>
          {errors.email && touchedFields.email ? (
            <p className="text-error-700 q-text-body-sm mt-1 flex">
              {errors.email.message}
            </p>
          ) : null}
        </form>
      </div>
    </div>
  );
};

type FooterLinkListProps = {
  children: ReactNode;
};
const FooterLinkList = ({ children }: FooterLinkListProps) => {
  return <ul className="flex flex-col gap-2">{children}</ul>;
};

type FooterLinkListHeaderProps = {
  children: ReactNode;
};
const FooterLinkListHeader = ({ children }: FooterLinkListHeaderProps) => {
  return <h2 className="q-text-body-base-bold mb-2">{children}</h2>;
};

type FooterRowProps = {
  children: ReactNode | undefined;
  className?: string;
};
const FooterRow = ({ children, className }: FooterRowProps) => {
  return (
    <div>
      <div
        className={clsx(
          'max-w-site-content mx-auto px-4 py-12 md:px-6',
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};

type FooterLinkProps = {
  href: string;
  children: ReactNode;
  className?: string;
};
const FooterLink = ({ href, children, className }: FooterLinkProps) => {
  return (
    <Link
      href={href}
      className={clsx(
        'outline-primary-700 text-gray-700 outline-offset-4',
        className,
      )}
    >
      {children}
    </Link>
  );
};
const Footer = (): JSX.Element => {
  const footerRef = useRef<HTMLElement | null>(null);
  const { consent } = useConsent();
  const isConsentBannerDisabled = useIsConsentBannerDisabled();
  const { status } = useAuthentication();
  return (
    <footer className="relative border-t border-t-gray-900" ref={footerRef}>
      <div className="divide-y divide-gray-900">
        <FooterRow className="flex flex-col items-stretch justify-between sm:flex-row sm:gap-x-6">
          <div className="mb-12 flex flex-col sm:mb-0 sm:justify-between">
            <div className="mb-10 flex">
              <Link href="/">
                <QogitaLogoWordmark
                  className="h-[2.5rem] w-[9rem]"
                  theme="black"
                />
              </Link>
              <ul className="ml-8 flex list-none flex-row items-center gap-2.5 sm:ml-10 sm:gap-6">
                <li>
                  <LinkExternal href="https://twitter.com/qogita">
                    <TwitterIcon
                      title="Twitter"
                      className="h-4.5 w-4.5 fill-gray-900"
                    />
                  </LinkExternal>
                </li>
                <li>
                  <LinkExternal href="https://www.linkedin.com/company/qogita/">
                    <LinkedinIcon
                      title="Linkedin"
                      className="h-4.5 w-4.5 fill-gray-900"
                    />
                  </LinkExternal>
                </li>
                <li>
                  <LinkExternal href="https://www.instagram.com/qogitahq/">
                    <InstagramIcon
                      title="Instagram"
                      className="h-4.5 w-4.5 fill-gray-900"
                    />
                  </LinkExternal>
                </li>
              </ul>
            </div>
            {status === 'authenticated' ? (
              <div>
                <p className="q-text-body-base-bold mb-1">
                  Message us on WhatsApp
                </p>
                <p className="mb-1">Weekdays from 10am - 6pm CET</p>

                <Anchor href="https://wa.me/31208098587" target="_blank">
                  <WhatsAppIcon className="h-6.5 w-6.5" />
                  +31 20 809 8587
                </Anchor>
              </div>
            ) : (
              <div>
                <p className="q-text-body-base-bold mb-1 flex gap-2">
                  Reach us on WhatsApp after sign up
                  <WhatsAppIcon className="h-6.5 w-6.5" />
                </p>
                <p className="mb-1">Weekdays from 10am - 6pm CET</p>

                <NextAnchor
                  variant="primaryContained"
                  href={{
                    pathname: '/register/',
                  }}
                  className="mt-1 w-full text-sm md:w-fit"
                >
                  Sign up
                </NextAnchor>
              </div>
            )}
          </div>
          <Subscribe />
        </FooterRow>
        <FooterRow className="grid grid-cols-2 gap-x-4 gap-y-14 py-12 sm:grid-cols-3 md:flex md:justify-between md:gap-x-20">
          <div>
            <FooterLinkListHeader>Products</FooterLinkListHeader>
            <FooterLinkList>
              <li>
                <FooterLink href="/products/">Catalog</FooterLink>
              </li>
              <li>
                <FooterLink href="/brands/">Brands</FooterLink>
              </li>
              <li>
                <FooterLink href="/financing/">Financing</FooterLink>
              </li>
              <li>
                <FooterLink href="/insurance/">Insurance</FooterLink>
              </li>
            </FooterLinkList>
          </div>
          <div>
            <FooterLinkListHeader>Use Cases</FooterLinkListHeader>
            <FooterLinkList>
              <li>
                <FooterLink href="/buyer-information/">Buyers</FooterLink>
              </li>
              <li>
                <FooterLink href="https://sellers.qogita.com">
                  Sellers
                </FooterLink>
              </li>
            </FooterLinkList>
          </div>
          <div>
            <FooterLinkListHeader>Company</FooterLinkListHeader>
            <FooterLinkList>
              <li>
                <FooterLink href="/about-qogita/">About Us</FooterLink>
              </li>
              <li>
                <FooterLink href="/careers/">Careers</FooterLink>
              </li>
              <li>
                <FooterLink href="/partners/">Partners</FooterLink>
              </li>
              <li>
                <FooterLink href="/social-impact/">Social Impact</FooterLink>
              </li>
            </FooterLinkList>
          </div>
          <div>
            <FooterLinkListHeader>Developers</FooterLinkListHeader>
            <FooterLinkList>
              <li>
                <FooterLink href="https://developers.qogita.com/">
                  Documentation
                </FooterLink>
              </li>
              <li>
                <FooterLink href="https://developers.qogita.com/reference/">
                  API Reference
                </FooterLink>
              </li>
              <li>
                <FooterLink href="https://status.qogita.com/">
                  Status
                </FooterLink>
              </li>
            </FooterLinkList>
          </div>
          <div>
            <FooterLinkListHeader>Resources</FooterLinkListHeader>
            <FooterLinkList>
              <li>
                <FooterLink href="/contact/">Contact Us</FooterLink>
              </li>
              <li>
                <FooterLink href="https://help.qogita.com">
                  Help Center
                </FooterLink>
              </li>
              <li>
                {consent.status === 'loading' ||
                isConsentBannerDisabled ? null : (
                  <Modal>
                    <ModalTrigger className="outline-primary-700 text-gray-700 outline-offset-4">
                      Cookie Settings
                    </ModalTrigger>
                    <CookieSettingsModelContent />
                  </Modal>
                )}
              </li>
            </FooterLinkList>
          </div>
        </FooterRow>
        <FooterRow className="flex flex-col gap-y-9 lg:flex-row lg:justify-between lg:gap-y-0">
          <span className="text-gray-700">
            &copy; {date.getFullYear()} Qogita. All rights reserved.
          </span>
          <ul className="flex flex-col items-start gap-2 divide-gray-300 sm:flex-row sm:items-center sm:gap-0 sm:divide-x">
            <li>
              <FooterLink
                className="whitespace-nowrap text-gray-700 sm:pr-5"
                href="/legal/cookies/"
              >
                Cookies
              </FooterLink>
            </li>
            <li>
              <FooterLink
                className="whitespace-nowrap text-gray-700 sm:px-5"
                href="/legal/privacy/"
              >
                Privacy Policy
              </FooterLink>
            </li>
            <li>
              <FooterLink
                className="whitespace-nowrap text-gray-700 sm:pl-5"
                href="/legal/terms-buyer/"
              >
                Terms of Use
              </FooterLink>
            </li>
          </ul>
          <div className="flex gap-4 lg:justify-end">
            <VisaIcon title="Visa" className="h-[1.125rem]" />
            <MasterCardIcon title="Mastercard" className="h-[1.125rem]" />
            <AmexIcon title="American Express" className="h-[1.125rem]" />
            <ApplePayIcon title="Apple Pay" className="h-[1.125rem]" />
            <GooglePayIcon title="Google Pay" className="h-[1.125rem]" />
            <UnionPayIcon title="UnionPay" className="h-[1.125rem]" />
            <IdealIcon title="iDEAL" className="h-[1.125rem]" />
          </div>
        </FooterRow>
      </div>
    </footer>
  );
};

export { Footer };
