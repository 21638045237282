import { cn, Modal as QogitaUIModal } from '@qogita/ui';
import clsx from 'clsx';
import { ComponentProps, ReactNode } from 'react';

type ModalSizeType = 'normal' | 'large' | 'fluid';

type ModalRootProps = ComponentProps<typeof QogitaUIModal> & {
  open: boolean;
  onClose?: () => void;
  size?: ModalSizeType;
  children: ReactNode;
};
function ModalRoot({
  open,
  onClose,
  size = 'normal',
  children,
}: ModalRootProps) {
  return (
    <QogitaUIModal open={open} onClose={onClose}>
      <QogitaUIModal.Panel
        className={clsx({
          'max-w-full': size === 'fluid',
          'sm:max-w-[50%]': size === 'normal',
          'sm:w-full sm:!max-w-5xl': size === 'large',
          'sm:w-fit': size !== 'large',
        })}
      >
        {children}
      </QogitaUIModal.Panel>
    </QogitaUIModal>
  );
}

type ModalFooterProps = {
  children: ReactNode;
};
function ModalFooter({ children }: ModalFooterProps) {
  return (
    <QogitaUIModal.Footer className="flex flex-col gap-2 md:flex-row md:items-center md:justify-between">
      {children}
    </QogitaUIModal.Footer>
  );
}

type ModalActionsProps = {
  children: ReactNode;
  className?: string;
};
function ModalActions({ children, className }: ModalActionsProps) {
  return (
    <div
      className={cn(
        'flex flex-col gap-2 md:ml-auto md:flex-row md:justify-end',
        className,
      )}
    >
      {children}
    </div>
  );
}

export const Modal = Object.assign(ModalRoot, {
  Header: QogitaUIModal.Header,
  Title: QogitaUIModal.Title,
  Description: QogitaUIModal.Description,
  Body: QogitaUIModal.Body,
  Footer: ModalFooter,
  Actions: ModalActions,
});
