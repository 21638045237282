import { LDEvaluationDetail, LDFlagSet } from '@launchdarkly/node-server-sdk';
import { datadogRum } from '@qogita/logging';
import { LDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import { ReactNode, useEffect } from 'react';

import { useUser } from '#hooks/api/useUser';
import { environment } from '#lib/environment.mjs';
/**
 * This wrapper updates the LD context when we have the user qid.
 * This is because we fetch the user qid on the client. So the initial
 * LDPRovider render will never have the user qid and needs to be
 * updated once we've fetched the user.
 *
 * This will go away when we fetch the user on the server and pass it to the
 * _app component as pageProps.
 */
const ContextManager = ({ children }: { children: ReactNode }) => {
  const ldClient = useLDClient();
  const { data: user } = useUser();

  useEffect(() => {
    if (!user?.qid) return;

    ldClient?.identify({
      kind: 'user',
      key: user.qid,
    });
  }, [user?.qid, ldClient]);

  return children;
};
/**
 * Temporarily this only runs for logged in users, identifying them by their qid
 * This is because we've had a spike in users which we think come from anonymous sessions
 * and we want to see if this is the case.
 */
export function FeatureFlagProvider({
  children,
  initialState,
}: {
  children: ReactNode;
  initialState: LDFlagSet;
}) {
  const LAUNCHDARKLY_SDK_CLIENT_SIDE_ID =
    environment.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID ?? '';
  const { data: user } = useUser();

  const options = {
    bootstrap: initialState,
    flushInterval: 2000,
    inspectors: [
      {
        type: 'flag-used',
        name: 'dd-inspector',
        method: (key: string, detail: LDEvaluationDetail) => {
          datadogRum.addFeatureFlagEvaluation(key, detail.value);
        },
      },
    ],
  };

  return (
    <LDProvider
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-ignore TODO: fix type error */
      options={options}
      clientSideID={LAUNCHDARKLY_SDK_CLIENT_SIDE_ID}
      context={{
        kind: 'user',
        key: user ? user.qid : 'anonymous',
      }}
    >
      <ContextManager>{children}</ContextManager>
    </LDProvider>
  );
}
