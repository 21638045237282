import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@heroicons/react/20/solid';
import clsx from 'clsx';
import {
  ComponentPropsWithoutRef,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type TableContextType = {
  rotativeColIndex: number;
  setRotativeColIndex: Dispatch<SetStateAction<number>>;
};

const TableContext = createContext<TableContextType | null>(null);
const useTableContext = () => {
  const context = useContext(TableContext);
  if (!context) {
    throw new Error(
      '[Qogita UI] Table components must be used inside a <Table> component',
    );
  }
  return context;
};

type Align = 'center' | 'left' | 'right';

type TableCellProps = ComponentPropsWithoutRef<'td'> & {
  className?: string;
  align?: Align;
  prevColTitle?: string;
  nextColTitle?: string;
};

export type TableHeaderProps = TableCellProps & {
  onClick?: React.MouseEventHandler;
  sort?: 'ascending' | 'descending';
};

type TableContainerProps = ComponentPropsWithoutRef<'div'> & {
  className?: string;
};

function Th({
  align = 'center',
  className,
  prevColTitle: previousColTitle = 'Previous column',
  nextColTitle = 'Next column',
  onClick,
  children,
  sort,
  ...props
}: TableHeaderProps) {
  const { rotativeColIndex, setRotativeColIndex } = useTableContext();

  return (
    <th
      className={clsx(
        'group relative hidden w-1/2 text-ellipsis bg-white px-6 py-3 first:table-cell sm:table-cell sm:w-auto',
        {
          'text-center': align === 'center',
          'text-left': align === 'left',
          'text-right': align === 'right',
        },
        className,
      )}
      role="columnheader"
      aria-sort={sort}
      {...props}
    >
      {rotativeColIndex > 2 ? (
        <button
          className="absolute left-0 top-1/2 -translate-y-1/2 group-first:hidden sm:hidden"
          onClick={() => setRotativeColIndex(rotativeColIndex - 1)}
          title={previousColTitle}
          type="button"
        >
          <ChevronLeftIcon className="h-5 w-5 text-gray-400" />
        </button>
      ) : null}
      {onClick ? (
        <button
          onClick={onClick}
          className={clsx('flex w-full items-center gap-2', {
            'justify-start': align === 'left',
            'justify-center': align === 'center',
            'justify-end': align === 'right',
          })}
        >
          <span className="q-text-body-base-bold text-gray-900" {...props}>
            {children}
          </span>
          <span className="flex flex-col">
            <ChevronUpIcon
              className={clsx('h-2.5 w-2.5', {
                'text-gray-400': sort !== 'ascending',
                'text-gray-700': sort === 'ascending',
              })}
            />
            <ChevronDownIcon
              className={clsx('-mt-1 h-2.5 w-2.5', {
                'text-gray-400': sort !== 'descending',
                'text-gray-700': sort === 'descending',
              })}
            />
          </span>
        </button>
      ) : (
        <span className="q-text-body-base-bold text-gray-900" {...props}>
          {children}
        </span>
      )}

      <button
        className="absolute right-0 top-1/2 -translate-y-1/2 group-first:hidden group-last:hidden sm:hidden"
        onClick={() => setRotativeColIndex(rotativeColIndex + 1)}
        title={nextColTitle}
        type="button"
      >
        <ChevronRightIcon className="h-5 w-5 text-gray-400" />
      </button>
    </th>
  );
}

function Td({
  align = 'center',
  className,
  children,
  ...props
}: TableCellProps) {
  return (
    <td
      className={clsx(
        'hidden w-1/2 overflow-auto text-ellipsis px-4 py-3 text-gray-700 first:table-cell sm:table-cell sm:w-auto sm:px-6',
        {
          'text-center': align === 'center',
          'text-left': align === 'left',
          'text-right': align === 'right',
        },
        className,
      )}
      {...props}
    >
      {children}
    </td>
  );
}

function Tr({
  className,
  ...props
}: ComponentPropsWithoutRef<'tr'> & { className?: string }) {
  return (
    <tr
      className={clsx(
        'border-b border-gray-300 odd:bg-gray-50 even:bg-white',
        className,
      )}
      {...props}
    />
  );
}

function Tbody({ ...props }: ComponentPropsWithoutRef<'tbody'>) {
  return <tbody {...props} />;
}

function Thead({ ...props }: ComponentPropsWithoutRef<'thead'>) {
  return <thead {...props} />;
}

function TableRoot({
  className,
  ...props
}: ComponentPropsWithoutRef<'table'> & { className?: string }) {
  const [rotativeColIndex, setRotativeColIndex] = useState<number>(2); // first column is always visible, so we display the 2nd col on the rotative slot by default
  return (
    <TableContext.Provider value={{ rotativeColIndex, setRotativeColIndex }}>
      <div className="sm:overflow-auto">
        <table
          className={clsx(
            'w-full table-fixed rounded-lg sm:table-auto sm:overflow-auto',
            className,
          )}
          {...props}
        />
        <style>
          {`
       th:nth-child(${rotativeColIndex}),td:nth-child(${rotativeColIndex}) {
        display: table-cell;
        }
      `}
        </style>
      </div>
    </TableContext.Provider>
  );
}

function Container({ className, children, ...divProps }: TableContainerProps) {
  return (
    <div
      className={clsx(
        'w-full overflow-hidden rounded-lg border border-gray-300',
        className,
      )}
      {...divProps}
    >
      {children}
    </div>
  );
}

export function TableTestComponent() {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th align="left">Column 1</Th>
          <Th>Column 2</Th>
          <Th>Column 3</Th>
          <Th>Column 4</Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td align="left">1st column content</Td>
          <Td>2nd column content</Td>
          <Td>3rd column content</Td>
          <Td>4th column content</Td>
        </Tr>
        <Tr>
          <Td align="left">Col 1</Td>
          <Td>Col 2</Td>
          <Td>Col 3</Td>
          <Td>Col 4</Td>
        </Tr>
        <Tr>
          <Td align="left">Col 1</Td>
          <Td>Col 2</Td>
          <Td>Col 3</Td>
          <Td>Col 4</Td>
        </Tr>
      </Tbody>
    </Table>
  );
}

export const Table = Object.assign(TableRoot, {
  Td,
  Th,
  Tr,
  Thead,
  Tbody,
  Container,
});
