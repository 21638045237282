import { InternalProps } from '../utils/internal.types';

export interface ProviderProps extends InternalProps {
  children: React.ReactElement;
}

export function Provider({ children, ...props }: ProviderProps) {
  return (
    <div {...props} data-testid="qogita-ui">
      {children}
    </div>
  );
}
