import { forwardRef } from 'react';

import {
  DeprecatedFormInput,
  type DeprecatedFormInputProps,
} from './DeprecatedControls';
import { DeprecatedErrorMessage } from './DeprecatedErrorMessage';
import { DeprecatedField } from './DeprecatedField';
import { DeprecatedFieldDescription } from './DeprecatedFieldDescription';
import { DeprecatedFormLabel } from './DeprecatedFieldLabel';
import {
  type DeprecatedFormFieldParam as FormFieldParameter,
  useDeprecatedFormField,
} from './useDeprecatedFormField';

// Form hook handles these types of props
type InputAttribute = Omit<DeprecatedFormInputProps, 'id' | 'error'>;

// An opinionated form types - Consumers can create
export type DeprecatedFormFieldInputParam = FormFieldParameter & InputAttribute;

export const DeprecatedFormField = forwardRef<
  HTMLInputElement,
  DeprecatedFormFieldInputParam
>(function FormField(formFieldProps, formFieldRef) {
  const {
    className,
    id,
    name,
    label,
    description,
    tooltip,
    errorMessage,
    type = 'text',
    required = false,
    disabled = false,
    ...props
  } = formFieldProps;

  const {
    fieldProps,
    labelProps,
    descriptionProps,
    controlProps,
    fieldErrorProps,
  } = useDeprecatedFormField({
    id,
    name,
    label,
    description,
    tooltip,
    errorMessage,
    required,
    disabled,
    fieldset: false,
  });

  return (
    <DeprecatedField {...fieldProps} className={className}>
      {'htmlFor' in labelProps && <DeprecatedFormLabel {...labelProps} />}

      {descriptionProps && <DeprecatedFieldDescription {...descriptionProps} />}

      {controlProps && (
        <DeprecatedFormInput
          {...props}
          {...controlProps}
          ref={formFieldRef}
          type={type}
        />
      )}

      {fieldErrorProps && <DeprecatedErrorMessage {...fieldErrorProps} />}
    </DeprecatedField>
  );
});
