import { forwardRef } from 'react';

import { BaseButton, SharedButtonProps } from '../Button/Button';

export type AnchorProps = SharedButtonProps<'a'>;
export const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(
  ({ variant = 'hyperlink', ...props }, ref) => {
    return <BaseButton variant={variant} as="a" ref={ref} {...props} />;
  },
);
Anchor.displayName = 'Anchor';
