import clsx from 'clsx';
import { ComponentPropsWithRef, forwardRef } from 'react';

type InputAttribute = ComponentPropsWithRef<'input'>;

/**
 * @description In most scenarios consumers should compose an adornment with the `Input` component `not` prop drilling it!
 */
export type InputWithoutAdornments = Omit<
  InputAttribute,
  'startAdornment' | 'endAdornment'
>;

type InputProps = InputAttribute & {
  error?: boolean;
};

/**
 * @deprecated
 */
export const DeprecatedInput = forwardRef<HTMLInputElement, InputProps>(
  function DeprecatedInput(inputProps, inputRef) {
    const {
      type = 'text',
      className,
      error = false,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      required, // Avoid native behavior - favour custom error behavior as it's more descriptive
      ...props
    } = inputProps;

    const hasError: boolean = props.disabled ? false : error;

    return (
      <input
        {...props}
        ref={inputRef}
        type={type}
        className={clsx(
          className,
          'h-10 rounded border outline-none placeholder:text-base placeholder:text-gray-500 focus:ring-2 focus:ring-offset-1 disabled:cursor-not-allowed disabled:border-gray-200 disabled:text-gray-400 disabled:placeholder-gray-400',
          {
            'focus:ring-primary-700 border-gray-300 focus:border-gray-300 ':
              !hasError,
            'border-error-700 focus:border-error-700 focus:ring-error-700':
              hasError,
          },
        )}
      />
    );
  },
);
