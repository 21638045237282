export class Queue<T> {
  private queue: T[] = [];

  enqueue(item: T) {
    this.queue.push(item);
  }
  dequeue() {
    return this.queue.shift();
  }
  flush(callback: (item: T) => void) {
    while (this.queue.length) {
      const item = this.queue.shift();
      if (item) {
        callback(item);
      }
    }
  }
}
