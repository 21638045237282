import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

import {
  useIsCollapsedContext,
  useIsSectionActiveContext,
  useNavigationDepth,
  useSetIsCollapsedContext,
} from './context';
import { NavigationInsetMargin } from './NavigationInsetMargin';
import {
  NavigationListItem,
  NavigationListItemProps,
} from './NavigationListItem';

type SectionHeadingProps = Omit<
  NavigationListItemProps<'button'>,
  'as' | 'onClick' | 'type' | 'isActive'
>;

export function NavigationSectionHeading({
  children,
  ...componentProps
}: SectionHeadingProps) {
  const navigationDepth = useNavigationDepth();
  const isCollapsed = useIsCollapsedContext();
  const setIsCollapsed = useSetIsCollapsedContext();
  const isSectionActive = useIsSectionActiveContext();

  const isActive = isCollapsed && isSectionActive;
  const onClick = () => setIsCollapsed((previous) => !previous);
  const ArrowIcon = isCollapsed ? ChevronRightIcon : ChevronDownIcon;

  return (
    <NavigationListItem
      {...componentProps}
      isActive={isActive}
      as="button"
      onClick={onClick}
    >
      <NavigationInsetMargin insetLevel={navigationDepth - 1} />
      {children}
      <div className="ml-auto">
        <ArrowIcon
          className="ml-3 h-[1.125rem] w-[1.125rem] flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />
      </div>
    </NavigationListItem>
  );
}
