import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * An extension of clsx that merges tailwind classes
 * Consumers passing in any tailwind classes will have those classes merged in
 * overriding any existing classes that apply to the same properties
 *
 * Avoids the issues of using clsx with tailwind classes
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
