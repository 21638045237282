import clsx from 'clsx';
import {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  ElementType,
  forwardRef,
  ReactNode,
  useEffect,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';

function ButtonFallback({
  resetErrorBoundary,
}: {
  resetErrorBoundary: () => void;
  error: Error;
}) {
  useEffect(() => {
    resetErrorBoundary();
  }, [resetErrorBoundary]);
  return null;
}

function ButtonErrorBoundary({ children }: { children: ReactNode }) {
  return (
    <ErrorBoundary
      FallbackComponent={ButtonFallback}
      onError={(error) => {
        const buttonError = new Error(`ButtonErrorBoundary: ${error.message}`, {
          cause: error,
        });
        console.error(buttonError);
      }}
    >
      {children}
    </ErrorBoundary>
  );
}

type SharedTheme = 'primary' | 'success' | 'error' | 'info' | 'caution';
type SharedVariant = 'contained' | 'outlined' | 'text';
type PrimaryInverseTheme = 'primaryInverse';
type PrimaryInverseVariant = 'contained';
type HyperlinkVariant = 'hyperlink';
type PageLinkVariant = 'pagelink';

type ButtonVariant =
  | `${SharedTheme}${Capitalize<SharedVariant>}`
  | `${PrimaryInverseTheme}${Capitalize<PrimaryInverseVariant>}`
  | HyperlinkVariant
  | PageLinkVariant;

export type SharedButtonProps<T extends ElementType> = {
  variant?: ButtonVariant;
  startIcon?: ElementType;
  isLoading?: boolean;
  children: ReactNode;
} & ComponentPropsWithoutRef<T>;

type BaseButtonProps<T extends ElementType> = {
  as?: T;
} & SharedButtonProps<T>;

const LoadingIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    className="h-4.5 w-4.5 animate-spin fill-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.232 16.778c.097.614-.322 1.197-.943 1.217a8.999 8.999 0 1 1 7.158-14.049c.349.514.123 1.197-.43 1.479-.554.282-1.224.054-1.594-.445a6.75 6.75 0 1 0-5.488 10.77c.622.006 1.2.414 1.297 1.028Z" />
  </svg>
);

const buttonClasses: Record<ButtonVariant, string> = {
  primaryInverseContained:
    'px-6 py-2 font-medium rounded text-primary-900 bg-white hover:bg-primary-50 focus-visible:bg-primary-50 tracking-[0.0625rem]',
  hyperlink:
    'p-0 inline font-normal text-info-700 hover:text-info-800 focus-visible:text-info-800 active:text-info-900',
  pagelink:
    'p-0 inline text-sm tracking-wider border-gray-300 border-b font-medium hover:border-primary-700 hover:text-primary-700 focus-visible:border-primary-700 focus-visible:text-primary-700 active:border-primary-900 active:text-primary-900',
  primaryContained:
    'px-6 py-2 font-medium rounded text-white bg-primary-700 hover:bg-primary-800 focus-visible:bg-primary-800 active:bg-primary-900 tracking-[0.0625rem]',
  primaryOutlined:
    'px-6 py-2 font-medium rounded text-primary-900 border border-primary-900 hover:bg-primary-50/50 focus-visible:bg-primary-50/50 tracking-[0.0625rem]',
  primaryText:
    'py-2 font-medium text-primary-700 hover:text-primary-800 focus-visible:text-primary-800 active:text-primary-900 tracking-[0.0625rem]',
  successContained:
    'px-6 py-2 font-medium rounded text-white bg-success-700 hover:bg-success-800 focus-visible:bg-success-800 active:bg-success-900 tracking-[0.0625rem]',
  successOutlined:
    'px-6 py-2 font-medium rounded text-success-900 border border-success-900 hover:bg-success-50/50 focus-visible:bg-success-50/50 tracking-[0.0625rem]',
  successText:
    'py-2 font-medium text-success-700 hover:text-success-800 focus-visible:text-success-800 active:text-success-900 tracking-[0.0625rem]',
  errorContained:
    'px-6 py-2 font-medium rounded text-white bg-error-700 hover:bg-error-800 focus-visible:bg-error-800 active:bg-error-900 tracking-[0.0625rem]',
  errorOutlined:
    'px-6 py-2 font-medium rounded text-error-900 border border-error-900 hover:bg-error-50/50 focus-visible:bg-error-50/50 tracking-[0.0625rem]',
  errorText:
    'py-2 font-medium text-error-700 hover:text-error-800 focus-visible:text-error-800 active:text-error-900 tracking-[0.0625rem]',
  infoContained:
    'px-6 py-2 font-medium rounded text-white bg-info-700 hover:bg-info-800 focus-visible:bg-info-800 active:bg-info-900 tracking-[0.0625rem]',
  infoOutlined:
    'px-6 py-2 font-medium rounded text-info-900 border border-info-900 hover:bg-info-50/50 focus-visible:bg-info-50/50 tracking-[0.0625rem]',
  infoText:
    'py-2 font-medium text-info-700 hover:text-info-800 focus-visible:text-info-800 active:text-info-900 tracking-[0.0625rem]',
  cautionContained:
    'px-6 py-2 font-medium rounded text-white bg-caution-700 hover:bg-caution-800 focus-visible:bg-caution-800 active:bg-caution-900 tracking-[0.0625rem]',
  cautionOutlined:
    'px-6 py-2 font-medium rounded text-caution-900 border border-caution-900 hover:bg-caution-50/50 focus-visible:bg-caution-50/50 tracking-[0.0625rem]',
  cautionText:
    'py-2 font-medium text-caution-700 hover:text-caution-800 focus-visible:text-caution-800 active:text-caution-900 tracking-[0.0625rem]',
};

export const BaseButton = forwardRef(
  <T extends ElementType>(
    {
      as,
      variant = 'primaryContained',
      children,
      startIcon: StartIcon,
      isLoading = false,
      className,
      ...props
    }: BaseButtonProps<T>,
    ref?: ComponentPropsWithRef<T>['ref'],
  ) => {
    const themeVariantClasses = buttonClasses[variant];

    const Component = as || 'button';

    const isContained = variant.toLowerCase().includes('contained');
    const isOutlined = variant.toLowerCase().includes('outlined');

    return (
      <ButtonErrorBoundary>
        <Component
          ref={ref}
          aria-busy={isLoading}
          className={clsx(
            'inline-flex cursor-pointer',
            'outline-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1',
            'disabled:cursor-not-allowed disabled:text-gray-400',
            themeVariantClasses,
            {
              'active:shadow-[inset_0_4px_4px_rgba(0,0,0,0.25)] disabled:active:shadow-none':
                isContained || isOutlined,
              'disabled:bg-gray-200': isContained,
              'disabled:border-gray-300 disabled:hover:bg-white': isOutlined,
            },
            className,
          )}
          {...props}
        >
          <span className="inline-flex h-full w-full items-center justify-center gap-2">
            {StartIcon ? (
              <>
                {isLoading ? (
                  <LoadingIcon />
                ) : (
                  <StartIcon className="max-h-4.5 max-w-4.5 h-full w-full" />
                )}
                {children}
              </>
            ) : (
              <>
                {isLoading ? (
                  <div className="flex h-6 w-6 items-center justify-center">
                    <LoadingIcon />
                  </div>
                ) : (
                  children
                )}
              </>
            )}
          </span>
        </Component>
      </ButtonErrorBoundary>
    );
  },
);
BaseButton.displayName = 'BaseButton';

export type ButtonProps = SharedButtonProps<'button'>;
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    return <BaseButton as="button" ref={ref} {...props} />;
  },
);
Button.displayName = 'Button';
