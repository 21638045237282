import {
  autoUpdate,
  flip,
  FloatingPortal,
  Placement,
  shift,
  useFloating,
} from '@floating-ui/react';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { createContext, Fragment, ReactNode, useContext } from 'react';

export const MENU_ALIGNMENTS = ['left', 'center', 'right'] as const;
type MenuAlignment = (typeof MENU_ALIGNMENTS)[number];

const FLOATING_PLACEMENT_MAP: Record<MenuAlignment, Placement> = {
  left: 'bottom-start',
  center: 'bottom',
  right: 'bottom-end',
};

const FloatingContext = createContext<ReturnType<typeof useFloating> | null>(
  null,
);
const useFloatingContext = () => {
  const context = useContext(FloatingContext);
  if (!context) {
    throw new Error(
      '[Qogita UI] DropdownMenu components must be used within a <Dropdown>',
    );
  }
  return context;
};

type ItemProps = Parameters<typeof Menu.Item>[0];
function Item({ className, ...props }: ItemProps) {
  return (
    <Menu.Item
      {...props}
      className={({ active }: { active: boolean }) =>
        clsx(
          'block rounded',
          'hover:bg-gray-100',
          'active:bg-gray-200',
          // Because focus is managed by HeadlessUI, we use the `active` render prop
          // (means whether or not the item is the active/focused item in the list.)
          // as the focus state
          { 'bg-gray-100': active },
          className,
        )
      }
    />
  );
}

type ItemsProps = Parameters<typeof Menu.Items>[0];
function Items({ className, ...props }: ItemsProps) {
  const { refs, x, y, strategy } = useFloatingContext();
  return (
    <FloatingPortal>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          {...props}
          className={clsx(
            'mt-1 divide-y divide-gray-200 rounded-md border border-gray-200 bg-white focus-visible:outline-none',
            className,
          )}
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            width: 'max-content',
          }}
        />
      </Transition>
    </FloatingPortal>
  );
}

type SectionProps = {
  children: ReactNode;
};
const Section = (props: SectionProps) => {
  return <div className="p-1" {...props} />;
};

type ButtonProps = Parameters<typeof Menu.Button>[0];
function Button(props: ButtonProps) {
  const { refs } = useFloatingContext();
  return <Menu.Button {...props} ref={refs.setReference} />;
}

type DropdownMenuRootProps = Parameters<typeof Menu>[0] & {
  alignment?: (typeof MENU_ALIGNMENTS)[number];
};
function DropdownMenuRoot({
  alignment = 'left',
  ...props
}: DropdownMenuRootProps) {
  const placement = FLOATING_PLACEMENT_MAP[alignment];
  const floating = useFloating({
    strategy: 'fixed',
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [shift({ padding: 8 }), flip()],
  });
  return (
    <FloatingContext.Provider value={floating}>
      <Menu {...props} />
    </FloatingContext.Provider>
  );
}

export const DropdownMenu = Object.assign(DropdownMenuRoot, {
  Button,
  Items,
  Item,
  Section,
});

export const DropdownMenuTestComponent = () => (
  <DropdownMenu>
    <DropdownMenu.Button>Menu</DropdownMenu.Button>
    <DropdownMenu.Items>
      <DropdownMenu.Item>
        <a href="#">Your profile</a>
      </DropdownMenu.Item>
      <DropdownMenu.Item>
        <a href="#">Your addresses</a>
      </DropdownMenu.Item>
      <DropdownMenu.Item>
        <a href="#">Your orders</a>
      </DropdownMenu.Item>
    </DropdownMenu.Items>
  </DropdownMenu>
);
