import type { User } from '@qogita/canary-types';
import { MeiliSearch } from 'meilisearch';

import { environment } from '#lib/environment.mjs';

const MEILISEARCH_URL = environment.NEXT_PUBLIC_MEILISEARCH_URL || '';
const MEILISEARCH_API_KEY = environment.NEXT_PUBLIC_MEILISEARCH_API_KEY || '';

export const SEARCH_INDEX_NAME =
  environment.NEXT_PUBLIC_MEILISEARCH_INDEX_NAME || '';

/**
 * meilisearch client used to run regular API methods, like index instantiation or search
 */
export const meiliClient = new MeiliSearch({
  host: MEILISEARCH_URL,
  apiKey: MEILISEARCH_API_KEY,
});

/**
 * @param shippingCountry single country code from user.country
 * @param buyingCountries array of country codes set in account details (if buyer's preference is worldwide - empty array - then all allowedShippingCountries are used)
 * @returns meilisearch filter string to pass to isntantsearch's configure widget
 */
export const getInstantSearchAvailabilityByCountryFilter = (
  shippingCountry: User['country'],
  buyingCountries: User['buyingCountries'],
) => {
  const availabilityCountryArray = buyingCountries.reduce<string[]>(
    (previousValue, buyingCountry) => {
      return [
        ...previousValue,
        `ALL_${buyingCountry}`,
        `${shippingCountry}_${buyingCountry}`,
      ];
    },
    [],
  );

  return `availability_country IN ${JSON.stringify(availabilityCountryArray)}`;
};
