import { countries } from './country.data';

export * from './country.data';

export type CountryDescriptor = {
  [key: string]: unknown;
  name: string;
  callingCode: string;
};

export const flatCountries = countries.flatMap((country) => {
  if (Array.isArray(country.callingCode)) {
    return country.callingCode.map((callingCode) => ({
      name: country.name,
      countryCode: country.countryCode,
      callingCode,
    }));
  }
  return {
    name: country.name,
    countryCode: country.countryCode,
    callingCode: country.callingCode,
  };
});

export const getCountryDescriptorForDialingCode = (
  callingCode: string | undefined,
  fallbackValue: CountryDescriptor,
): CountryDescriptor =>
  flatCountries.find(
    (countryDescriptor) => countryDescriptor.callingCode === callingCode,
  ) ?? fallbackValue;
