import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { forwardRef, useState } from 'react';

import { DeprecatedInputEndAdornment } from '../DeprecatedInput';
import {
  DeprecatedFormInput,
  DeprecatedFormInputProps,
} from './DeprecatedControls';
import { DeprecatedErrorMessage } from './DeprecatedErrorMessage';
import { DeprecatedField } from './DeprecatedField';
import { DeprecatedFieldDescription } from './DeprecatedFieldDescription';
import { DeprecatedFormLabel } from './DeprecatedFieldLabel';
import {
  DeprecatedFormFieldParam as FormFieldParameter,
  useDeprecatedFormField,
} from './useDeprecatedFormField';

// Form hook handles these types of props
type InputAttribute = Omit<DeprecatedFormInputProps, 'id' | 'error'>;

type PasswordFieldProps = Omit<
  FormFieldParameter & InputAttribute,
  'type' | 'endAdornment'
>;

const visibilityConfig = {
  visible: {
    icon: EyeSlashIcon,
    type: 'text',
    title: 'Hide password',
  },
  hidden: {
    icon: EyeIcon,
    type: 'password',
    title: 'Show password',
  },
} as const;

/**
 * @deprecated
 */
export const DeprecatedPasswordField = forwardRef<
  HTMLInputElement,
  PasswordFieldProps
>(function DeprecatedPasswordField(passwordFieldProps, passwordFieldRef) {
  const {
    id,
    name,
    label,
    description,
    required,
    disabled,
    errorMessage,
    tooltip,
    ...props
  } = passwordFieldProps;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const {
    type,
    title,
    icon: VisibilityIcon,
  } = visibilityConfig[isVisible ? 'visible' : 'hidden'];

  const {
    fieldProps,
    labelProps,
    descriptionProps,
    controlProps,
    fieldErrorProps,
  } = useDeprecatedFormField({
    id,
    name,
    label,
    tooltip,
    description,
    errorMessage,
    required,
    disabled,
    fieldset: false,
  });

  return (
    <DeprecatedField {...fieldProps}>
      {'htmlFor' in labelProps && <DeprecatedFormLabel {...labelProps} />}

      {descriptionProps && <DeprecatedFieldDescription {...descriptionProps} />}

      {controlProps && (
        <DeprecatedFormInput
          {...props}
          {...controlProps}
          ref={passwordFieldRef}
          type={type}
          className="pr-11"
          endAdornment={
            <DeprecatedInputEndAdornment variant="end" className="px-3">
              <button
                type="button"
                title={title}
                aria-label={title}
                onClick={() => setIsVisible((x) => !x)}
              >
                <VisibilityIcon className="h-6 w-6 text-gray-400" />
              </button>
            </DeprecatedInputEndAdornment>
          }
        />
      )}

      {fieldErrorProps && <DeprecatedErrorMessage {...fieldErrorProps} />}
    </DeprecatedField>
  );
});
