import { MagnifyingGlassIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { DeprecatedCombobox } from '@qogita/ui';
import clsx from 'clsx';
import { FormEventHandler, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import { useQueryParams } from '#hooks/useQueryParams';
import {
  SearchSuggestionHit,
  useSearchSuggestions,
} from '#hooks/useSearchSuggestions';
import { useTrackEvent } from '#lib/report/tracking';

const querySchema = Yup.object({
  query: Yup.string().optional().default(''),
});

export const ProductsSearch = (): JSX.Element => {
  const [{ query: searchQuery }, setQueryParams] = useQueryParams({
    validationSchema: querySchema,
  });

  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchInputValue, setSearchInputValue] = useState(searchQuery);

  const [selectedSuggestionHit, setSelectedSuggestionHit] =
    useState<SearchSuggestionHit | null>(null);

  const { data: suggestionsData } = useSearchSuggestions({
    query: searchInputValue,
  });

  const suggestionItems = suggestionsData ?? [];

  useEffect(() => {
    const urlSelectedSuggestionHit = {
      query: searchQuery,
      objectID: '',
    };
    setSelectedSuggestionHit(searchQuery ? urlSelectedSuggestionHit : null);
  }, [searchQuery]);

  const { trackProductsSearched } = useTrackEvent();

  const handleSelectedItemChange = (value: SearchSuggestionHit) => {
    const searchTerm = value?.query ?? '';
    setSearchInputValue(searchTerm);
    setSelectedSuggestionHit(value);
    trackProductsSearched({ query: searchInputValue });
    setQueryParams(
      { query: searchTerm },
      { navigationType: 'push', pathname: '/products/' },
    );
  };

  const handleSearchFormSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    trackProductsSearched({ query: searchInputValue });
    setQueryParams(
      { query: searchInputValue },
      { navigationType: 'push', pathname: '/products/' },
    );
  };

  const handleOnKeyDown = (event: KeyboardEvent) => {
    if (
      event.key !== '/' ||
      (event.target as HTMLElement).tagName === 'INPUT' ||
      (event.target as HTMLElement).tagName === 'SELECT' ||
      (event.target as HTMLElement).tagName === 'TEXTAREA' ||
      (event.target as HTMLElement).isContentEditable
    ) {
      return;
    } else {
      event.preventDefault();
      searchInputRef.current?.focus();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleOnKeyDown);
    return () => {
      document.removeEventListener('keydown', handleOnKeyDown);
    };
  }, []);

  return (
    <form onSubmit={handleSearchFormSubmit} role="search">
      <DeprecatedCombobox<SearchSuggestionHit | null>
        value={selectedSuggestionHit}
        onChange={handleSelectedItemChange}
      >
        <div className="relative">
          <DeprecatedCombobox.Input<SearchSuggestionHit, 'input'>
            className="border-gray-900 py-1 sm:py-2"
            placeholder="Search brands, products, GTINs"
            displayValue={(value) => value?.query ?? ''}
            ref={searchInputRef}
            aria-label="Search catalog"
            onChange={(event) => setSearchInputValue(event.target.value)}
            value={searchInputValue}
          />
          <div className="absolute right-0 top-0 flex h-full flex-row items-center">
            {searchInputValue || selectedSuggestionHit?.query ? (
              <button
                onClick={() => {
                  setSearchInputValue('');
                  setSelectedSuggestionHit(null);
                  searchInputRef.current?.focus();
                }}
                className="h-full pl-2 pr-2"
              >
                <XCircleIcon className="h-6 w-6 text-gray-400" />
              </button>
            ) : null}
            <div className="h-6 w-[0.0625rem] bg-gray-900" />
            <button
              type="submit"
              className="h-full pl-2 pr-2"
              aria-label="Search catalog"
            >
              <MagnifyingGlassIcon className="h-6 w-6" />
            </button>
          </div>
          <DeprecatedCombobox.Options className="shadow-md ring-0">
            <div
              className={clsx({
                hidden: !searchInputValue,
              })}
            >
              <DeprecatedCombobox.Option
                value={{
                  objectId: null,
                  query: searchInputValue,
                }}
                className="data-[headlessui-state=active]:bg-gray-100 data-[headlessui-state=active]:text-gray-900"
              >
                {searchInputValue}
              </DeprecatedCombobox.Option>
            </div>
            {suggestionItems.map((item) => (
              <DeprecatedCombobox.Option
                className="data-[headlessui-state=active]:bg-gray-100 data-[headlessui-state=active]:text-gray-900"
                key={item.objectID}
                value={item}
              >
                {item.query}
              </DeprecatedCombobox.Option>
            ))}
          </DeprecatedCombobox.Options>
        </div>
      </DeprecatedCombobox>
    </form>
  );
};
