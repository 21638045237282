import clsx from 'clsx';

type Props = {
  children: React.ReactNode;
  className?: string;
  id?: string;
};

export const ErrorBox = ({ children, className, id }: Props): JSX.Element => (
  <div
    className={clsx(
      'border-error-600 bg-error-100 text-error-600 rounded border',
      className,
    )}
    id={id}
  >
    {children}
  </div>
);
