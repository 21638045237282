import { Country } from '@qogita/canary-types';
import { countries } from '@qogita/countries';
import { useField } from 'formik';
import { DetailedHTMLProps, SelectHTMLAttributes } from 'react';

type Props = {
  id?: string;
  className?: string;
  name: string;
  required?: boolean;
  validate?: (countryCode: Country) => boolean;
} & DetailedHTMLProps<
  SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

/**
 * @deprecated Please use `<CountryField />` as this uses the new UI Library
 */
export const CountryFieldOld = ({ validate, ...props }: Props): JSX.Element => {
  const [field] = useField(props);
  const validCountries = validate
    ? countries.filter(({ countryCode }) => validate(countryCode))
    : countries;
  return (
    <select {...field} {...props}>
      {validCountries.map(({ name, countryCode }) => (
        <option key={countryCode} value={countryCode}>
          {name}
        </option>
      ))}
    </select>
  );
};
