import {
  AnnualTurnover,
  BuyerCurrency,
  Cart,
  CartLine,
  Checkout,
  CheckoutComplete,
  NumberOfEmployees,
  Order,
  PersonaDistributionChannels,
  User,
  Variant,
  WatchlistItem,
} from '@qogita/canary-types';
import { logError } from '@qogita/logging';
import { Hit } from 'meilisearch';
import { useCallback, useMemo } from 'react';

import { ProductResponse } from '#hooks/api/useProduct';
import { getValidatedUserCurrency } from '#lib/currency';
import { SEARCH_INDEX_NAME } from '#lib/search';
import { getUrlPathname } from '#lib/url';
import type { MeilisearchResponseHitWithAnalytics } from '#types/index';

import { track, useAnalytics } from './AnalyticsProvider';
import {
  gtagConversion,
  gtagEvent,
  useIsManuallyLoadGtagEnabled,
} from './Gtag';

const LINKED_IN_CONVERSION_IDS = {
  UserSignedUp: 13500594,
  ProductAdded: 13500602,
  CheckoutCompleted: 13500786,
  FirstCheckoutCompleted: 13969162,
  FirstCheckoutStarted: 13969194,
};

const GOOGLE_CONVERSION_IDS = {
  CheckoutCompleted: 'qy8iCLadqYwYEM65vKsC',
  UserSignedUp: 'PTdeCLrIqYwYEM65vKsC',
  CheckoutStarted: 'XCrnCKiJm40YEM65vKsC',
  ProductAdded: 'QttuCNzHm40YEM65vKsC',
  UserVerificationStatusChanged: 'Ze9PCNO325cYEM65vKsC',
  FirstCheckoutCompleted: 'zK3vCKHY1p4YEM65vKsC',
  FirstCheckoutStarted: 'WpBmCNzBm9gYEM65vKsC',
};

const trackLinkedInConversion = (
  type: keyof typeof LINKED_IN_CONVERSION_IDS,
) => {
  if (!window.lintrk) {
    return;
  }

  const conversionId = LINKED_IN_CONVERSION_IDS[type];
  window.lintrk('track', { conversion_id: conversionId });
};

const AnalyticsEvents = {
  AccountManagerContactClicked: 'Account Manager Contact Clicked',
  AccountMenuOpened: 'Account Menu Opened',
  BackToCartClicked: 'Back To Cart Clicked',
  CartComparisonDownloaded: 'Cart Comparison Downloaded',
  CartDeleted: 'Cart Deleted',
  CartDownloaded: 'Cart Downloaded',
  CartLineQuantityUpdated: 'Cart Line Quantity Updated',
  CartLineRemoved: 'Cart Line Removed',
  CartOptimized: 'Cart Optimized',
  CartUploaded: 'Cart Uploaded',
  CartViewed: 'Cart Viewed',
  CatalogDownloaded: 'Catalog Downloaded',
  CheckoutCompleted: 'Checkout Completed',
  CheckoutStarted: 'Checkout Started',
  FirstCheckoutStarted: 'First Checkout Started',
  CheckoutStepCompleted: 'Checkout Step Completed',
  ContactBannerClicked: 'Contact Banner Clicked',
  FirstCheckoutCompleted: 'First Checkout Completed',
  HomepageVideoStarted: 'Homepage Video Started',
  HomepageVideoStopped: 'Homepage Video Stopped',
  OptimizerExplainerModalViewed: 'Optimizer Explainer Modal Viewed',
  OrderCreated: 'Order Created',
  ProductAdded: 'Product Added',
  ProductClicked: 'Product Clicked',
  ProductListFiltered: 'Product List Filtered',
  ProductListViewed: 'Product List Viewed',
  ProductRemoved: 'Product Removed',
  ProductsSearched: 'Products Searched',
  ProductViewed: 'Product Viewed',
  RegistrationStepCompleted: 'Registration Step Completed',
  ReturnToOptimizedCartClicked: 'Return To Optimized Cart Clicked',
  ShippingBannerModalViewed: 'Shipping Banner Modal Viewed',
  TargetPriceAdded: 'Target Price Added',
  TargetPriceEdited: 'Target Price Edited',
  TargetPriceRemoved: 'Target Price Removed',
  TargetQuantityAdded: 'Target Quantity Added',
  TargetQuantityEdited: 'Target Quantity Edited',
  UserSignedIn: 'User Signed In',
  UserSignedOut: 'User Signed Out',
  UserSignedUp: 'User Signed Up',
  UserVerificationStatusChanged: 'User Verification Status Changed',
  ProductWatchlisted: 'Product Watchlisted',
  WatchlistDownloaded: 'Watchlist Downloaded',
  WatchlistUploaded: 'Watchlist Uploaded',
};

type TrackedProduct = {
  productId: string;
  sku: string;
  category?: string;
  name: string;
  brand?: string;
  price?: string;
  currency?: string;
  quantity?: number;
  imageUrl: string;
  url: string;
  position?: number;
  objectID: string;
};

const getProductDetailsFromSearchHit = ({
  searchHit,
  currency,
  position,
}: {
  searchHit: MeilisearchResponseHitWithAnalytics;
  currency: User['currency'];
  position?: number;
}): TrackedProduct => {
  return {
    productId: searchHit.gtin,
    sku: searchHit.gtin,
    category: searchHit.category_name,
    name: searchHit.name,
    brand: searchHit.brand_name,
    price: searchHit.price_multi?.[getValidatedUserCurrency(currency)],
    currency,
    imageUrl: getUrlPathname(searchHit.image_url),
    url: `/products/${searchHit.fid}/${searchHit.slug}`,
    objectID: searchHit.objectID,
    position,
  };
};

const getProductDetailsFromVariant = ({
  variant,
}: {
  variant: Variant;
}): TrackedProduct => {
  return {
    productId: variant.gtin,
    sku: variant.gtin,
    category: variant.category.name,
    name: variant.name,
    brand: variant.brand.name,
    price: variant.price || undefined,
    currency: variant.priceCurrency,
    // FIXME: noUncheckedIndexedAccess
    // @ts-expect-error, this violates noUncheckIndexedAccess, fix it and and remove ts-expect-error
    imageUrl: getUrlPathname(variant.images[0]?.url),
    url: `/products/${variant.fid}/${variant.slug}`,
    objectID: variant.gtin,
  };
};

const getProductDetailsFromWatchlistItem = ({
  watchlistItem,
}: {
  watchlistItem: WatchlistItem;
}): TrackedProduct => {
  return {
    productId: watchlistItem.gtin,
    sku: watchlistItem.gtin,
    name: watchlistItem.name,
    price: watchlistItem.price || undefined,
    currency: watchlistItem.priceCurrency || undefined,
    // FIXME: noUncheckedIndexedAccess
    // @ts-expect-error, this violates noUncheckIndexedAccess, fix it and and remove ts-expect-error
    imageUrl: getUrlPathname(watchlistItem.images[0]?.url),
    url: `/products/${watchlistItem.fid}/${watchlistItem.slug}`,
    objectID: watchlistItem.gtin,
  };
};

type TrackProductsSearchedMetadata = {
  query: string;
};

type TrackProductAddedMetadata = {
  cartId: string;
  quantity: number;
  product: TrackedProduct;
  queryID?: string;
  origin: string;
};

type TrackProductAddedFromCatalogMetadata = {
  cartId: string;
  product: MeilisearchResponseHitWithAnalytics;
  quantity: number;
  userCurrency: User['currency'];
};

type TrackProductAddedFromProductPageMetadata = {
  cartId: string;
  product: Variant;
  quantity: number;
  queryID?: string;
};

type TrackProductAddedFromWatchlistMetadata = {
  cartId: string;
  product: WatchlistItem;
  quantity: number;
};

type TrackProductClickedMetadata = {
  product: MeilisearchResponseHitWithAnalytics;
  userCurrency: User['currency'];
};

type TrackProductClickedFromWatchlistMetadata = {
  product: WatchlistItem;
};

type ProductListViewedMetadata = {
  products: Hit<MeilisearchResponseHitWithAnalytics>[];
  category?: string;
  userCurrency: User['currency'];
};

type ProductListFilteredMetadata = {
  filters: {
    type: string;
    value: string | string[] | boolean | undefined;
  }[];
  products: Hit<MeilisearchResponseHitWithAnalytics>[];
  userCurrency: User['currency'];
};

type TrackProductViewedMetadata = {
  product: ProductResponse;
  currency: User['currency'];
};

type TrackCartViewedMetadata = Pick<
  Cart,
  'qid' | 'checkoutQid' | 'subtotal' | 'subtotalCurrency' | 'cartlinesCount'
>;

type TrackCartDownloadedMetadata = Pick<
  Cart,
  'qid' | 'checkoutQid' | 'subtotal' | 'subtotalCurrency' | 'estimatedTotal'
>;

type TrackCartComparisonDownloadedMetadata = Pick<
  Checkout,
  'qid' | 'cartQid' | 'currency' | 'subtotal' | 'shipping'
>;

type TrackCartOptimizedMetadata = Pick<
  Checkout,
  'qid' | 'cartQid' | 'currency' | 'subtotal' | 'shipping'
>;

type TrackBackToCartClickedMetadata = Pick<Checkout, 'qid' | 'cartQid'>;

type TrackReturnToOptimizedCartClicked = Pick<
  Cart,
  'qid' | 'checkoutQid' | 'subtotal' | 'subtotalCurrency' | 'estimatedTotal'
>;

type TrackCartDeleted = Pick<Cart, 'qid' | 'checkoutQid'>;

type TrackCartLineQuantityUpdated = {
  cart: Pick<Cart, 'qid' | 'checkoutQid'>;
  cartLine: Pick<CartLine, 'priceCurrency' | 'price' | 'variant' | 'quantity'>;
};

type TrackCartLineProductRemoved = {
  cartQid: string;
  checkoutQid: string;
  gtin: string;
};

type TrackCheckoutStartedMetadata = {
  checkout: Pick<
    Checkout,
    'cartQid' | 'currency' | 'subtotal' | 'shipping' | 'qid'
  >;
};

type TrackFirstCheckoutStartedMetadata = {
  checkout: Pick<
    Checkout,
    'cartQid' | 'currency' | 'subtotal' | 'shipping' | 'qid'
  >;
};

type TrackCheckoutStepCompleted = {
  step: string;
  checkout: Pick<Checkout, 'cartQid' | 'qid'>;
};

type TrackCheckoutCompletedMetadata = {
  order: Order;
};

type TrackFirstCheckoutCompletedMetadata = {
  order: CheckoutComplete;
};

type TrackUserSignedInMetadata = {
  email: string;
};

type RegistrationStepCompletedMetadata = {
  step: 'Basic Details' | 'Company Details' | 'Distribution Channels';
};

type TrackUserSignedUpMetadata = {
  email: string;
  account: string;
  company: string;
  personaDistributionChannels?: PersonaDistributionChannels[] | null;
  annualTurnover?: AnnualTurnover;
  numberOfEmployees?: NumberOfEmployees;
};

type TrackUserVerificationStatusChangedMetadata = {
  status: 'email verified' | 'phone verified' | 'all verified';
  userId: string | undefined;
  verification: {
    email: boolean | undefined;
    phone: boolean | undefined;
  };
};

type TrackCatalogDownloaded = {
  category: string;
};

type TrackContactBannerClicked = {
  bannerType: 'generic';
  contactType: 'meeting' | 'email';
};

type TrackHomepageVideoStopped = {
  currentTime: number;
};

type TrackAccountManagerContactClickedMetadata = {
  medium: string;
};

type TrackProductWatchlistedMetadata = {
  product: Variant;
};

export function useTrackEvent() {
  const { track } = useAnalytics();
  const isManuallyLoadGtagEnabled = useIsManuallyLoadGtagEnabled();

  const trackProductAdded = useCallback(
    ({
      cartId,
      product,
      quantity,
      queryID,
      origin,
    }: TrackProductAddedMetadata) => {
      try {
        trackLinkedInConversion('ProductAdded');

        const value = Number(product.price) * Number(quantity);
        track(AnalyticsEvents.ProductAdded, {
          cartId,
          quantity,
          index: SEARCH_INDEX_NAME,
          eventType: 'conversion',
          queryID,
          origin,
          value,
          ...product,
        });
        if (isManuallyLoadGtagEnabled) {
          gtagEvent('add_to_cart', {
            items: [
              {
                item_id: product.productId,
                item_name: product.name,
                item_brand: product.brand,
                item_category: product.category,
                price: product.price,
                quantity: product.quantity,
              },
            ],
            value,
            currency: product.currency,
          });
          gtagConversion(GOOGLE_CONVERSION_IDS.ProductAdded, {
            currency: product.currency,
            value,
            transaction_id: cartId,
          });
        }
      } catch (error) {
        logError(error);
      }
    },
    [isManuallyLoadGtagEnabled, track],
  );

  return useMemo(
    () => ({
      trackProductsSearched({ query }: TrackProductsSearchedMetadata) {
        track(AnalyticsEvents.ProductsSearched, {
          query,
          index: SEARCH_INDEX_NAME,
        });
        if (isManuallyLoadGtagEnabled) {
          gtagEvent('search', { search_term: query });
        }
      },
      trackProductAddedFromBrandPage({
        cartId,
        product,
        quantity,
        userCurrency,
      }: TrackProductAddedFromCatalogMetadata) {
        trackProductAdded({
          cartId,
          product: getProductDetailsFromSearchHit({
            searchHit: product,
            currency: userCurrency,
          }),
          quantity,
          origin: 'brandpage',
        });
      },
      trackProductAddedFromCatalog({
        cartId,
        product,
        quantity,
        userCurrency,
      }: TrackProductAddedFromCatalogMetadata) {
        trackProductAdded({
          cartId,
          product: getProductDetailsFromSearchHit({
            searchHit: product,
            currency: userCurrency,
          }),
          quantity,
          origin: 'catalog',
        });
      },
      trackProductAddedFromProductPage({
        cartId,
        product,
        quantity,
        queryID,
      }: TrackProductAddedFromProductPageMetadata) {
        trackProductAdded({
          cartId,
          product: getProductDetailsFromVariant({ variant: product }),
          quantity,
          queryID,
          origin: 'PDP',
        });
      },
      trackProductAddedFromWatchlist({
        cartId,
        product,
        quantity,
      }: TrackProductAddedFromWatchlistMetadata) {
        trackProductAdded({
          cartId,
          product: getProductDetailsFromWatchlistItem({
            watchlistItem: product,
          }),
          quantity,
          origin: 'watchlist',
        });
      },
      trackProductClicked({
        product,
        userCurrency,
      }: TrackProductClickedMetadata) {
        const trackedProduct = getProductDetailsFromSearchHit({
          searchHit: product,
          currency: userCurrency,
        });
        track(AnalyticsEvents.ProductClicked, {
          currency: userCurrency,
          position: product.__position,
          index: SEARCH_INDEX_NAME,
          eventType: 'click',
          queryID: product.__queryID,
          ...trackedProduct,
        });
      },
      trackProductClickedFromWatchlist({
        product,
      }: TrackProductClickedFromWatchlistMetadata) {
        track(AnalyticsEvents.ProductClicked, {
          currency: product.priceCurrency,
          eventType: 'click',
          origin: 'watchlist',
          ...product,
        });
      },
      trackProductListViewed({
        products,
        category,
        userCurrency,
      }: ProductListViewedMetadata) {
        const trackedProducts = products.map((product) => {
          return {
            productId: product.gtin,
            sku: product.gtin,
            category: product.category_name,
            name: product.name,
            brand: product.brand_name,
            price:
              product?.price_multi?.[getValidatedUserCurrency(userCurrency)],
            quantity: product.inventory,
            image_url: product.image_url,
            url: `/products/${product.fid}/${product.slug}`,
            position: product.__position,
          };
        });
        track(AnalyticsEvents.ProductListViewed, {
          category,
          products: trackedProducts,
        });
        if (isManuallyLoadGtagEnabled) {
          gtagEvent('view_item_list', {
            products: trackedProducts.map((product) => ({
              item_id: product.productId,
              item_name: product.name,
              index: product.position,
              item_brand: product.brand,
              item_category: product.category,
              price: product.price,
              quantity: product.quantity,
            })),
          });
        }
      },
      trackProductListFiltered({
        filters,
        products,
        userCurrency,
      }: ProductListFilteredMetadata) {
        const trackedProducts = products.map((product) => ({
          productId: product.gtin,
          sku: product.gtin,
          category: product.category_name,
          name: product.name,
          brand: product.brand_name,
          price: product?.price_multi?.[getValidatedUserCurrency(userCurrency)],
          quantity: product.inventory,
          image_url: product.image_url,
          url: `/products/${product.fid}/${product.slug}`,
          position: product.__position,
        }));
        track(AnalyticsEvents.ProductListFiltered, {
          filters,
          products: trackedProducts,
        });
      },
      trackProductViewed({ product }: TrackProductViewedMetadata) {
        const variant = product.variant;
        const productToTrack = getProductDetailsFromVariant({ variant });
        track(AnalyticsEvents.ProductViewed, productToTrack);
        if (isManuallyLoadGtagEnabled) {
          gtagEvent('view_item', {
            items: [
              {
                item_id: productToTrack.productId,
                item_name: productToTrack.name,
                item_brand: productToTrack.brand,
                item_category: productToTrack.category,
                price: productToTrack.price,
                quantity: productToTrack.quantity,
              },
            ],
            currency: productToTrack.currency,
            value: productToTrack.price,
          });
        }
      },
      trackCartViewed({
        qid,
        checkoutQid,
        subtotal,
        subtotalCurrency,
        cartlinesCount,
      }: TrackCartViewedMetadata) {
        track(AnalyticsEvents.CartViewed, {
          cartId: qid,
          orderId: checkoutQid,
          subtotal,
          subtotalCurrency,
          cartlinesCount,
        });
      },
      trackCartDownloaded(cart: TrackCartDownloadedMetadata) {
        track(AnalyticsEvents.CartDownloaded, {
          cartId: cart.qid,
          orderId: cart.checkoutQid,
          currency: cart.subtotalCurrency,
          subtotal: cart.subtotal,
          total: cart.estimatedTotal,
        });
      },
      trackCartComparisonDownloaded(
        checkout: TrackCartComparisonDownloadedMetadata,
      ) {
        track(AnalyticsEvents.CartComparisonDownloaded, {
          cartId: checkout.cartQid,
          orderId: checkout.qid,
          currency: checkout.currency,
          subtotal: checkout.subtotal,
          shipping: checkout.shipping,
        });
      },
      trackCartOptimized(checkout: TrackCartOptimizedMetadata) {
        track(AnalyticsEvents.CartOptimized, {
          cartId: checkout.cartQid,
          orderId: checkout.qid,
          currency: checkout.currency,
          subtotal: checkout.subtotal,
          shipping: checkout.shipping,
        });
      },
      trackBackToCartClicked(checkout: TrackBackToCartClickedMetadata) {
        track(AnalyticsEvents.BackToCartClicked, {
          cartId: checkout.cartQid,
          orderId: checkout.qid,
        });
      },
      trackReturnToOptimizedCartClicked(
        cart: TrackReturnToOptimizedCartClicked,
      ) {
        track(AnalyticsEvents.ReturnToOptimizedCartClicked, {
          cartId: cart.qid,
          orderId: cart.checkoutQid,
          currency: cart.subtotalCurrency,
          subtotal: cart.subtotal,
          total: cart.estimatedTotal,
        });
      },
      trackCartDeleted(cart: TrackCartDeleted) {
        track(AnalyticsEvents.CartDeleted, {
          cartId: cart.qid,
          orderId: cart.checkoutQid,
        });
      },
      trackCartLineQuantityUpdated(data: TrackCartLineQuantityUpdated) {
        track(AnalyticsEvents.CartLineQuantityUpdated, {
          cartId: data.cart.qid,
          orderId: data.cart.checkoutQid,
          quantity: data.cartLine.quantity,
          productGtin: data.cartLine.variant.gtin,
          price: data.cartLine.price,
          currency: data.cartLine.priceCurrency,
        });
      },
      trackCartLineProductRemoved({
        cartQid,
        checkoutQid,
        gtin,
      }: TrackCartLineProductRemoved) {
        track(AnalyticsEvents.CartLineRemoved, {
          cartQid,
          checkoutQid,
          gtin,
        });
        if (isManuallyLoadGtagEnabled) {
          gtagEvent('remove_from_cart');
        }
      },
      trackCheckoutStarted({ checkout }: TrackCheckoutStartedMetadata) {
        track(AnalyticsEvents.CheckoutStarted, {
          cartId: checkout.cartQid,
          orderId: checkout.qid,
          currency: checkout.currency,
          subtotal: checkout.subtotal,
          // This is replicated to add the `value` key
          // enabling Segment to automatically map this to Google Ads
          value: checkout.subtotal,
          shipping: checkout.shipping,
        });
        if (isManuallyLoadGtagEnabled) {
          gtagEvent('begin_checkout');
          gtagConversion(GOOGLE_CONVERSION_IDS.CheckoutStarted, {
            currency: checkout.currency,
            value: Number(checkout.subtotal),
            transaction_id: checkout.qid,
          });
        }
      },
      trackFirstCheckoutStarted({
        checkout,
      }: TrackFirstCheckoutStartedMetadata) {
        trackLinkedInConversion('FirstCheckoutStarted');
        track(AnalyticsEvents.FirstCheckoutStarted, {
          cartId: checkout.cartQid,
          orderId: checkout.qid,
          currency: checkout.currency,
          subtotal: checkout.subtotal,
          // This is replicated to add the `value` key
          // enabling Segment to automatically map this to Google Ads
          value: checkout.subtotal,
          shipping: checkout.shipping,
        });
        if (isManuallyLoadGtagEnabled) {
          gtagEvent('first_checkout_started', {
            value: checkout.subtotal,
            currency: checkout.currency,
            order_id: checkout.qid,
          });
          gtagConversion(GOOGLE_CONVERSION_IDS.FirstCheckoutStarted, {
            currency: checkout.currency,
            value: Number(checkout.subtotal),
            transaction_id: checkout.qid,
          });
        }
      },
      trackCheckoutStepCompleted({
        step,
        checkout,
      }: TrackCheckoutStepCompleted) {
        track(AnalyticsEvents.CheckoutStepCompleted, {
          step,
          cartId: checkout.cartQid,
          orderId: checkout.qid,
          index: SEARCH_INDEX_NAME,
        });
        if (isManuallyLoadGtagEnabled) {
          if (step === 'Address') {
            gtagEvent('checkout_step_completed_address');
          }
          if (step === 'Financing & payment') {
            gtagEvent('checkout_step_completed_financing_payment');
          }
        }
      },
      trackCheckoutCompleted({ order }: TrackCheckoutCompletedMetadata) {
        trackLinkedInConversion('CheckoutCompleted');
        const productsToTrack = order.orderlines.map((orderline) => ({
          ...getProductDetailsFromVariant({ variant: orderline.variant }),
          quantity: orderline.quantity,
        }));
        track(AnalyticsEvents.CheckoutCompleted, {
          orderId: order.qid,
          isOptimized: order.status === 'CHECKOUT',
          total: order.total,
          // This is replicated to add the `value` key
          // enabling Segment to automatically map this to Google Ads
          value: order.total,
          subtotal: order.subtotal,
          revenue: null,
          shipping: order.shipping,
          tax: order.vat,
          currency: order.totalCurrency,
          products: productsToTrack,
          index: SEARCH_INDEX_NAME,
        });
        if (isManuallyLoadGtagEnabled) {
          gtagEvent('checkout_step_completed_review', {
            value: order.total,
            currency: order.totalCurrency,
          });
          gtagEvent('purchase', {
            currency: order.totalCurrency,
            products: productsToTrack.map((product) => ({
              item_id: product.productId,
              item_name: product.name,
              item_brand: product.brand,
              item_category: product.category,
              price: product.price,
              quantity: product.quantity,
            })),
            order_id: order.qid,
            shipping: order.shipping,
            tax: order.vat,
            value: order.total,
          });
          gtagConversion(GOOGLE_CONVERSION_IDS.CheckoutCompleted, {
            currency: order.totalCurrency,
            value: Number(order.total),
            transaction_id: order.qid,
          });
        }
      },
      trackFirstCheckoutCompleted({
        order,
      }: TrackFirstCheckoutCompletedMetadata) {
        trackLinkedInConversion('FirstCheckoutCompleted');
        track(AnalyticsEvents.FirstCheckoutCompleted, {
          orderId: order.qid,
          isOptimized: order.status === 'CHECKOUT',
          total: order.total,
          // This is replicated to add the `value` key
          // enabling Segment to automatically map this to Google Ads
          value: order.total,
          subtotal: order.subtotal,
          revenue: null,
          shipping: order.shipping,
          tax: order.vat,
          currency: order.totalCurrency,
          products: order.orderlines.map((orderline) => ({
            ...getProductDetailsFromVariant({ variant: orderline.variant }),
            quantity: orderline.quantity,
          })),
          index: SEARCH_INDEX_NAME,
        });
        if (isManuallyLoadGtagEnabled) {
          gtagEvent('first_time_purchase', {
            order_id: order.qid,
            currency: order.totalCurrency,
            value: order.total,
          });
          gtagConversion(GOOGLE_CONVERSION_IDS.FirstCheckoutCompleted, {
            currency: order.totalCurrency,
            value: Number(order.total),
            transaction_id: order.qid,
          });
        }
      },
      trackUserSignedIn({ email }: TrackUserSignedInMetadata) {
        track(AnalyticsEvents.UserSignedIn, {
          username: email,
        });
        if (isManuallyLoadGtagEnabled) {
          gtagEvent('login');
        }
      },
      trackUserSignedOut() {
        track(AnalyticsEvents.UserSignedOut);
      },
      trackRegistrationStepCompleted({
        step,
      }: RegistrationStepCompletedMetadata) {
        track(AnalyticsEvents.RegistrationStepCompleted, {
          step,
        });
      },
      trackUserSignedUp({
        email,
        account,
        company,
        personaDistributionChannels,
        annualTurnover,
        numberOfEmployees,
      }: TrackUserSignedUpMetadata) {
        trackLinkedInConversion('UserSignedUp');
        track(AnalyticsEvents.UserSignedUp, {
          account,
          email,
          company,
          personaDistributionChannels,
          annualTurnover,
          numberOfEmployees,
        });
        if (isManuallyLoadGtagEnabled) {
          gtagEvent('sign_up');
          gtagConversion(GOOGLE_CONVERSION_IDS.UserSignedUp);
        }
      },
      trackUserVerificationStatusChanged({
        status,
        userId,
        verification,
      }: TrackUserVerificationStatusChangedMetadata) {
        track(AnalyticsEvents.UserVerificationStatusChanged, {
          status,
          userId,
          verification,
        });
        if (isManuallyLoadGtagEnabled) {
          gtagConversion(GOOGLE_CONVERSION_IDS.UserVerificationStatusChanged);
        }
      },
      trackCatalogDownloaded({ category }: TrackCatalogDownloaded) {
        track(AnalyticsEvents.CatalogDownloaded, {
          category,
        });
      },
      trackHomepageVideoStopped(meta: TrackHomepageVideoStopped) {
        track(AnalyticsEvents.HomepageVideoStopped, meta);
      },
      trackContactBannerClicked(meta: TrackContactBannerClicked) {
        track(AnalyticsEvents.ContactBannerClicked, meta);
      },
      trackShippingBannerModalViewed() {
        track(AnalyticsEvents.ShippingBannerModalViewed);
      },
      trackHomepageVideoStarted() {
        track(AnalyticsEvents.HomepageVideoStarted);
      },
      trackOptimizerExplainerModalViewed() {
        track(AnalyticsEvents.OptimizerExplainerModalViewed);
      },
      trackAccountMenuOpened() {
        track(AnalyticsEvents.AccountMenuOpened);
      },
      trackAccountManagerContactClicked({
        medium,
      }: TrackAccountManagerContactClickedMetadata) {
        track(AnalyticsEvents.AccountManagerContactClicked, {
          medium,
        });
      },
      trackProductWatchlisted({ product }: TrackProductWatchlistedMetadata) {
        track(AnalyticsEvents.ProductWatchlisted, {
          gtin: product.gtin,
          name: product.name,
          price: product.price,
          currency: product.priceCurrency,
          category: product.category.name,
        });
      },
      trackWatchlistDownloaded() {
        track(AnalyticsEvents.WatchlistDownloaded);
      },
      trackWatchlistUploaded() {
        track(AnalyticsEvents.WatchlistUploaded);
      },
      trackTargetPriceAdded({
        gtin,
        targetPrice,
        currency,
        origin,
      }: {
        gtin: string;
        targetPrice: string;
        currency: BuyerCurrency;
        origin: string;
      }) {
        track(AnalyticsEvents.TargetPriceAdded, {
          gtin,
          targetPrice,
          currency,
          origin,
        });
      },
      trackTargetPriceEdited({
        gtin,
        targetPrice,
        currency,
        origin,
      }: {
        gtin: string;
        targetPrice: string | null;
        currency: BuyerCurrency;
        origin: string;
      }) {
        track(AnalyticsEvents.TargetPriceEdited, {
          gtin,
          targetPrice,
          currency,
          origin,
        });
      },
      trackTargetPriceRemoved({
        gtin,
        origin,
      }: {
        gtin: string;
        origin: string;
      }) {
        track(AnalyticsEvents.TargetPriceRemoved, { gtin, origin });
      },
      trackTargetQuantityAdded({
        gtin,
        targetQuantity,
        origin,
      }: {
        gtin: string;
        targetQuantity: number;
        origin: string;
      }) {
        track(AnalyticsEvents.TargetQuantityAdded, {
          gtin,
          targetQuantity,
          origin,
        });
      },
      trackTargetQuantityEdited({
        gtin,
        targetQuantity,
        origin,
      }: {
        gtin: string;
        targetQuantity: number | null;
        origin: string;
      }) {
        track(AnalyticsEvents.TargetQuantityEdited, {
          gtin,
          targetQuantity,
          origin,
        });
      },
    }),
    [isManuallyLoadGtagEnabled, track, trackProductAdded],
  );
}

export const trackUserSignedOut = () => {
  try {
    track(AnalyticsEvents.UserSignedOut);
  } catch (error) {
    logError(error);
  }
};
