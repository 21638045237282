type Props = {
  href: string;
  children: React.ReactNode;
  className?: string;
};

export const LinkExternal = ({
  href,
  children,
  className,
}: Props): JSX.Element => (
  <a
    className={className}
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);
