import type { Subscriber, SubscribersRequest } from '@qogita/canary-types';
import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { environment } from '#lib/environment.mjs';

type Variables = {
  data: SubscribersRequest;
};

type PostSubscriber = (params: Variables) => Promise<Subscriber>;

const ERROR_MESSAGE_CATCH_ALL =
  'There was an error subscribing your email address. Please try again or speak to a member of our team if the issue persists.';

const postSubscriber: PostSubscriber = async ({ data }) => {
  const response = await fetch(
    `${environment.NEXT_PUBLIC_API_BASE_URL}/subscribers/`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  );

  if (response.ok) return response.json();
  throw new Error(ERROR_MESSAGE_CATCH_ALL);
};

export const useCreateSubscriber = (): UseMutationResult<
  Subscriber,
  Error,
  Variables
> => {
  return useMutation<Subscriber, Error, Variables>({
    mutationFn: ({ data }) => postSubscriber({ data }),
  });
};
