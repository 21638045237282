import { useField } from 'formik';

import { CURRENCY_SYMBOLS_MAP } from '#lib/currency';

type Props = {
  id?: string;
  className?: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
};

/**
 * @deprecated Please use `<CurrencyField />` as this uses the new UI Library
 */
export const CurrencyFieldOld = (props: Props): JSX.Element => {
  const [field] = useField(props);
  const currencies = Object.keys(CURRENCY_SYMBOLS_MAP);

  return (
    <select {...field} {...props}>
      {currencies.map((code) => (
        <option key={code} value={code}>
          {code}
        </option>
      ))}
    </select>
  );
};
