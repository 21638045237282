import type { Statistics } from '@qogita/canary-types';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { useAuthentication } from '#contexts/Authentication';
import { environment } from '#lib/environment.mjs';
import { ONE_HOUR_MS } from '#lib/time';

import { queryKeys } from './queryKeys';

type GetInfo = () => Promise<Statistics>;

const getInfo: GetInfo = async () => {
  const response = await fetch(
    `${environment.NEXT_PUBLIC_API_BASE_URL}/info/`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.ok) return response.json();
  throw new Error('getInfo fetch failed.');
};

export const useInfo = (): UseQueryResult<Statistics, Error> => {
  const { status } = useAuthentication();
  return useQuery<Statistics, Error>({
    ...queryKeys.info.all,
    enabled: status !== 'loading',
    queryFn: getInfo,
    staleTime: ONE_HOUR_MS,
  });
};
