import clsx from 'clsx';
import { type ComponentProps, forwardRef, type ReactElement } from 'react';

import { DeprecatedInput, ErrorInputAdornment } from '../../DeprecatedInput';

type InputProps = ComponentProps<typeof DeprecatedInput>;

/**
 * @deprecated
 */
export type DeprecatedFormInputProps = InputProps & {
  startAdornment?: ReactElement | false;
  endAdornment?: ReactElement | false;
};

/**
 * @deprecated
 */
export const DeprecatedFormInput = forwardRef<
  HTMLInputElement,
  DeprecatedFormInputProps
>(function DeprecatedFormInput(formInputProps, formInputRef) {
  const {
    className,
    error = false,
    startAdornment,
    endAdornment,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    required, // Avoid native behavior - favour custom error behavior as it's more descriptive
    ...props
  } = formInputProps;

  const hasPropsError = error || isAriaInvalidTruthy(props['aria-invalid']);
  const hasError: boolean = props.disabled ? false : hasPropsError;

  return (
    <div className="relative rounded">
      {startAdornment}

      <DeprecatedInput
        {...props}
        ref={formInputRef}
        error={hasError}
        className={clsx(
          className,
          'w-full', // Investigate different sizes
          {
            'pr-8': hasError, // Required for `ErrorInputAdornment`
          },
        )}
      />

      {hasError ? <ErrorInputAdornment /> : endAdornment}
    </div>
  );
});

const isAriaInvalidTruthy = (
  ariaInvalid: DeprecatedFormInputProps['aria-invalid'],
): boolean => {
  return ariaInvalid === true || ariaInvalid === 'true';
};
