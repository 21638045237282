import {
  arrow,
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  type Placement,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import {
  cloneElement,
  createContext,
  forwardRef,
  HTMLProps,
  isValidElement,
  ReactNode,
  type RefCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import { mergeReferences as mergeReferences } from '../../Utils';

const TooltipContext = createContext<ReturnType<typeof useTooltip> | null>(
  null,
);
const useTooltipState = () => {
  const context = useContext(TooltipContext);

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }

  return context;
};

type TooltipOptions = {
  placement?: Placement;
  initialOpen?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  showArrow?: boolean;
};

function useTooltip({
  placement = 'top',
  initialOpen = false,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  showArrow = true,
}: TooltipOptions = {}) {
  const arrowRef = useRef(null);
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(8),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const context = data.context;

  const hover = useHover(context, {
    move: true,
    handleClose: safePolygon(),
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      arrowRef,
      showArrow,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data],
  );
}

type TooltipRootProps = {
  children: ReactNode;
} & TooltipOptions;

const TooltipRoot = ({ children, ...options }: TooltipRootProps) => {
  const tooltip = useTooltip(options);
  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
};

type TriggerProps = HTMLProps<HTMLElement> & { asChild?: boolean } & {
  asChild?: boolean;
};

const Trigger = forwardRef<HTMLElement, TriggerProps>(function Trigger(
  { children, ...props },
  propertyRef,
) {
  const state = useTooltipState();

  const ref: RefCallback<HTMLButtonElement> = useMemo(
    // TODO: deal with children type, either ignore or type properly
    () =>
      mergeReferences([
        state.refs.setReference,
        propertyRef,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (children as any).ref,
      ]),
    [state.refs.setReference, propertyRef, children],
  );

  if (isValidElement(children)) {
    return cloneElement(
      children,
      state.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': state.open ? 'open' : 'closed',
      }),
    );
  }

  return (
    <button ref={ref} {...state.getReferenceProps(props)} type="button">
      {children}
    </button>
  );
});

type ContentProps = React.HTMLProps<HTMLDivElement>;

const Content = forwardRef<HTMLDivElement, ContentProps>(function Content(
  { children, ...props },
  propertyRef,
) {
  const { arrowRef, showArrow, ...state } = useTooltipState();

  // placement can be in the form of "top-start" or "top"
  // we only want the first part if it's in the form of "top-start"
  const placement = state.placement.split('-')[0];
  // We need to know the static side of the tooltip to position the arrow
  const staticSide = {
    top: 'bottom',
    bottom: 'top',
    left: 'right',
    right: 'left',
    // We default to a top placement if we have nothing else defined
  }[placement ?? 'top'];

  const ref = useMemo(
    () => mergeReferences([state.refs.setFloating, propertyRef]),
    [state.refs.setFloating, propertyRef],
  );

  return (
    <FloatingPortal>
      {state.open ? (
        <div
          className="z-10 max-w-xs rounded bg-gray-500 px-2 py-2 text-xs leading-5 text-white"
          ref={ref}
          style={{
            position: state.strategy,
            top: state.y ?? 0,
            left: state.x ?? 0,
            ...props.style,
          }}
          {...state.getFloatingProps(props)}
        >
          {children}
          {showArrow ? (
            <div
              className="absolute h-2 w-2 rotate-45 transform bg-gray-500"
              style={{
                top: state.middlewareData.arrow?.y ?? '',
                left: state.middlewareData.arrow?.x ?? '',
                bottom: '',
                right: '',
                // staticSide can potentially be undefined according to the types.
                // In practice this is impossible, but I can't get TS to understand that.
                [staticSide || 'top']: '-4px',
              }}
              ref={arrowRef}
            />
          ) : null}
        </div>
      ) : null}
    </FloatingPortal>
  );
});

export const Tooltip = Object.assign(TooltipRoot, {
  Trigger,
  Content,
});
