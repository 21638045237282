import { z } from 'zod';

export const COOKIE_CONSENT_KEY = 'q_consent';

export const consentValueSchema = z.object({
  functional: z.boolean(),
  performance: z.boolean(),
  marketing: z.boolean(),
});

export type ConsentValue = z.infer<typeof consentValueSchema>;
export type ConsentCategory = keyof ConsentValue;
