import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import {
  ComponentPropsWithoutRef,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type AccordionContextType = {
  expandedItem: string | null;
  setExpandedItem: Dispatch<SetStateAction<string | null>>;
  closeOthers: boolean;
  separator: boolean;
};

const AccordionContext = createContext<AccordionContextType | null>(null);
const useAccordionContext = () => {
  const context = useContext(AccordionContext);
  if (!context) {
    throw new Error('useAccordionContext must be used within a Accordion');
  }
  return context;
};

type ItemContextType = {
  name: string;
};

const ItemContext = createContext<ItemContextType | null>(null);
const useItemContext = () => {
  const context = useContext(ItemContext);
  if (!context) {
    throw new Error('useItemContext must be used within a Accordion Item');
  }
  return context;
};

type ButtonProps = {
  children: React.ReactNode;
};
function Button({ children }: ButtonProps) {
  const { setExpandedItem } = useAccordionContext();
  const { name } = useItemContext();

  return (
    <Disclosure.Button
      className="grid w-full grid-cols-[9fr_1fr] gap-4 px-4 font-bold md:px-6"
      onClick={() => setExpandedItem(name)}
    >
      {({ open }) => (
        <>
          {typeof children === 'string' ? (
            <span className="text-left">{children}</span>
          ) : (
            children
          )}
          <span className="flex h-full items-start justify-end">
            <ChevronDownIcon
              className={clsx('h-6 w-6 transition-transform', {
                'rotate-180 transform': open,
              })}
            />
          </span>
        </>
      )}
    </Disclosure.Button>
  );
}

type PanelProps = {
  children: React.ReactNode;
};
function Panel({ children }: PanelProps) {
  const { expandedItem, closeOthers } = useAccordionContext();
  const { name } = useItemContext();

  return (
    <Disclosure.Panel className="mt-3 text-gray-700">
      {({ open, close }) => {
        if (closeOthers && expandedItem && expandedItem !== name && open) {
          close();
        }

        return typeof children === 'string' ? (
          <p className="px-4 md:px-6">{children}</p>
        ) : (
          <>{children}</>
        );
      }}
    </Disclosure.Panel>
  );
}

type ItemProps = {
  children: [React.ReactNode, React.ReactNode];
  name: string;
  className?: string;
  defaultOpen?: boolean;
};
function Item({ children, name, defaultOpen = false, className }: ItemProps) {
  const { separator } = useAccordionContext();

  return (
    <ItemContext.Provider value={{ name }}>
      <Disclosure defaultOpen={defaultOpen}>
        <div
          className={clsx(
            'bg-white py-4',
            separator ? 'border-b border-gray-300 first:border-t' : '',
            className,
          )}
        >
          {children}
        </div>
      </Disclosure>
    </ItemContext.Provider>
  );
}

type AccordionProps = ComponentPropsWithoutRef<'div'> & {
  className?: string;
  closeOthers?: boolean;
  separator?: boolean;
  children: Array<React.ReactElement<ItemProps>>;
};

function AccordionRoot({
  className,
  closeOthers = true,
  separator = true,
  children,
  ...props
}: AccordionProps) {
  const [expandedItem, setExpandedItem] = useState<string | null>(null);
  return (
    <AccordionContext.Provider
      value={{ expandedItem, setExpandedItem, closeOthers, separator }}
    >
      <div className={className} {...props}>
        {children}
      </div>
    </AccordionContext.Provider>
  );
}

export function AccordionTestComponent({
  closeOthers,
  defaultOpenItemIndex,
}: {
  closeOthers?: boolean;
  defaultOpenItemIndex?: number;
}) {
  return (
    <Accordion className="bg-gray-200 p-8" closeOthers={closeOthers}>
      <Accordion.Item name="panel1" defaultOpen={defaultOpenItemIndex === 0}>
        <Accordion.Button>first item title</Accordion.Button>
        <Accordion.Panel> first item content</Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item name="panel2" defaultOpen={defaultOpenItemIndex === 1}>
        <Accordion.Button>second item title</Accordion.Button>
        <Accordion.Panel> second item content</Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item name="panel3" defaultOpen={defaultOpenItemIndex === 2}>
        <Accordion.Button>third item title</Accordion.Button>
        <Accordion.Panel> third item content</Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}

export const Accordion = Object.assign(AccordionRoot, {
  Item,
  Button,
  Panel,
});
