import {
  ArchiveBoxIcon,
  ArrowDownCircleIcon,
  MapPinIcon,
  ReceiptPercentIcon,
  StarIcon,
  UserCircleIcon,
  WalletIcon,
} from '@heroicons/react/20/solid';
import {
  GiftIcon,
  UserCircleIcon as UserCircleIcon24,
} from '@heroicons/react/24/solid';
import { Anchor, Button, Drawer, DropdownMenu } from '@qogita/ui';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { forwardRef, useState } from 'react';
import { UrlObject } from 'url';

import { WhatsAppIcon } from '#components/Icons';
import { AccountNavigation } from '#components/pages/AccountPage/AccountNavigation';
import { useAuthentication } from '#contexts/Authentication';
import { useUser } from '#hooks/api/useUser';
import { useTrackEvent } from '#lib/report/tracking';
import { vercelImageLoader } from '#lib/url';

const MastheadText = () => {
  const { data: user } = useUser();

  return (
    <span className="flex flex-row items-center">
      <span className="mr-1 flex flex-col text-right">
        {user ? (
          <>
            <span className="q-text-body-xs max-w-[8rem] overflow-hidden overflow-ellipsis whitespace-nowrap leading-none">
              Hello, {user.account.split(' ')[0]}
            </span>
            <span className="q-text-body-xs-bold leading-none">Account</span>
          </>
        ) : (
          <>
            <span className="q-text-body-xs whitespace-nowrap leading-none">
              Hello
            </span>
            <span className="q-text-body-xs-bold leading-none">Login</span>
          </>
        )}
      </span>
      <UserCircleIcon24 className="h-8 w-8 fill-current" />
    </span>
  );
};

type MenuLinkProps = {
  className?: string;
  href: string | UrlObject;
  children: React.ReactNode;
};

const MenuLink = forwardRef<HTMLAnchorElement, MenuLinkProps>(
  ({ href, children, className, ...props }, ref) => {
    return (
      <Link
        className={clsx(
          'flex flex-row items-center px-3 py-2 text-sm',
          className,
        )}
        href={href}
        ref={ref}
        {...props}
      >
        {children}
      </Link>
    );
  },
);

MenuLink.displayName = 'MenuLink';

const AccountManagerSection = () => {
  const { data: user } = useUser();
  const accountManager = user?.accountManager;
  const { trackAccountManagerContactClicked } = useTrackEvent();

  if (!accountManager) return null;

  return (
    <DropdownMenu.Section>
      <div className="px-6 py-4 sm:px-3 sm:py-3">
        <div className="flex items-center gap-2">
          {accountManager.profilePicture ? (
            <Image
              src={accountManager.profilePicture}
              alt={`${accountManager.account} picture`}
              width={32}
              height={32}
              className="rounded-full"
              loader={vercelImageLoader}
            />
          ) : (
            <UserCircleIcon24 className="h-4 w-4 fill-gray-300" />
          )}
          <div className="ml-1 flex flex-col items-start">
            <p className="q-text-body-base-bold whitespace-nowrap sm:text-xs sm:font-normal sm:text-gray-500">
              Your account manager
            </p>
            <p className="max-w-[11rem] overflow-hidden overflow-ellipsis whitespace-nowrap">
              {accountManager.account}
            </p>
          </div>
        </div>
        <div className="mt-2 flex flex-col items-start gap-1 sm:gap-0">
          {accountManager.whatsappNumber ? (
            <Anchor
              href={`https://wa.me/${accountManager.whatsappNumber.replaceAll(
                /\D/g,
                '',
              )}`}
              className="text-sm"
              target="_blank"
              onClick={() =>
                trackAccountManagerContactClicked({
                  medium: 'whatsApp',
                })
              }
            >
              <span className="flex items-center">
                <WhatsAppIcon className="h-6.5 w-6.5" />
                {accountManager.whatsappNumber}
              </span>
            </Anchor>
          ) : null}
          <Anchor
            href={`mailto:${accountManager.accountManagerEmail}`}
            className="text-sm"
            onClick={() =>
              trackAccountManagerContactClicked({
                medium: 'email',
              })
            }
          >
            {accountManager.accountManagerEmail}
          </Anchor>
        </div>
        <Anchor
          href={accountManager.meetingLink}
          target="_blank"
          className="mt-3 text-sm sm:mt-4"
          onClick={() =>
            trackAccountManagerContactClicked({
              medium: 'calendly',
            })
          }
        >
          <span className="font-medium tracking-wider">Book a Call</span>
        </Anchor>
      </div>
    </DropdownMenu.Section>
  );
};

type MastheadAccountProps = {
  className?: string;
};
export const MastheadAccount = ({
  className,
}: MastheadAccountProps): JSX.Element => {
  const { data: user } = useUser();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { trackAccountMenuOpened } = useTrackEvent();

  const { logout } = useAuthentication();

  const { asPath } = useRouter();
  const loginUrl = { pathname: '/login/', query: { redirectTo: asPath } };

  return (
    <>
      {/* MOBILE we show a side drawer for navigation */}
      <div className={clsx('sm:hidden', className)}>
        <button
          onClick={() => {
            setIsDrawerOpen(true);
            trackAccountMenuOpened();
          }}
        >
          <UserCircleIcon24 className="mt-[0.313rem] h-6 w-6 fill-current sm:h-8 sm:w-8" />
        </button>
        <Drawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          align="right"
          className="z-[9999999]" //avoids support widget overlapping the Drawer's register button
        >
          <Drawer.Backdrop />
          <Drawer.Content className="flex flex-col divide-y-[0.5px] divide-gray-300">
            <div className="px-7 py-4">
              <p className="pt-8">
                Hello{user ? `, ${user.account.split(' ')[0]}` : null}
              </p>
              <h2 className="pt-2 text-2xl">Account</h2>
            </div>
            <AccountNavigation className="w-full grow bg-gray-50 py-6" />
            <AccountManagerSection />
            {user ? (
              <div className="bg-gray-50 p-7">
                <Button
                  variant="primaryText"
                  onClick={() => {
                    logout();
                    setIsDrawerOpen(false);
                  }}
                >
                  <span className="font-medium text-gray-900">Sign out</span>
                </Button>
              </div>
            ) : (
              <div className="grid grid-cols-2 divide-x bg-gray-50 py-7">
                <Link
                  href={loginUrl}
                  className="py-0.5 text-center font-medium"
                >
                  Login
                </Link>
                <Link
                  href={{
                    pathname: '/register/',
                  }}
                  className="py-0.5 text-center text-gray-500"
                >
                  Register
                </Link>
              </div>
            )}
          </Drawer.Content>
        </Drawer>
      </div>

      {/* Desktop we show the full dropdown menu */}
      <DropdownMenu
        as="div"
        className={clsx('relative hidden sm:block', className)}
        alignment="right"
      >
        {({ open }) => (
          <>
            <DropdownMenu.Button
              className="flex items-center rounded-full pl-3 hover:bg-gray-100 active:shadow-[inset_0_4px_4px_rgba(0,0,0,0.25)]"
              onClick={() => !open && trackAccountMenuOpened()}
            >
              <MastheadText />
            </DropdownMenu.Button>
            <DropdownMenu.Items>
              <>
                {user ? (
                  <DropdownMenu.Section>
                    <div className="px-3 py-2">
                      <div className="q-text-body-xs text-gray-500">
                        Signed in as
                      </div>{' '}
                      <div className="q-text-body-sm truncate">
                        {user?.email}
                      </div>
                    </div>
                  </DropdownMenu.Section>
                ) : (
                  <DropdownMenu.Section>
                    <div className="flex flex-row">
                      <DropdownMenu.Item className="mr-1 flex w-full justify-center text-center text-base font-medium">
                        <MenuLink href={loginUrl}>Login</MenuLink>
                      </DropdownMenu.Item>
                      <div className="my-2 w-[1px] bg-gray-400" />
                      <DropdownMenu.Item className="ml-1 flex w-full justify-center text-center text-base text-gray-500">
                        <MenuLink
                          href={{
                            pathname: '/register/',
                          }}
                        >
                          Register
                        </MenuLink>
                      </DropdownMenu.Item>
                    </div>
                  </DropdownMenu.Section>
                )}
              </>

              <DropdownMenu.Section>
                <DropdownMenu.Item>
                  <MenuLink href="/account/profile/">
                    <UserCircleIcon className="h-4.5 w-4.5 mr-3 text-gray-400" />{' '}
                    Profile
                  </MenuLink>
                </DropdownMenu.Item>
                <DropdownMenu.Item>
                  <MenuLink href="/account/addresses/">
                    <MapPinIcon className="h-4.5 w-4.5 mr-3 text-gray-400" />{' '}
                    Addresses
                  </MenuLink>
                </DropdownMenu.Item>
                <DropdownMenu.Item>
                  <MenuLink href="/account/orders/">
                    <ArchiveBoxIcon className="h-4.5 w-4.5 mr-3 text-gray-400" />{' '}
                    Orders
                  </MenuLink>
                </DropdownMenu.Item>
                <DropdownMenu.Item>
                  <MenuLink href="/account/watchlist/">
                    <StarIcon className="h-4.5 w-4.5 mr-3 text-gray-400" />{' '}
                    Watch list
                  </MenuLink>
                </DropdownMenu.Item>
                <DropdownMenu.Item>
                  <MenuLink href={'/account/wallet/cash/'}>
                    <WalletIcon className="h-4.5 w-4.5 mr-3 text-gray-400" />{' '}
                    Wallet
                  </MenuLink>
                </DropdownMenu.Item>
                <DropdownMenu.Item>
                  <MenuLink href="/account/credit/">
                    <ReceiptPercentIcon className="h-4.5 w-4.5 mr-3 text-gray-400" />{' '}
                    Credit
                  </MenuLink>
                </DropdownMenu.Item>
                <DropdownMenu.Item>
                  <MenuLink href="/account/promotions/promocode/">
                    <GiftIcon className="h-4.5 w-4.5 mr-3 text-gray-400" />{' '}
                    Promotions
                  </MenuLink>
                </DropdownMenu.Item>
                <DropdownMenu.Item>
                  <MenuLink href="/account/catalog/">
                    <ArrowDownCircleIcon className="h-4.5 w-4.5 mr-3 text-gray-400" />{' '}
                    Catalog
                  </MenuLink>
                </DropdownMenu.Item>
              </DropdownMenu.Section>
              <AccountManagerSection />
              {user ? (
                <DropdownMenu.Section>
                  <DropdownMenu.Item
                    className="w-full px-2 py-1 text-left text-base font-medium"
                    onClick={() => logout()}
                  >
                    <button>Sign Out</button>
                  </DropdownMenu.Item>
                </DropdownMenu.Section>
              ) : null}
            </DropdownMenu.Items>
          </>
        )}
      </DropdownMenu>
    </>
  );
};
