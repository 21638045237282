import axios from 'axios';
import { useCallback } from 'react';

type FetchFunction = typeof fetch;

export function useAuthenticatedFetch() {
  // TODO: transform response to match fetch response
  return useCallback<FetchFunction>(
    async (input, { method, body, headers } = {}) => {
      const requestHeaders = { ...headers };
      return axios({
        method: method || 'GET',
        url: input.toString(),
        data: body,
        headers: requestHeaders,
        // This determines whether axios will throw an error for non-2xx responses
        // Always returning true means it won't throw and instead mimics fetch
        // by returning the status which our consumers expect
        validateStatus: () => true,
      }).then((response) => ({
        ...response,
        // NOTE: transforming the response to more closely match the
        // native fetch api
        ok: response.status >= 200 && response.status < 300,
        // TODO: how does this interact with non-json responses?
        json: () => Promise.resolve(response.data),
        text: () => Promise.resolve(response.data),
        redirected: response.status >= 300 && response.status < 400,
        type: 'basic',
        url: response.config.url || '',
        // TODO: can we populate headers from axios response.headers?
        headers: new Headers(
          response.headers ? { ...response.headers } : undefined,
        ),
        // These are all methods that are supported by fetch but not axios
        // We probably don't need these, if we do we should consider it a forcing
        // function to replacing our auth provider
        body: response.data,
        bodyUsed: false,
        clone() {
          throw new Error('Cannot clone an axios request');
        },
        formData() {
          throw new Error('Cannot get form data from an axios request');
        },
        arrayBuffer() {
          throw new Error('Cannot get array buffer from an axios request');
        },
        blob() {
          throw new Error('Cannot get blob from an axios request');
        },
      }));
    },
    [],
  );
}
