/**
 * A function that mimics the slugify function in canary
 * - normalizes a string and replaces all accented characters with standard equivalent
 * - mirrors python slugify in encoding and then decoding the string to cover non-standard characters (this will most likely never be used)
 * - strips '-','_' and space at beginning and end of string
 * - removes non-alphanumeric characters - apostrophes, periods, and commas etc.
 * - replaces double spaces with single spaces
 * - replaces all single spaces with '-'
 * @param value - a string value for the category name
 * @returns a slugified version of the category name, identical to the one outputted by the Python slugify in canary
 */
export const slugify = (value: string) => {
  const normalizedString = value
    .normalize('NFKD')
    .replaceAll(/[\u0300-\u036F]/g, '');
  const encodedString = normalizedString.split('');
  const encodedArray = encodedString.map((letter) => letter.charCodeAt(0));
  const decodedString = String.fromCharCode(...encodedArray);

  return decodedString
    .toLowerCase()
    .trim()
    .replaceAll(/[-_]$/g, '')
    .replaceAll(/^[-_]/g, '')
    .replaceAll(/[^\w\s-]/g, '')
    .replaceAll(/[\s]{2}/g, ' ')
    .replaceAll(' ', '-');
};
