import type { User } from '@qogita/canary-types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useAuthentication } from '#contexts/Authentication';
import { useAuthenticatedFetch } from '#hooks/api/useAuthenticatedFetch';
import { isDisallowedCurrency } from '#lib/currency';
import { environment } from '#lib/environment.mjs';
import { DisallowedUserCurrencyError } from '#lib/error';

import { queryKeys } from './queryKeys';

type GetUser = () => Promise<User>;

const useGetUser = () => {
  const authenticatedFetch = useAuthenticatedFetch();
  const getUser: GetUser = async () => {
    const response = await authenticatedFetch(
      `${environment.NEXT_PUBLIC_API_BASE_URL}/user/`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.ok) {
      const user: User = await response.json();

      if (isDisallowedCurrency(user.currency)) {
        throw new DisallowedUserCurrencyError(
          'The user currency is now allowed.',
          { currency: user.currency },
        );
      }

      return user;
    }

    throw new Error('getUser fetch failed.');
  };
  return getUser;
};

export const useUser = <TData = User>({
  select,
}: Partial<UseQueryOptions<User, unknown, TData>> = {}) => {
  const { status } = useAuthentication();
  const getUser = useGetUser();
  return useQuery({
    ...queryKeys.users.currentUser(status),
    queryFn: () => getUser(),
    enabled: status === 'authenticated',
    select,
    retry: (failureCount, error) => {
      // If a DisallowedUserCurrencyError happens, retrying won't be helpful because the next two retries are expected to fail. So, we'll return false instead.
      if (error instanceof DisallowedUserCurrencyError) {
        return false;
      }

      // The default retry count from React Query is 3. Since React Query does not export this default, we will leave it fixed.
      return failureCount < 3;
    },
  });
};
