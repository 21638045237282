import clsx from 'clsx';

type Props = {
  children: React.ReactNode;
  className?: string;
  id?: string;
};

export const InformationBox = ({
  id,
  children,
  className,
}: Props): JSX.Element => (
  <div
    id={id}
    className={clsx(
      'border-info-600 bg-info-100 text-info-600 rounded border',
      className,
    )}
  >
    {children}
  </div>
);
