import clsx from 'clsx';
import type { ComponentPropsWithoutRef, ElementType, ReactNode } from 'react';

import { NavigationAdornment } from './NavigationAdornment';

export type NavigationListItemProps<C extends ElementType> = {
  as?: C;
  children?: ReactNode;
  isActive: boolean;
} & Omit<ComponentPropsWithoutRef<C>, 'as'>;

/**
 * Internal <li> used by single items and section headings.
 * (Not exposed in public API)
 */
export function NavigationListItem<C extends ElementType>({
  as,
  children,
  isActive,
  className,
  ...componentProps
}: NavigationListItemProps<C>) {
  const Component = as || 'button';
  const type = Component === 'button' ? 'button' : undefined;
  const ariaCurrent = 'href' in componentProps && isActive ? 'page' : undefined;

  return (
    <li className="group relative flex py-1">
      <NavigationAdornment isActive={isActive}>
        <Component
          {...componentProps}
          className={clsx(
            'ml-3 mr-[0.375rem] flex grow flex-row flex-nowrap items-center rounded py-3 pl-3 pr-2 text-left outline-none focus:bg-gray-200 group-hover:bg-gray-200',
            {
              'text-primary-700 bg-gray-100': isActive,
              'text-gray-900': !isActive,
            },
            className,
          )}
          type={type}
          aria-current={ariaCurrent}
        >
          {children}
        </Component>
      </NavigationAdornment>
    </li>
  );
}
