import { Cart } from '@qogita/canary-types';
import { useQuery } from '@tanstack/react-query';

import { useAuthentication } from '#contexts/Authentication';
import { environment } from '#lib/environment.mjs';
import { FetchError } from '#lib/error';

import { queryKeys } from './queryKeys';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

export class CartNotFoundError extends FetchError {
  constructor({ url, responseBody }: { url: string; responseBody: unknown }) {
    super('Cart not found', {
      statusCode: 404,
      responseBody,
      url,
    });
    this.name = 'CartNotFoundError';
  }
}

type Params = {
  qid?: Cart['qid'];
};

const useGetCarts = () => {
  const authenticatedFetch = useAuthenticatedFetch();

  const getCart = async ({ qid }: Params) => {
    const url = `${environment.NEXT_PUBLIC_API_BASE_URL}/carts/${qid}/`;

    const response = await authenticatedFetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    let responseBody: unknown;

    if (response.headers.get('Content-Type')?.includes('application/json')) {
      responseBody = await response.json();
    } else if (response.headers.get('Content-Type')?.includes('text/plain')) {
      responseBody = await response.text();
    }

    if (response.ok) return responseBody as Cart;

    if (response.status === 404) {
      throw new CartNotFoundError({ url, responseBody });
    }

    throw new FetchError(`getCart failed with status code ${response.status}`, {
      statusCode: response.status,
      responseBody,
      url,
    });
  };

  return getCart;
};

export const useCart = ({ qid }: Params) => {
  const { status } = useAuthentication();
  const getCarts = useGetCarts();

  return useQuery({
    ...queryKeys.carts.detail({ qid }),
    queryFn: () => getCarts({ qid }),
    enabled: status === 'authenticated' && Boolean(qid),
  });
};
