import { XMarkIcon } from '@heroicons/react/20/solid';
import { cva, VariantProps } from 'class-variance-authority';

import { BadgeProps, badgeVariants } from '../Badge/Badge';
import { cn } from '../utils';

const removableBadgeVariants = cva('inline-flex gap-1', {
  variants: {
    variant: {
      default: 'focus-visible:ring-gray-700',
      info: 'focus-visible:ring-info-700',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

const removableBadgeIconVariants = cva('ml-1 h-3 w-3 self-center', {
  variants: {
    variant: {
      default: 'text-gray-700',
      info: 'text-info-700',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

type RemovableBadgeProps = VariantProps<typeof removableBadgeVariants> & {
  onRemove: () => void;
  children: string;
  className?: string;
  size?: BadgeProps['size'];
  shape?: BadgeProps['shape'];
};
export function RemovableBadge({
  onRemove,
  children,
  className,
  size,
  variant,
  shape,
}: RemovableBadgeProps) {
  return (
    <button
      className={cn(
        badgeVariants({ size, variant, shape }),
        removableBadgeVariants({ variant }),
        'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-1',
        className,
      )}
      onClick={() => onRemove()}
      aria-label={`remove ${children}`}
      type="button"
    >
      {children}
      <XMarkIcon className={removableBadgeIconVariants({ variant })} />
    </button>
  );
}
