import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

type LabelProps = Parameters<typeof Listbox.Label>[0];
function Label({ className, ...props }: LabelProps) {
  return (
    <Listbox.Label
      {...props}
      className={clsx('mb-1 block text-xs', className)}
    />
  );
}

type ButtonProps = Parameters<typeof Listbox.Button>[0] & {
  placeholder?: string;
  error?: boolean;
};
function Button({
  children,
  placeholder,
  className,
  error,
  ...props
}: ButtonProps) {
  return (
    <Listbox.Button
      {...props}
      as="button"
      className={({ value }) =>
        clsx(
          'flex h-10 w-full items-center justify-between gap-2 rounded border border-gray-300 bg-white py-2 pl-3 pr-2',
          ' focus:border-primary-700 focus:ring-primary-700 focus:ring-1',
          'disabled:cursor-not-allowed disabled:border-gray-200 disabled:text-gray-400',
          'active:enabled:text-gray-900',
          {
            'text-gray-500': !value,
            'text-gray-900': value,
            'active:enabled:border-primary-700': !error,
            'border-error-700': error,
          },
          className,
        )
      }
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore TODO: fix type error */}
      <span className="truncate">{children ?? placeholder}</span>
      <ChevronUpDownIcon className="h-5 w-5 flex-none fill-gray-400" />
    </Listbox.Button>
  );
}

type OptionsProps = Parameters<typeof Listbox.Options>[0];
function Options({ className, ...props }: OptionsProps) {
  return (
    <Listbox.Options
      {...props}
      as="ul"
      className={clsx(
        'absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded border border-gray-300 bg-white py-1 text-sm text-gray-900 shadow-lg focus:outline-none',
        className,
      )}
    />
  );
}

type OptionProps = Parameters<typeof Listbox.Option>[0];
function Option({ className, children, ...props }: OptionProps) {
  return (
    <Listbox.Option
      {...props}
      as="li"
      className={({ active }) =>
        clsx(
          'flex select-none items-center gap-2 px-3 py-2',
          {
            'bg-primary-700 text-white': active,
            'cursor-not-allowed': props.disabled,
          },
          className,
        )
      }
    >
      {({ selected, active }) => (
        <>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore TODO: fix the types */}
          <span className="w-full truncate">{children}</span>
          {selected ? (
            <CheckIcon
              className={clsx('h-4.5 w-4.5 flex-none', {
                'fill-white': active,
                'fill-primary-700': !active,
              })}
            />
          ) : null}
        </>
      )}
    </Listbox.Option>
  );
}

type SelectProps = Parameters<typeof Listbox>[0];
/**
 * SelectRoot is rendered as `div` by default so we can apply classes and event handlers out of the box.
 */
function SelectRoot({ as = 'div', className, ...props }: SelectProps) {
  return <Listbox {...props} as={as} className={clsx('relative', className)} />;
}

export const DeprecatedSelect = Object.assign(SelectRoot, {
  Label,
  Button,
  Options,
  Option,
});
