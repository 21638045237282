import { PatchedUserRequest, User } from '@qogita/canary-types';
import { logError } from '@qogita/logging';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { useAuthentication } from '#contexts/Authentication';
import { usePatchUpdateUser } from '#hooks/api/usePatchUpdateUser';

type LocalisationPreferences = {
  country: User['country'];
  currency: User['currency'];
};

type Variables = {
  data: PatchedUserRequest;
};

type ProfileLocalisation = (
  updateProfile: UseMutateAsyncFunction<
    User,
    TypeError | Error,
    Variables,
    unknown
  >,
  data: LocalisationPreferences,
) => Promise<void>;

const STORAGE_KEY = 'localisation';

const updateProfileLocalisation: ProfileLocalisation = async (
  updateProfile,
  data,
) => {
  const { country, currency } = data;
  try {
    await updateProfile({
      data: {
        country,
        currency,
      },
    });
  } catch (error) {
    logError(error);
  }
};

const setLocalisationPreference = async ({
  country,
  currency,
}: LocalisationPreferences) => {
  return window.localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({ country, currency }),
  );
};

export const useUpdateLocalisationPreferences = () => {
  const queryClient = useQueryClient();

  const { status } = useAuthentication();

  const {
    mutateAsync: updateProfile,
    isPending: isUpdatingProfile,
    error: updateProfileError,
  } = usePatchUpdateUser();

  const { mutateAsync: setLocalisation } = useMutation<
    void,
    TypeError | Error,
    LocalisationPreferences
  >({
    mutationFn: (data) => {
      if (status === 'authenticated') {
        updateProfileLocalisation(updateProfile, data);
      }
      return setLocalisationPreference(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  return {
    setLocalisation,
    isUpdatingProfile,
    updateProfileError,
  };
};
