import { Bars2Icon } from '@heroicons/react/24/outline';
import { QogitaLogoWordmark } from '@qogita/ui';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { MastheadAccount } from '#components/Masthead/Account';

import { CategoriesBanner } from './CategoriesBanner';
import { InfoBanner } from './InfoBanner';
import { MastheadLocalisation } from './Localisation';
import { MastheadCart } from './MastheadCart';
import { ProductsSearch } from './ProductsSearch';
import { ShippingBanner } from './ShippingBanner';

const navPagesBlocklist = new Set([
  '/checkout/[qid]/address',
  '/checkout/[qid]/payment',
  '/checkout/[qid]/review',
]);

export const Masthead = (): JSX.Element => {
  const { pathname } = useRouter();

  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavClose = () => {
    setIsNavOpen(false);
  };

  const isCategoriesBannerVisible = !navPagesBlocklist.has(pathname);

  return (
    <>
      <ShippingBanner />
      <header className="flex w-full justify-center border-b border-b-[#000]">
        <div className="max-w-site-content flex w-full flex-wrap items-stretch justify-between gap-3 px-4 py-4 sm:flex-nowrap md:px-6">
          {isCategoriesBannerVisible ? (
            <div className="flex flex-1 sm:hidden">
              <button
                onClick={() => setIsNavOpen(true)}
                className="h-full pr-4"
              >
                <span className="sr-only">Menu</span>
                <Bars2Icon className="w-6" />
              </button>
            </div>
          ) : null}
          <Link href="/" className="flex w-[9rem] justify-center sm:w-auto">
            <QogitaLogoWordmark theme="black" className="h-[2.5rem] w-[9rem]" />
          </Link>
          <div className="order-last mt-4 w-full flex-grow sm:order-none sm:mx-6 sm:mt-0">
            <ProductsSearch />
          </div>
          <div
            className={clsx(
              'flex flex-1 items-center justify-end gap-3 sm:w-auto',
            )}
          >
            <MastheadLocalisation />
            <MastheadAccount />
            <MastheadCart />
          </div>
        </div>
      </header>
      {isCategoriesBannerVisible ? (
        <CategoriesBanner isOpen={isNavOpen} onClose={handleNavClose} />
      ) : null}
      <InfoBanner />
    </>
  );
};
