import { logError } from '@qogita/logging';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
} from 'react';

import { getAccessExpiry } from '#lib/authentication/authedFetchInterceptor';
import { reset as resetAnalytics } from '#lib/report/AnalyticsProvider';
import { trackUserSignedOut } from '#lib/report/tracking';
import type { Access } from '#types/index';

// TODO: update other types to use this AuthenticationStatus type with Extract
export type AuthenticationStatus =
  | 'loading'
  | 'authenticated'
  | 'unauthenticated';

type SignatureAccess = {
  signature: Access['signature'];
};

type AuthenticationState =
  | {
      status: 'loading';
      access: null;
    }
  | { status: 'authenticated'; access: SignatureAccess }
  | { status: 'unauthenticated'; access: null };

type AuthentictionAction =
  | { type: 'login'; payload: SignatureAccess }
  | { type: 'authenticate'; payload: SignatureAccess }
  | { type: 'unauthenticate' };

const authenticationReducer = (
  state: AuthenticationState,
  action: AuthentictionAction,
): AuthenticationState => {
  switch (action.type) {
    case 'login':
      return {
        status: 'authenticated',
        access: action.payload,
      };
    case 'authenticate':
      return {
        status: 'authenticated',
        access: action.payload,
      };
    case 'unauthenticate':
      return { status: 'unauthenticated', access: null };
  }
};

type AuthenticationContext = AuthenticationState & {
  dispatch: Dispatch<AuthentictionAction>;
  logout: () => void;
};

const AuthenticationContext = createContext<AuthenticationContext | undefined>(
  undefined,
);

const AuthenticationProvider = (
  props: PropsWithChildren<{ [key: string]: unknown }>,
): JSX.Element => {
  const [authState, dispatch] = useReducer(authenticationReducer, {
    status: 'loading',
    access: null,
  });

  const queryClient = useQueryClient();
  const { push, pathname } = useRouter();
  const privateRoutes = ['/account', '/cart', '/checkout'];
  const isPrivatePage = privateRoutes.some((route) =>
    pathname.startsWith(route),
  );

  useEffect(() => {
    // If we don't have an access expiry cookie then we shouldn't have a
    // refresh token cookie so we can't possibly refresh the session and
    // can just unauthenticate without a request
    const accessExpiry = getAccessExpiry();

    if (!accessExpiry) {
      dispatch({ type: 'unauthenticate' });
      return;
    }

    // TODO: work out a better mechanism of preventing double fetching
    // in dev mode
    fetch(`/api/auth/refresh/`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-qogita-trace': 'initial-page-load',
      },
    })
      .then(async (response) => {
        if (response.ok) {
          const { signature } = await response.json();
          dispatch({
            type: 'authenticate',
            payload: { signature },
          });
        } else {
          dispatch({ type: 'unauthenticate' });
        }
      })
      .catch((error) => {
        dispatch({ type: 'unauthenticate' });
        logError(error);
      });
  }, []);

  const logout = async () => {
    try {
      await fetch(`/api/auth/logout/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      trackUserSignedOut();
      resetAnalytics();
    } catch (error) {
      logError(error);
    }

    if (isPrivatePage) {
      await push('/');
    }

    dispatch({ type: 'unauthenticate' });
    queryClient.removeQueries();
  };

  const value = { ...authState, logout, dispatch };
  return <AuthenticationContext.Provider value={value} {...props} />;
};

const useAuthentication = (): AuthenticationContext => {
  const context = useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error(
      'useAuthentication must be used within an AuthenticationProvider.',
    );
  }
  return context;
};

export { AuthenticationProvider, useAuthentication };
