import { Anchor, Button } from '@qogita/ui';
import { useEffect, useState } from 'react';
import { z } from 'zod';

import { useUser } from '#hooks/api/useUser';
import { getCookie, removeBrowserCookie, setBrowserCookie } from '#lib/cookie';

import { Modal } from './Modal';

const cookieKey = 'q_buyer_cannot_checkout_alert_state';

const cookieSchema = z.literal('dismissed');

export function BuyerCannotCheckoutAlert() {
  const { data: user } = useUser();
  const [alertState, setAlertState] = useState<
    'unknown' | 'dismissed' | 'not-dismissed'
  >('unknown');

  useEffect(() => {
    // Load initial state from cookie
    const cookie = getCookie(document.cookie, cookieKey);
    const parsedCookie = cookieSchema.safeParse(cookie);
    if (parsedCookie.success) {
      setAlertState(parsedCookie.data);
    } else {
      setAlertState('not-dismissed');
    }
  }, []);

  useEffect(() => {
    // If user is no longer blocked, remove the cookie so we can show alert again
    // if user is ever re-blocked
    if (user?.canBuyerCheckout) {
      removeBrowserCookie(cookieKey);
    }
  }, [user]);

  const handleDismissAlert = () => {
    setAlertState('dismissed');
    setBrowserCookie(cookieKey, 'dismissed', { path: '/' });
  };

  if (!user) return null;

  const isAlertVisible =
    alertState === 'not-dismissed' && !user.canBuyerCheckout;
  return (
    <Modal open={isAlertVisible} onClose={handleDismissAlert}>
      <Modal.Header>Unable to place orders</Modal.Header>
      <Modal.Body>
        <h3 className="mb-2 font-bold">
          Your VAT number is missing or invalid
        </h3>
        <p className="text-gray-700">
          If you wish to checkout, please provide a valid VAT number or contact
          our support team. Otherwise, you are unable to place an order at this
          time
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Actions>
          <Button onClick={handleDismissAlert} variant="primaryOutlined">
            Close
          </Button>
          <Anchor
            onClick={handleDismissAlert}
            href="mailto:support@qogita.com"
            variant="primaryContained"
          >
            Contact support
          </Anchor>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
}
