import clsx from 'clsx';

import { useIsCollapsedContext } from './context';

type SectionItemsProps = React.HTMLProps<HTMLUListElement>;

export function NavigationSectionItems({
  className,
  ...rest
}: SectionItemsProps) {
  const isCollapsed = useIsCollapsedContext();

  return (
    <ul
      className={clsx(
        'flex flex-col',
        {
          hidden: isCollapsed,
        },
        className,
      )}
      {...rest}
    />
  );
}
