import clsx from 'clsx';
import { forwardRef, type HTMLAttributes, type ReactNode } from 'react';

import { FormTooltip } from '../Tooltip';

const formLabelClasses = {
  base: 'block text-gray-900',
  optionalLabel: 'flex',
  label: 'mb-1',
  legend: 'mb-3 pr-2',
};

type LabelAttribute = HTMLAttributes<HTMLLabelElement>;
export type FormLabelProps = LabelAttribute & {
  children: ReactNode;
  htmlFor: string;
  required: boolean;
  tooltip?: string | ReactNode;
};
/**
 * @deprecated
 */
export const DeprecatedFormLabel = forwardRef<HTMLLabelElement, FormLabelProps>(
  function DeprecatedFormLabelWithoutRef(forwardProps, ref) {
    const { className, children, required, tooltip, ...props } = forwardProps;

    const isOptional = !required;
    return (
      <label
        {...props}
        ref={ref}
        className={clsx(
          'q-text-body-xs flex items-center',
          className,
          formLabelClasses.base,
          formLabelClasses.label,
        )}
      >
        {children}
        {tooltip ? <FormTooltip className="ml-1">{tooltip}</FormTooltip> : null}
        {isOptional ? (
          <span className="ml-auto text-gray-500">Optional</span>
        ) : null}
      </label>
    );
  },
);

type LegendAttribute = HTMLAttributes<HTMLLegendElement>;
export type DeprecatedFormLegendProps = LegendAttribute & {
  children: ReactNode;
  tooltip?: string | ReactNode;
};

export const DeprecatedFormLegend = forwardRef<
  HTMLLegendElement,
  DeprecatedFormLegendProps
>(function DeprecatedFormLegendWithoutRef(forwardProps, ref) {
  const { className, tooltip, children, ...props } = forwardProps;

  return (
    <legend
      {...props}
      ref={ref}
      className={clsx(
        'q-text-body-base-bold',
        className,
        formLabelClasses.base,
        formLabelClasses.legend,
      )}
    >
      {children}
      {tooltip ? <FormTooltip>{tooltip}</FormTooltip> : null}
    </legend>
  );
});
