import { User } from '@qogita/canary-types';
import {
  clearUser as clearLoggerUser,
  identifyUser,
  logError,
} from '@qogita/logging';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { useRouter } from 'next/router';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { z } from 'zod';

import { useConsent, useIsConsentBannerDisabled } from '#contexts/Consent';
import { ConsentCategory } from '#lib/consent';
import { environment } from '#lib/environment.mjs';
import { FetchError } from '#lib/error';

import { getIsBotUser } from './bots';
import { useIsManuallyLoadGtagEnabled } from './Gtag';

// ts-unused-exports:disable-next-line
export const analytics = new AnalyticsBrowser();

async function page(properties?: Record<string, unknown>) {
  const isBot = await getIsBotUser();
  if (isBot && environment.NEXT_PUBLIC_ENABLE_BOT_DETECTION) {
    return;
  }
  try {
    analytics.page(undefined, undefined, properties);
  } catch (error) {
    logError(error);
  }
}

export async function track(
  event: string,
  properties?: Record<string, unknown>,
) {
  const isBot = await getIsBotUser();
  if (isBot && environment.NEXT_PUBLIC_ENABLE_BOT_DETECTION) {
    return;
  }
  try {
    analytics.track(event, { ...properties });
  } catch (error) {
    logError(error);
  }
}

async function identify(user: User) {
  const isBot = await getIsBotUser();
  if (isBot && environment.NEXT_PUBLIC_ENABLE_BOT_DETECTION) {
    return;
  }
  try {
    identifyUser(user);

    analytics.identify(user.qid, {
      email: user.email,
      name: user.account,
      //@ts-expect-error - TODO: we should probably change this to an ISO string
      createdAt: user.createdAt,
      //@ts-expect-error - TODO: this doesn't match what segment expects a company to be
      company: user.company,
      isKeyAccount: Boolean(user.accountManager),
    });
  } catch (error) {
    logError(error);
  }
}

export function reset() {
  try {
    analytics.reset();
    clearLoggerUser();
  } catch (error) {
    logError(error);
  }
}

/**
 * Selected functions from segment analytics, augmented with things like bot detection
 * and GA session data
 */
const customAnalytics = {
  page,
  identify,
  reset,
  track,
};

const AnalyticsContext = createContext<typeof customAnalytics | undefined>(
  undefined,
);

export function useAnalytics() {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider');
  }
  return context;
}

function usePageTracking() {
  const { events, asPath, pathname } = useRouter();
  const ref = useRef<string>(asPath);

  const handleRouteChange = useCallback(() => {
    page({ previousUrl: ref.current });
    ref.current = asPath;
  }, [asPath]);

  /**
   * this was added as a temporary solution to trigger initial page view event
   * because on routeChangeComplete doesn't trigger this (because no change to the route...)
   */
  useEffect(() => {
    // This is so that we don't trigger double page tracking events redirecting
    // from /voucher/[code] to the homepage (ChrisG)
    if (pathname === '/voucher/[code]') return;

    handleRouteChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // triggers events for subsequent route changes
  useEffect(() => {
    events.on('routeChangeComplete', handleRouteChange);
    return () => events.off('routeChangeComplete', handleRouteChange);
  }, [events, handleRouteChange]);
}

const segmentCategorySchema = z.enum(['Analytics', 'Advertising', 'CRM']);
type SegmentCategory = z.infer<typeof segmentCategorySchema>;

/**
 * Maps segment's categories to our own consent categories
 */
const segmentCategoryToConsentCategoryMap = {
  Analytics: 'performance',
  Advertising: 'marketing',
  CRM: 'performance',
} satisfies Record<SegmentCategory, ConsentCategory>;

async function fetchSegmentDestinations(writeKey: string) {
  const response = await fetch(
    `https://analytics.qogita.com/v1/projects/${writeKey}/integrations`,
  );

  const responseBody = response.headers
    .get('content-type')
    ?.includes('application/json')
    ? await response.json()
    : await response.text();

  if (!response.ok) {
    throw new FetchError(`Failed to fetch segment integrations`, {
      responseBody,
      statusCode: response.status,
      url: response.url,
    });
  }

  return z
    .array(
      z.object({
        creationName: z.string(),
        category: segmentCategorySchema.transform(
          (value) => segmentCategoryToConsentCategoryMap[value],
        ),
      }),
    )
    .parse(responseBody);
}

function analyticsLoad({
  writeKey,
  externalIntegrations = {},
}: {
  writeKey: string;
  externalIntegrations?: Record<string, boolean>;
}) {
  return analytics.load(
    {
      writeKey,
      cdnURL: 'https://analytics.qogita.com',
    },
    {
      integrations: {
        All: false,
        'Segment.io': {
          apiHost: 'analyticsapi.qogita.com/v1',
        },
        ...externalIntegrations,
      },
    },
  );
}

export function AnalyticsProvider({
  children,
  writeKey,
}: {
  children: ReactNode;
  writeKey: string;
}) {
  const isConsentBannerDisabled = useIsConsentBannerDisabled();
  const isManuallyLoadGtagEnabled = useIsManuallyLoadGtagEnabled();
  const { consent } = useConsent();
  const isSegmentLoadedRef = useRef(false);

  useEffect(() => {
    async function loadSegmentWithEverythingConsented() {
      // We only load segment without checking consent if the feature flag hasn't been turned on yet
      // We can delete this effect when the flag is removed
      if (!isConsentBannerDisabled) return;
      // Don't load segment if we've already loaded it
      // If the user changes their preferences mid session we'll hard refresh
      if (isSegmentLoadedRef.current) return;

      try {
        const destinations = await fetchSegmentDestinations(writeKey);
        const externalIntegrations: Record<string, boolean> = {};
        destinations.forEach((destination) => {
          let isEnabled = true;
          if (
            isManuallyLoadGtagEnabled && // if we're manually loading gtag we don't want to also enable it from segment
            (destination.creationName === 'Google Analytics 4 Web' ||
              destination.creationName === 'Google AdWords New')
          ) {
            isEnabled = false;
          }

          externalIntegrations[destination.creationName] = isEnabled;
        });

        analyticsLoad({ writeKey, externalIntegrations });
      } catch (error) {
        logError(error);
        // If we fail to fetch the integrations, we'll just load segment without them
        analyticsLoad({ writeKey });
      } finally {
        isSegmentLoadedRef.current = true;
      }
    }
    loadSegmentWithEverythingConsented();
  }, [isConsentBannerDisabled, isManuallyLoadGtagEnabled, writeKey]);

  useEffect(() => {
    async function loadSegment() {
      // Don't load segment if the consent banner feature flag isn't on yet
      if (isConsentBannerDisabled) return;
      // Don't load segment if we don't know the user's preferences yet
      if (consent.status !== 'ready') return;
      // Don't load segment at all if the user has opted out of analytics
      if (consent.value.performance === false) return;
      // Don't load segment if we've already loaded it
      // If the user changes their preferences mid session we'll hard refresh
      if (isSegmentLoadedRef.current) return;

      try {
        const destinations = await fetchSegmentDestinations(writeKey);
        const externalIntegrations: Record<string, boolean> = {};
        destinations.forEach((destination) => {
          let isEnabled = consent.value[destination.category];

          if (
            isManuallyLoadGtagEnabled && // if we're manually loading gtag we don't want to also enable it from segment
            (destination.creationName === 'Google Analytics 4 Web' ||
              destination.creationName === 'Google AdWords New')
          ) {
            isEnabled = false;
          }

          externalIntegrations[destination.creationName] = isEnabled;
        });

        analyticsLoad({ writeKey, externalIntegrations });
      } catch (error) {
        logError(error);
        // If we fail to fetch the integrations, we'll just load segment without them
        analyticsLoad({ writeKey });
      } finally {
        isSegmentLoadedRef.current = true;
      }
    }

    loadSegment();
    // consent is an object so adding to dependency array is dangerous
    // This is why we have a lot of guarded early returns in loadSegment
  }, [consent, isConsentBannerDisabled, isManuallyLoadGtagEnabled, writeKey]);

  usePageTracking();

  return (
    <AnalyticsContext.Provider value={customAnalytics}>
      {children}
    </AnalyticsContext.Provider>
  );
}
