import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { environment } from '#lib/environment.mjs';
import { meiliClient } from '#lib/search';

import { queryKeys } from './api/queryKeys';

export type SearchSuggestionHit = {
  objectID: string;
  query?: string;
};

type Params = {
  query: string;
};

type GetSearchSuggestions = (params: Params) => Promise<SearchSuggestionHit[]>;

const SEARCH_SUGGESTIONS_HITS_PER_PAGE = 10;
const MEILISEARCH_SUGGESTIONS_INDEX_NAME =
  environment.NEXT_PUBLIC_MEILISEARCH_SUGGESTIONS_INDEX_NAME || '';

const index = meiliClient.index(MEILISEARCH_SUGGESTIONS_INDEX_NAME);

const getSearchSuggestions: GetSearchSuggestions = async ({ query }) => {
  const response = await index.search<SearchSuggestionHit>(query, {
    hitsPerPage: SEARCH_SUGGESTIONS_HITS_PER_PAGE,
  });
  return response.hits;
};

export const useSearchSuggestions = ({ query }: Params) => {
  return useQuery({
    ...queryKeys.searchSuggestions.search({ query }),
    queryFn: () => getSearchSuggestions({ query }),
    enabled: Boolean(query),
    placeholderData: keepPreviousData,
  });
};
