import { User } from '@qogita/canary-types';
import {
  getCurrencyFromCountryCode,
  isValidCountryCode,
} from '@qogita/countries';
import { useQuery } from '@tanstack/react-query';

import { useUser } from '#hooks/api/useUser';
import { getCookie } from '#lib/cookie';

import { queryKeys } from './queryKeys';

export type LocalisationPreferences = {
  country: User['country'];
  currency: User['currency'];
};

const STORAGE_KEY = 'localisation';

const retrieveLocalisationPreference =
  async (): Promise<LocalisationPreferences> => {
    const localeCode = getCookie(document.cookie, STORAGE_KEY) ?? 'NL';

    const localeCountryCode = isValidCountryCode(localeCode)
      ? localeCode
      : 'NL';
    const localeCurrency = getCurrencyFromCountryCode(localeCountryCode);
    const localStorageResult =
      window.localStorage.getItem(STORAGE_KEY) ??
      `{"country": "${localeCountryCode}", "currency": "${localeCurrency}"}`;
    return localStorageResult ? JSON.parse(localStorageResult) : null;
  };

export const useLocalisationPreferences = () => {
  const { data: user } = useUser();
  const { data = { country: 'NL', currency: 'EUR' } } = useQuery({
    ...queryKeys.localisation.preferences,
    queryFn: retrieveLocalisationPreference,
  });

  const localisedCountry = data.country;
  const localisedCurrency = data.currency;
  const country: User['country'] = user?.country ?? localisedCountry;
  const currency: User['currency'] = user?.currency ?? localisedCurrency;

  return {
    country,
    currency,
  };
};
