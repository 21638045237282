import type { Statistics } from '@qogita/canary-types';

/**
 * This should be removed when BE supplies region data through GET /info/
 */
// prettier-ignore
export const REGION_MAP = {
  AFRICA: ['AO', 'BF', 'BI', 'BJ', 'BV', 'BW', 'CD', 'CF', 'CG', 'CI', 'CM', 'CV', 'DJ', 'DZ', 'EH', 'ER', 'ET', 'GA', 'GH', 'GM', 'GN', 'GQ', 'GW', 'HM', 'KE', 'KM', 'LR', 'LS', 'LY', 'MA', 'MG', 'ML', 'MR', 'MU', 'MW', 'MZ', 'NA', 'NE', 'NG', 'RE', 'RW', 'SC', 'SD', 'SH', 'SL', 'SN', 'SO', 'SS', 'ST', 'SZ', 'TD', 'TF', 'TG', 'TN', 'TZ', 'UG', 'YT', 'ZA', 'ZM', 'ZW'],
  ASIA: ['AF', 'AM', 'AZ', 'BD', 'BN', 'BT', 'CC', 'CN', 'CX', 'GE', 'HK', 'ID', 'IN', 'IO', 'JP', 'KG', 'KH', 'KP', 'KR', 'KZ', 'LA', 'LK', 'MM', 'MN', 'MO', 'MV', 'MY', 'NP', 'PH', 'PK', 'SG', 'TH', 'TJ', 'TL', 'TM', 'TW', 'UZ', 'VN'],
  EUROPE: ['AD', 'AL', 'AX', 'BA', 'BY', 'FO', 'GB', 'GG', 'GI', 'IM', 'JE', 'MC', 'MD', 'ME', 'MK', 'RS', 'RU', 'SJ', 'SM', 'UA', 'VA'],
  EU_SINGLE_MARKET: ['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'GR', 'HR', 'HU', 'IE', 'IT', 'LT', 'LU', 'LV', 'MT', 'NL', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK'],
  MIDDLE_EAST: ['AE', 'BH', 'EG', 'IL', 'IQ', 'IR', 'JO', 'KW', 'LB', 'OM', 'PS', 'QA', 'SA', 'SY', 'TR', 'YE'],
  NORTH_AMERICA: ['AG', 'AI', 'AW', 'BB', 'BL', 'BM', 'BQ', 'BS', 'BZ', 'CA', 'CR', 'CU', 'CW', 'DM', 'DO', 'GD', 'GL', 'GP', 'GT', 'HN', 'HT', 'JM', 'KN', 'KY', 'LC', 'MF', 'MQ', 'MS', 'MX', 'NI', 'PA', 'PM', 'PR', 'SV', 'TC', 'TT', 'US', 'VC', 'VG', 'VI'],
  OCEANIA: ['AS', 'AU', 'CK', 'FJ', 'FM', 'GU', 'KI', 'MH', 'MP', 'NC', 'NF', 'NR', 'NU', 'NZ', 'PF', 'PG', 'PN', 'PW', 'SB', 'TK', 'TO', 'TV', 'UM', 'VU', 'WF', 'WS'],
  SOUTH_AMERICA: ['AR', 'BO', 'BR', 'CL', 'CO', 'EC', 'FK', 'GF', 'GS', 'GY', 'PE', 'PY', 'SR', 'SX', 'UY', 'VE'],
};

export const EU_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
] as const;

export const MIDDLE_EAST_COUNTRIES = ['IL', 'AE'] as const;

export const ALL_BUYING_COUNTRIES = [
  ...EU_COUNTRIES,
  ...MIDDLE_EAST_COUNTRIES,
  'GB',
  'US',
] as const;

export const INFO_DATA_SNAPSHOT: Statistics = {
  variantCount: 1436469,
  brandCount: 91698,
  categoryCount: 4733,
  inventoryCount: 366933677,
  countriesServed: 40,
  inventoryValue: '5355540803.55',
  inventoryValueCurrency: 'EUR',
  mov: {
    EUR: 1000,
    GBP: 900,
    USD: 1000,
  },
  invoicingOptions: [
    {
      daysDeferred: 0,
      interestRatio: '0.0000',
    },
    {
      daysDeferred: 30,
      interestRatio: '0.0170',
    },
    {
      daysDeferred: 60,
      interestRatio: '0.0237',
    },
    {
      daysDeferred: 90,
      interestRatio: '0.0331',
    },
  ],
  offerCountries: {
    SK: {
      sellers: 4,
      variants: 6031,
    },
    DK: {
      sellers: 4,
      variants: 38168,
    },
    LT: {
      sellers: 3,
      variants: 51719,
    },
    CZ: {
      sellers: 8,
      variants: 49083,
    },
    US: {
      sellers: 57,
      variants: 258862,
    },
    FR: {
      sellers: 6,
      variants: 12264,
    },
    LV: {
      sellers: 1,
      variants: 333,
    },
    AE: {
      sellers: 3,
      variants: 3912,
    },
    RO: {
      sellers: 2,
      variants: 10200,
    },
    NL: {
      sellers: 42,
      variants: 208218,
    },
    AT: {
      sellers: 1,
      variants: 71503,
    },
    HU: {
      sellers: 2,
      variants: 3698,
    },
    IE: {
      sellers: 2,
      variants: 1124,
    },
    PL: {
      sellers: 18,
      variants: 39439,
    },
    PT: {
      sellers: 1,
      variants: 9641,
    },
    ES: {
      sellers: 16,
      variants: 168175,
    },
    IL: {
      sellers: 1,
      variants: 830,
    },
    BG: {
      sellers: 1,
      variants: 302,
    },
    GB: {
      sellers: 102,
      variants: 98795,
    },
    IT: {
      sellers: 23,
      variants: 25758,
    },
    GR: {
      sellers: 2,
      variants: 803,
    },
    BE: {
      sellers: 2,
      variants: 259,
    },
    DE: {
      sellers: 44,
      variants: 673809,
    },
  },
  allowedShippingCountries: [
    'AT',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DE',
    'DK',
    'EE',
    'ES',
    'FI',
    'FR',
    'GR',
    'HR',
    'HU',
    'IE',
    'IT',
    'LT',
    'LU',
    'LV',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SE',
    'SI',
    'SK',
    'GB',
    'US',
    'IL',
    'AE',
  ],
  parcelSizeOptions: [
    {
      name: 'package_xs',
      displayName: 'Package XS',
      length: '0.18',
      width: '0.33',
      height: '0.10',
    },
    {
      name: 'package_s',
      displayName: 'Package S',
      length: '0.32',
      width: '0.33',
      height: '0.10',
    },
    {
      name: 'package_m',
      displayName: 'Package M',
      length: '0.32',
      width: '0.33',
      height: '0.18',
    },
    {
      name: 'package_l',
      displayName: 'Package L',
      length: '0.32',
      width: '0.33',
      height: '0.34',
    },
    {
      name: 'package_xxl',
      displayName: 'Package XXL',
      length: '0.60',
      width: '0.40',
      height: '0.30',
    },
    {
      name: 'package_xl',
      displayName: 'Package XL',
      length: '0.40',
      width: '0.48',
      height: '0.38',
    },
  ],
  regionsCountries: {
    ASIA: [
      'AF',
      'AM',
      'AZ',
      'BD',
      'BN',
      'BT',
      'CC',
      'CN',
      'CX',
      'GE',
      'HK',
      'ID',
      'IN',
      'IO',
      'JP',
      'KG',
      'KH',
      'KP',
      'KR',
      'KZ',
      'LA',
      'LK',
      'MM',
      'MN',
      'MO',
      'MV',
      'MY',
      'NP',
      'PH',
      'PK',
      'SG',
      'TH',
      'TJ',
      'TL',
      'TM',
      'TW',
      'UZ',
      'VN',
    ],
    AFRICA: [
      'AO',
      'BF',
      'BI',
      'BJ',
      'BV',
      'BW',
      'CD',
      'CF',
      'CG',
      'CI',
      'CM',
      'CV',
      'DJ',
      'DZ',
      'EH',
      'ER',
      'ET',
      'GA',
      'GH',
      'GM',
      'GN',
      'GQ',
      'GW',
      'HM',
      'KE',
      'KM',
      'LR',
      'LS',
      'LY',
      'MA',
      'MG',
      'ML',
      'MR',
      'MU',
      'MW',
      'MZ',
      'NA',
      'NE',
      'NG',
      'RE',
      'RW',
      'SC',
      'SD',
      'SH',
      'SL',
      'SN',
      'SO',
      'SS',
      'ST',
      'SZ',
      'TD',
      'TF',
      'TG',
      'TN',
      'TZ',
      'UG',
      'YT',
      'ZA',
      'ZM',
      'ZW',
    ],
    EUROPE: [
      'AD',
      'AL',
      'AX',
      'BA',
      'BY',
      'FO',
      'GB',
      'GG',
      'GI',
      'IM',
      'JE',
      'MC',
      'MD',
      'ME',
      'MK',
      'RS',
      'RU',
      'SJ',
      'SM',
      'UA',
      'VA',
    ],
    OCEANIA: [
      'AS',
      'AU',
      'CK',
      'FJ',
      'FM',
      'GU',
      'KI',
      'MH',
      'MP',
      'NC',
      'NF',
      'NR',
      'NU',
      'NZ',
      'PF',
      'PG',
      'PN',
      'PW',
      'SB',
      'TK',
      'TO',
      'TV',
      'UM',
      'VU',
      'WF',
      'WS',
    ],
    MIDDLE_EAST: [
      'AE',
      'BH',
      'EG',
      'IL',
      'IQ',
      'IR',
      'JO',
      'KW',
      'LB',
      'OM',
      'PS',
      'QA',
      'SA',
      'SY',
      'TR',
      'YE',
    ],
    NORTH_AMERICA: [
      'AG',
      'AI',
      'AW',
      'BB',
      'BL',
      'BM',
      'BQ',
      'BS',
      'BZ',
      'CA',
      'CR',
      'CU',
      'CW',
      'DM',
      'DO',
      'GD',
      'GL',
      'GP',
      'GT',
      'HN',
      'HT',
      'JM',
      'KN',
      'KY',
      'LC',
      'MF',
      'MQ',
      'MS',
      'MX',
      'NI',
      'PA',
      'PM',
      'PR',
      'SV',
      'TC',
      'TT',
      'US',
      'VC',
      'VG',
      'VI',
    ],
    SOUTH_AMERICA: [
      'AR',
      'BO',
      'BR',
      'CL',
      'CO',
      'EC',
      'FK',
      'GF',
      'GS',
      'GY',
      'PE',
      'PY',
      'SR',
      'SX',
      'UY',
      'VE',
    ],
    EU_SINGLE_MARKET: [
      'AT',
      'BE',
      'BG',
      'CH',
      'CY',
      'CZ',
      'DE',
      'DK',
      'EE',
      'ES',
      'FI',
      'FR',
      'GR',
      'HR',
      'HU',
      'IE',
      'IS',
      'IT',
      'LI',
      'LT',
      'LU',
      'LV',
      'MT',
      'NL',
      'NO',
      'PL',
      'PT',
      'RO',
      'SE',
      'SI',
      'SK',
    ],
  },
};

export const DEFAULT_OPEN_GRAPH_IMAGE = {
  url: 'https://images.prod.qogita.com/files/downloads/logo-qogita.png?w=1200&h=630&fit=crop',
  width: '1200',
  height: '630',
};
