import { createQueryKeyStore } from '@lukemorales/query-key-factory';

import { AuthenticationStatus } from '#contexts/Authentication';
import { PaginationOptions } from '#lib/pagination';

import { OptimizerChangedLinesFilter } from './useOptimizerChangedLines';
import { OrdersFilter, OrdersSortOptions } from './useOrders';
import { SearchConfig } from './useSearch';
import { WatchlistParams } from './useWatchlistItems';

export const queryKeys = createQueryKeyStore({
  addresses: {
    list: ({ pagination }: { pagination?: PaginationOptions }) => [
      { pagination },
    ],
    detail: ({ qid }: { qid: string }) => [{ qid }],
  },
  carts: {
    list: ({ pagination }: { pagination?: PaginationOptions }) => [
      { pagination },
    ],
    detail: ({ qid }: { qid?: string }) => ({
      queryKey: [{ qid }],
      contextQueries: {
        lines: ({ pagination }: { pagination?: PaginationOptions }) => [
          { pagination },
        ],
      },
    }),
  },
  brands: {
    list: ({
      slugs,
      size,
      strategic,
    }: {
      slugs: string[] | null;
      size: number | null;
      strategic: boolean | null;
    }) => ({
      queryKey: [{ slugs, size, strategic }],
    }),
  },
  categories: {
    list: ({ slugs }: { slugs: string[] | null }) => ({
      queryKey: [{ slugs }],
    }),
  },
  checkouts: {
    list: ({ pagination }: { pagination?: PaginationOptions }) => [
      { pagination },
    ],
    detail: ({ qid }: { qid: string }) => ({
      queryKey: [{ qid }],
      contextQueries: {
        lines: ({ pagination }: { pagination?: PaginationOptions }) => [
          { pagination },
        ],
        optimizedLines: ({
          pagination,
          filter,
        }: {
          pagination?: PaginationOptions;
          filter?: OptimizerChangedLinesFilter;
        }) => [{ pagination, filter }],
        optimizerRemovedLines: ({
          pagination,
        }: {
          pagination?: PaginationOptions;
        }) => [{ pagination }],
        optimizerChangesSummary: null,
      },
    }),
  },
  orders: {
    list: ({
      pagination,
      filter,
      sort,
    }: {
      pagination?: PaginationOptions;
      filter?: OrdersFilter;
      sort?: OrdersSortOptions;
    }) => [{ pagination, filter, sort }],
    detail: ({ qid }: { qid: string }) => ({
      queryKey: [{ qid }],
      contextQueries: {
        orderLines: ({ pagination }: { pagination?: PaginationOptions }) => [
          { pagination },
        ],
        sales: ({ pagination }: { pagination?: PaginationOptions }) => [
          { pagination },
        ],
        claims: null,
      },
    }),
  },
  claims: {
    detail: ({ qid }: { qid: string }) => [{ qid }],
  },
  wallets: {
    detail: ({ qid }: { qid: string }) => ({
      queryKey: [{ qid }],
      contextQueries: {
        lines: ({
          pagination,
          source,
        }: {
          pagination?: PaginationOptions;
          source?: string;
        }) => [{ pagination, source }],
      },
    }),
  },
  subscribers: {
    detail: ({ sid }: { sid: string }) => [{ sid }],
  },
  products: {
    detail: ({
      fid,
      slug,
      currency,
      status,
    }: {
      fid: string;
      slug: string;
      currency: string | null;
      status: AuthenticationStatus;
    }) => [{ fid, slug, currency, status }],
  },
  localisation: { preferences: null },
  users: {
    currentUser: (status?: string) => ({
      queryKey: [{ status }],
      contextQueries: {
        debtor: null,
        groups: null,
        bank: {
          queryKey: null,
          contextQueries: {
            info: null,
          },
        },
        distributionChannelTypes: null,
      },
    }),
  },
  info: {
    all: null,
  },
  searchSuggestions: {
    search: ({ query }: { query: string }) => [{ query }],
  },
  search: {
    index: ({ searchConfig }: { searchConfig: SearchConfig }) => [
      { searchConfig },
    ],
    detail: ({ gtin }: { gtin: string }) => [{ gtin }],
    catalog: ({ searchConfig }: { searchConfig: SearchConfig }) => [
      { searchConfig },
    ],
    categories: ({
      categoryNames,
      searchConfig,
    }: {
      categoryNames: string[];
      searchConfig: Omit<SearchConfig, 'facets'>;
    }) => [{ categoryNames, searchConfig }],
  },
  watchlist: {
    items: (params: WatchlistParams) => [params],
  },
});
