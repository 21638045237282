import clsx from 'clsx';
import type { ReactNode } from 'react';

export type DeprecatedFieldDescriptionProps = {
  id: string;
  className?: string;
  children: ReactNode;
  fieldset: boolean;
};

/**
 * @deprecated
 */
export function DeprecatedFieldDescription({
  className,
  fieldset,
  ...props
}: DeprecatedFieldDescriptionProps) {
  return (
    <p
      {...props}
      className={clsx(className, 'mb-3 text-gray-700', {
        'mt-2': !fieldset,
      })}
    />
  );
}
