import { HomeIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import {
  Children,
  cloneElement,
  type ComponentProps,
  type FunctionComponentElement,
  isValidElement,
  useState,
} from 'react';

import { Breadcrumb } from './Breadcrumb';
import { BreadcrumbButton } from './BreadcrumbToggle';

type PossibleFalsy<T> = T | boolean | null;

type BreadcrumbElement = FunctionComponentElement<
  ComponentProps<typeof Breadcrumb>
>;

type BreadcrumbsProps = {
  homeLink: string;
  className?: string;
  children:
    | PossibleFalsy<BreadcrumbElement>
    | PossibleFalsy<BreadcrumbElement>[];
  initialMobileNavOpen?: boolean;
  onMobileToggle?: (isOpen: boolean) => void;
};

export function Breadcrumbs({
  className,
  homeLink,
  children,
  initialMobileNavOpen = false,
  onMobileToggle,
}: BreadcrumbsProps) {
  const [isOpen, setOpen] = useState<boolean>(initialMobileNavOpen);

  const totalChildNodes = Children.count(children);
  const lastItemIndex = totalChildNodes - 1;

  const responsiveMobileHiddenClasses = 'hidden sm:list-item';

  const handleToggle = () => {
    setOpen((isOpen) => {
      const newState = !isOpen;

      onMobileToggle?.(newState);

      return newState;
    });
  };

  return (
    <nav
      aria-label="Breadcrumb"
      className={clsx(
        className,
        'flex justify-between pb-4 sm:justify-start sm:border-none sm:pb-0',
        {
          'border-b border-b-gray-900': isOpen,
        },
      )}
    >
      <ol
        role="list"
        className="sm:self flex flex-col self-center sm:flex-row sm:items-center sm:space-x-4"
      >
        <li className={responsiveMobileHiddenClasses}>
          <a href={homeLink} className="text-gray-400 hover:text-gray-500">
            <HomeIcon className="h-5 w-5 flex-shrink-0" />
            <span className="sr-only">Home</span>
          </a>
        </li>

        {Children.map(children, (child, index) => {
          if (typeof child === 'boolean' || !isValidElement(child)) return;

          const isLastBreadcrumb = lastItemIndex === index;
          const hideBreadcrumb = !isOpen && !isLastBreadcrumb;

          return cloneElement(child, {
            ...child.props,
            className: clsx(child.props.className, {
              [responsiveMobileHiddenClasses]: hideBreadcrumb,
            }),
          });
        })}
      </ol>

      <div
        className={clsx('pl-7.5 self-end pr-4 sm:hidden', {
          'border-l border-l-gray-900': isOpen,
        })}
      >
        <BreadcrumbButton isOpen={isOpen} onClick={handleToggle} />
      </div>
    </nav>
  );
}
