import { useFlags } from 'launchdarkly-react-client-sdk';
import Script from 'next/script';
import { useEffect } from 'react';

import { useConsent, useIsConsentBannerEnabled } from '#contexts/Consent';
import { environment } from '#lib/environment.mjs';

const isGtagEnabled = environment.NEXT_PUBLIC_GTAG_ENABLED;

export function useIsManuallyLoadGtagEnabled() {
  const { manuallyLoadGtag } = useFlags();

  return manuallyLoadGtag === true;
}

export function GTagScript() {
  const isManuallyLoadGtagEnabled = useIsManuallyLoadGtagEnabled();
  const isConsentBannerEnabled = useIsConsentBannerEnabled();
  const { consent } = useConsent();

  useEffect(() => {
    if (!isGtagEnabled) return;
    if (!useIsManuallyLoadGtagEnabled) return;
    if (!isConsentBannerEnabled) return;

    if (!window.gtag) return;
    if (consent.status !== 'ready') return;

    window.gtag('consent', 'update', {
      ad_storage: consent.value.marketing ? 'granted' : 'denied',
      analytics_storage: consent.value.performance ? 'granted' : 'denied',
    });
  }, [consent.status, consent, isConsentBannerEnabled]);

  if (isGtagEnabled || !isManuallyLoadGtagEnabled) {
    return null;
  }

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${environment.NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
      />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', ${environment.NEXT_PUBLIC_GA_MEASUREMENT_ID});
          gtag('config', ${environment.NEXT_PUBLIC_GOOGLE_ADS_TAG_ID})
          ${
            isConsentBannerEnabled
              ? `
          gtag('consent', 'default', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied',
            wait_for_update: 500
          })
          `
              : ``
          }
        `}
      </Script>
    </>
  );
}

export function gtagEvent(
  // We need this to be a string literal type, but also allow for custom events
  // eslint-disable-next-line @typescript-eslint/ban-types
  eventName: Gtag.EventNames | (string & {}),
  eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams,
) {
  if (!window.gtag) return;

  window.gtag('event', eventName, eventParams);
}

export function gtagConversion(
  conversionId: string,
  params: Pick<Gtag.EventParams, 'value' | 'currency' | 'transaction_id'> = {},
) {
  if (!window.gtag) return;

  window.gtag('event', 'conversion', {
    send_to: `${environment.NEXT_PUBLIC_GOOGLE_ADS_TAG_ID}/${conversionId}`,
    ...params,
  });
}
