type NavigationInsetMarginProps = {
  insetLevel: number;
};

export function NavigationInsetMargin({
  insetLevel,
}: NavigationInsetMarginProps) {
  return (
    <>
      {Array.from({ length: insetLevel }, (_, index) => (
        <span key={index} className="w-[1.875rem]" />
      ))}
    </>
  );
}
