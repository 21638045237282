import { initDatadog } from '@qogita/logging';
import { useEffect } from 'react';

import { useConsent, useIsConsentBannerDisabled } from '#contexts/Consent';
import { environment } from '#lib/environment.mjs';

const isDatadogEnabled = environment.NEXT_PUBLIC_DATADOG_RUM_ENABLED;

export function DatadogInit() {
  const { consent } = useConsent();
  const isConsentBannerDisabled = useIsConsentBannerDisabled();
  const isDatadogAccepted =
    consent.status === 'ready' && consent.value.performance;

  useEffect(() => {
    if (isConsentBannerDisabled && isDatadogEnabled) {
      // If the flag isn't switched on, initialize straight away
      initDatadog();
    }
    if (isDatadogAccepted && isDatadogEnabled) {
      initDatadog();
    }
  }, [isConsentBannerDisabled, isDatadogAccepted]);

  return null;
}
