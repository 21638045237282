import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

type BreadcrumbButtonProps = React.ComponentProps<'button'> & {
  isOpen: boolean;
  children?: never;
};

/**
 * @description We have no concept of an IconButton, so this is an interim solution
 */
export function BreadcrumbButton({
  isOpen,
  className,
  ...props
}: BreadcrumbButtonProps) {
  const Icon = isOpen ? MinusCircleIcon : PlusCircleIcon;

  return (
    <button
      {...props}
      type="button"
      className={clsx(
        className,
        'w-7.5 text-primary-900 focus-visible:bg-primary-50 flex items-center justify-center bg-white font-medium',
      )}
    >
      <Icon className="max-h-4.5 max-w-4.5 h-full w-full" />
    </button>
  );
}
