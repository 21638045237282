import { Tab as HeadlessTab } from '@headlessui/react';
import clsx from 'clsx';
import { createContext, Fragment, PropsWithChildren, useContext } from 'react';

type TabGroupContext = {
  /**
   * @default "default"
   * @description By default tabs are justified to the start of the container. The fitted variant grows
   * the tabs to fill the available space
   */
  variant?: 'default' | 'fitted';
};
const TabGroupContext = createContext<TabGroupContext | undefined>(undefined);
const useTabGroupContext = () => {
  const context = useContext(TabGroupContext);
  if (!context) {
    throw new Error('useTabGroupContext must be used within a Tab.Group');
  }
  return context;
};

type GroupProps = PropsWithChildren<{
  /**
   * @description The default selected index
   */
  defaultIndex?: number;
  /**
   * @description The selected index if you want to use the Tabs component as a controlled component.
   */
  selectedIndex?: number;
  /**
   * @description A function called whenever the active tab changes.
   */
  onChange?: (index: number) => void;
  /**
   * @description When true, the user can only display a panel via the keyboard by first navigating to it using the arrow keys, and then by pressing Enter or Space. By default, panels are automatically displayed when navigated to via the arrow keys. Note that this prop has no affect on mouse behavior.
   */
  manual?: boolean;
}> &
  TabGroupContext;

function Group({ variant = 'default', ...props }: GroupProps) {
  return (
    <TabGroupContext.Provider value={{ variant }}>
      <HeadlessTab.Group {...props} />
    </TabGroupContext.Provider>
  );
}

type ListProps = PropsWithChildren<{
  className?: string;
}>;

function List({ className, ...props }: ListProps) {
  const { variant } = useTabGroupContext();
  return (
    <HeadlessTab.List
      {...props}
      className={clsx('flex', { 'gap-6': variant === 'default' }, className)}
    />
  );
}

type TabProps = PropsWithChildren<{
  className?: string;
  /**
   * @type {boolean}
   * @description Whether or not the Tab is currently disabled.
   */
  disabled?: boolean;
}>;

function TabRoot({ children, className, ...props }: TabProps) {
  const { variant } = useTabGroupContext();
  return (
    <HeadlessTab {...props} as={Fragment}>
      {({ selected }) => (
        <button
          className={clsx(
            'border border-transparent py-3 text-gray-500',
            'focus-visible:border-x-primary-600 focus-visible:border-t-primary-600 focus-visible:text-gray-900 focus-visible:outline-none',
            'active:border-x-transparent active:border-t-transparent active:text-gray-900 active:outline-none',
            'hover:border-b-primary-600 hover:text-gray-900',
            'disabled:cursor-not-allowed disabled:border-b-transparent disabled:text-gray-400 disabled:hover:text-gray-400',
            {
              'border-b-[3px] border-b-transparent': !selected,
              'border-b-primary-600 border-b-[3px] text-gray-900': selected,
              'flex-grow px-4': variant === 'fitted',
            },
            className,
          )}
        >
          {children}
        </button>
      )}
    </HeadlessTab>
  );
}

type PanelsProps = PropsWithChildren<{
  className?: string;
}>;

function Panels(props: PanelsProps) {
  return <HeadlessTab.Panels {...props} />;
}

type PanelProps = PropsWithChildren<{
  className?: string;
}>;

function Panel(props: PanelProps) {
  return <HeadlessTab.Panel {...props} />;
}

export const Tab = Object.assign(TabRoot, {
  Group,
  List,
  Panels,
  Panel,
});
