import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import { type ReactNode } from 'react';

import { Tooltip } from './Tooltip';

export type FormTooltipProps = {
  children: string | ReactNode;
  className?: string;
};
export const FormTooltip = ({ children, className }: FormTooltipProps) => {
  return (
    <Tooltip>
      <Tooltip.Trigger>
        <button className={className} type="button">
          <span className="sr-only">More info</span>
          <QuestionMarkCircleIcon className="h-4.5 w-4.5 fill-info-700" />
        </button>
      </Tooltip.Trigger>
      <Tooltip.Content>
        <span className="q-text-body-xs">{children}</span>
      </Tooltip.Content>
    </Tooltip>
  );
};
