import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useUser } from '#hooks/api/useUser';

/**
 * Used to redirect users to the verify page if they are not verified
 * Using a component instead of a hook so that it can be used in _app.tsx, inside of an Auth Context
 */
export const VerifiedRedirect = () => {
  const { data: user } = useUser();

  const { push, asPath, pathname } = useRouter();

  useEffect(() => {
    if (user?.isVerified === false && !pathname.includes('/verify')) {
      push({ pathname: '/verify', query: { redirectTo: asPath } });
    }
  }, [push, asPath, user?.isVerified, pathname]);
  return null;
};
