import { SvgAttr as SvgAttribute } from '../../utils/icon.interface';

export function ApplePay(props: SvgAttribute) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="18"
      {...props}
      fill="none"
    >
      <title>ApplePay</title>
      <path
        d="M8.097 1.566C9.279.008 10.923 0 10.923 0s.245 1.466-.93 2.877c-1.254 1.508-2.68 1.261-2.68 1.261s-.268-1.186.784-2.572Zm-.634 3.599c.608 0 1.738-.837 3.207-.837 2.53 0 3.526 1.8 3.526 1.8s-1.947.996-1.947 3.411c0 2.724 2.425 3.663 2.425 3.663s-1.695 4.772-3.985 4.772c-1.051 0-1.869-.71-2.977-.71-1.108 0-2.25.736-2.98.736C2.642 18 0 13.474 0 9.836c0-3.639 2.236-5.458 4.333-5.458 1.363 0 2.421.787 3.13.787Z"
        fill="#999"
      />
    </svg>
  );
}
