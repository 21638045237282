import {
  datadogLogs,
  HandlerType,
  LogsInitConfiguration,
} from '@datadog/browser-logs';
import {
  datadogRum,
  RumEventDomainContext,
  RumFetchResourceEventDomainContext,
  RumInitConfiguration,
} from '@datadog/browser-rum';
import { User } from '@qogita/canary-types';

import { flushErrorQueue, flushWarningQueue } from './browserLogger';
import { Queue } from './queue';

// TODO: move env handling to shared env package
if (!process.env.NEXT_PUBLIC_SERVICE_NAME) {
  throw new Error('NEXT_PUBLIC_SERVICE_NAME is not defined');
}
if (!process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL) {
  throw new Error('NEXT_PUBLIC_VERCEL_BRANCH_URL is not defined');
}
if (!process.env.NEXT_PUBLIC_VERCEL_ENV) {
  throw new Error('NEXT_PUBLIC_VERCEL_ENV is not defined');
}

// We don't set these env variables in the `.env` file
// this means that during tests they are unset and would error.
// Ideally we would set some fake values in a `.env.test` file
// but currently seller browser tests break if we do that.
// I've opted to just skip the check when `VERCEL_ENV` is development
// and set the VERCEL_ENV to `development` in the `.env` file.
if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'development') {
  if (!process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN) {
    throw new Error('NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN is not defined');
  }
  if (!process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID) {
    throw new Error('NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID is not defined');
  }
}

const SERVICE_NAME = process.env.NEXT_PUBLIC_SERVICE_NAME;
const VERCEL_BRANCH_URL = process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL;
const VERCEL_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV;
const DATADOG_RUM_CLIENT_TOKEN =
  process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN;
const DATADOG_RUM_APPLICATION_ID =
  process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID;

export const serviceNameByVercelEnvironment: {
  [key: string]: string;
} = {
  production: SERVICE_NAME,
  preview: VERCEL_BRANCH_URL,
  development: 'localhost',
};

export const datadogEnvironmentByVercelEnvironment: {
  [key: string]: string;
} = {
  production: 'prod',
  preview: 'test',
  development: 'dev',
};

const sharedConfiguration = {
  clientToken: DATADOG_RUM_CLIENT_TOKEN ?? '',
  site: 'datadoghq.eu',
  service: serviceNameByVercelEnvironment[VERCEL_ENV],
  version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  env: datadogEnvironmentByVercelEnvironment[VERCEL_ENV],
} as const;

const logsConfiguration = {
  ...sharedConfiguration,
  forwardErrorsToLogs: true,
} satisfies LogsInitConfiguration;

const isFetchResourceEventDomainContext = (
  context: RumEventDomainContext,
): context is RumFetchResourceEventDomainContext => {
  if (Object.hasOwn(context, 'requestInput')) {
    return true;
  }
  return false;
};

const getQogitaTraceHeader = (context: RumFetchResourceEventDomainContext) => {
  if (Array.isArray(context.requestInit?.headers)) {
    return context.requestInit?.headers.find((header) =>
      header.includes('x-qogita-trace'),
    )?.[1];
  }
};

const rumConfiguration = ({
  protocol,
  host,
}: {
  protocol: string;
  host: string;
}) =>
  ({
    ...sharedConfiguration,
    applicationId: DATADOG_RUM_APPLICATION_ID ?? '',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      `${protocol}//${host}`,
      'https://api.qogita.com',
      'https://api.test.qogita.com',
    ],
    enableExperimentalFeatures: ['feature_flags'],
    beforeSend(event, context) {
      if (isFetchResourceEventDomainContext(context)) {
        const qogitaTrace = getQogitaTraceHeader(context);

        event.context = {
          ...event.context,
          qogitaTrace,
        };
      }
    },
  }) satisfies RumInitConfiguration;

/**
 * We only load datadog after consent is given
 * so we need to queue up any logs that are sent before datadog is initialized.
 * This queue is flushed when datadog is initialized.
 *
 * Datadog doesn't yet have its own queueing mechanism for logs.
 */
export let isDatadogInitialized = false;

export const initDatadog = () => {
  datadogRum.init(
    rumConfiguration({
      host: window.location.host,
      protocol: window.location.protocol,
    }),
  );
  datadogRum.startSessionReplayRecording();

  datadogLogs.init(logsConfiguration);
  datadogLogs.logger.setHandler(
    process.env.NODE_ENV === 'production'
      ? HandlerType.http
      : HandlerType.console,
  );
  isDatadogInitialized = true;
  flushErrorQueue();
  flushWarningQueue();
  flushIdentifyUserQueue();
};

const identifyUserQueue = new Queue<User>();

/**
 * Sets the user in the logger and rum sessions
 * @param user The user to identify in the logs and rum sessions.
 * Will queue the user if datadog is not initialized.
 */
export function identifyUser(user: User) {
  if (isDatadogInitialized) {
    _identifyUser(user);
  } else {
    identifyUserQueue.enqueue(user);
  }
}

function _identifyUser(user: User) {
  const newUser = {
    id: user.qid,
    email: user.email,
    name: user.account,
  };

  datadogRum.setUser(newUser);
  datadogLogs.setUser(newUser);
}

const flushIdentifyUserQueue = () => {
  identifyUserQueue.flush((user) => {
    _identifyUser(user);
  });
};

/**
 * Clears the user from the logger and rum sessions.
 */
export function clearUser() {
  datadogRum.clearUser();
  datadogLogs.clearUser();
}

export { datadogLogs, datadogRum };
