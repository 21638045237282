import { removeSpaces } from '../../Utils';
import { DeprecatedFieldDescriptionProps } from './DeprecatedFieldDescription';
import type {
  DeprecatedFormLegendProps,
  FormLabelProps,
} from './DeprecatedFieldLabel';

/**
 * @description This matches JS falsy. It allows consumers to infer false without matching `undefined`
 */
type FalsyLike<T> = T | false | null | undefined;

type FormFieldBaseOptions = {
  /**
   * @description UI label to display
   */
  label: string;
  /**
   * @description Stop consumer interaction (stop error UI)
   */
  disabled?: boolean;
  /**
   * @description Show supportive label hints
   */
  required?: boolean;
  /**
   * @description Display description above control
   */
  description?: FalsyLike<string>;
  /**
   * @description The consumer needs to handle 'className' this hooks does/should not pass this on!
   */
  className?: never;
  /**
   * @description Tooltip to display on the label
   */
  tooltip?: string | JSX.Element;
};

/**
 * @deprecated
 */
export type DeprecatedFormFieldInternalParam = FormFieldBaseOptions & {
  fieldset: false;
  /**
   * @description Overrides the internal id being used
   */
  id?: string;
  /**
   * @description Each field must be unique
   */
  name: string;
  /**
   * @description Display field error message
   */
  errorMessage?: FalsyLike<string>;
};
/**
 * @deprecated
 */
export type DeprecatedFormFieldsetInternalParam = FormFieldBaseOptions & {
  /**
   * @description Makes is clear to this wraps fields
   */
  fieldset: true;
  /**
   * @description Required since 'name' is not available, and there is no unique value available
   *
   * TODO: Make this optional when useId() is implemented
   */
  id: string;
  /**
   * @description Not required since nested fields manage there own name's
   */
  name?: never;
  /**
   * @description Fieldset is only a wrapper and does not control errors (only legends, labels and descriptions values)
   */
  errorMessage?: never;
};

/**
 * @deprecated
 */
export type DeprecatedFormFieldParam = Omit<
  DeprecatedFormFieldInternalParam,
  'fieldset' | 'className'
>;
/**
 * @deprecated
 */
export type FormFieldSetParam = Omit<
  DeprecatedFormFieldsetInternalParam,
  'fieldset' | 'className'
>;

/**
 * @deprecated
 * @description Control props available from fieldset parent
 */
export type DeprecatedFormFieldsetOptions = {
  disabled: boolean;
  required: boolean;
};

type FormFieldOptions =
  | DeprecatedFormFieldsetInternalParam
  | DeprecatedFormFieldInternalParam;

/**
 * @deprecated
 */
export function useDeprecatedFormField(fieldOptions: FormFieldOptions) {
  const isFieldset = isFieldsetGuard(fieldOptions);

  const {
    id,
    required = false,
    disabled = false,
    ...options
  } = {
    ...fieldOptions,
    id: getFieldId(fieldOptions),
  };

  /**
   * @description Internal ids used for accessibility.
   */
  const internalIds: Record<`${'field' | 'description' | 'error'}Id`, string> =
    {
      fieldId: `${id}-field`,
      descriptionId: `${id}-description`,
      errorId: `${id}-error`,
    };

  const isRequired: boolean = disabled ? false : !!required;

  /**
   * @description Required props for field
   */
  const getFieldProps = () => {
    const isDisabled: boolean = isFieldset && disabled;

    return {
      id: internalIds.fieldId,
      as: isFieldset ? ('fieldset' as const) : ('div' as const),
      disabled: isDisabled || undefined, // Avoid false disabled in dom
    };
  };

  /**
   * @description Required props for label
   */
  const getLabelProps = (): FormLabelProps | DeprecatedFormLegendProps => {
    const legendPayload: DeprecatedFormLegendProps = {
      tooltip: options.tooltip,
      children: options.label,
    };

    const labelPayload: FormLabelProps = {
      tooltip: options.tooltip,
      children: options.label,
      required,
      htmlFor: id,
    };

    return isFieldset ? legendPayload : labelPayload;
  };

  /**
   * @description Shows supporting information about the control
   */
  const getDescriptionProps = (): DeprecatedFieldDescriptionProps | null => {
    if (!options.description) return null;

    return {
      fieldset: isFieldset,
      id: internalIds.descriptionId,
      children: options.description,
    };
  };

  /**
   * @description Required props nested form elements
   */
  const getFieldsetOptions = (): DeprecatedFormFieldsetOptions | null => {
    if (!isFieldset) return null;

    return {
      disabled,
      required: isRequired,
    };
  };

  /**
   * @description Required props for field control
   */
  const getControlProps = () => {
    if (isFieldset || !options.name) return null;

    const hasError: boolean = disabled ? false : !!options.errorMessage;

    return {
      id,
      name: options.name,
      disabled,
      error: hasError,
      'aria-invalid': hasError,
      'aria-required': isRequired,
      'aria-describedby': options.description
        ? internalIds.descriptionId
        : undefined,
      'aria-errormessage': hasError ? internalIds.errorId : undefined,
    };
  };

  /**
   * @description Required props for field error
   */
  const getErrorProps = () => {
    if (isFieldset || !options.errorMessage) return null;

    return {
      id: internalIds.errorId,
      children: options.errorMessage,
    };
  };

  const fieldProps = getFieldProps();
  const labelProps = getLabelProps();
  const descriptionProps = getDescriptionProps();
  const fieldsetOptions = getFieldsetOptions();
  const controlProps = getControlProps();
  const fieldErrorProps = getErrorProps();

  return {
    fieldProps,
    labelProps,
    descriptionProps,
    fieldsetOptions,
    controlProps,
    fieldErrorProps,
  };
}

function isFieldsetGuard(
  options: FormFieldOptions,
): options is DeprecatedFormFieldsetInternalParam {
  return 'fieldset' in options && !!options.fieldset;
}

/**
 * @description 'options' might not always return a valid 'id', this ensures type-safety.
 *
 * This hook depends on a unique 'id' for accessibility!
 */
const getFieldId = (options: FormFieldOptions): string => {
  const isFieldsetForm = isFieldsetGuard(options);

  if (isFieldsetForm) return options.id;

  // Replace with useId() at some point when react is v18
  return options.id ?? `field-${removeSpaces(options.name)}`;
};
