import cookie, { type CookieSerializeOptions } from 'cookie';

export const getCookie = (
  documentCookie: string,
  storageKey: string,
): string | null => {
  const cookies = cookie.parse(documentCookie);
  return cookies[storageKey] ?? null;
};

/**
 * Sets cookie directly in the browser.
 * Only to be used client-side, don't use this on the server.
 */
export const setBrowserCookie = (
  storageKey: string,
  value: string,
  options?: CookieSerializeOptions,
) => {
  // we need to set the cookie. CookieStore isn't available in firefox
  // eslint-disable-next-line unicorn/no-document-cookie
  document.cookie = cookie.serialize(storageKey, value, options);
};

export const removeBrowserCookie = (storageKey: string) => {
  // we need to set the cookie. CookieStore isn't available in firefox
  // eslint-disable-next-line unicorn/no-document-cookie
  document.cookie = cookie.serialize(storageKey, '', {
    maxAge: -1,
  });
};
