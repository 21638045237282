import { logError } from '@qogita/logging';
import { ImageLoader } from 'next/legacy/image';

import { slugify } from './slugify';

type GetUrlPathname = (url: string) => string;

export const getUrlPathname: GetUrlPathname = (url) => {
  try {
    const pathname = new URL(url).pathname;
    return pathname;
  } catch (error) {
    logError(error, {
      functionArgs: { url },
    });
    return '';
  }
};

export const vercelImageLoader: ImageLoader = ({
  src,
  width,
  quality = 75,
}) => {
  return `${src}?w=${width}&q=${quality}`;
};

/**
 * Function to construct slugified array from nested category string
 * @param categoryString string representation of nested category, e.g. Health & Beauty > Personal Care > Cosmetics > Perfume & Cologne
 * @returns slugified array of category strings, e.g. ['health-beauty', 'personal-care', 'cosmetics']
 */
export const getCategorySlugArray = (categoryString: string) => {
  const nestedCategoryArray = categoryString.split(' > ');
  const slugArray = nestedCategoryArray.map((category) => slugify(category));

  return slugArray;
};
