import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import {
  createContext,
  ElementType,
  Fragment,
  PropsWithChildren,
  useContext,
} from 'react';

import { noop } from '../../Utils';

type ModalContext = {
  onClose?: () => void;
};
const ModalContext = createContext<ModalContext | null>(null);

const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(
      '[Qogita UI] Modal components must be used within a <Modal>',
    );
  }
  return context;
};

type PanelProps = PropsWithChildren<{ className?: string }>;
function Panel({ className, ...props }: PanelProps) {
  return (
    <div className="fixed inset-0 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Dialog.Panel
            {...props}
            className={clsx(
              'sm:min-w-51 relative w-full rounded bg-white shadow-xl sm:w-auto sm:max-w-[50%]',
              className,
            )}
          />
        </Transition.Child>
      </div>
    </div>
  );
}

type HeaderProps = PropsWithChildren<{
  className?: string;
}>;
function Header({ children, className }: HeaderProps) {
  const { onClose } = useModalContext();

  const isCloseButtonVisible = onClose !== undefined;

  return (
    <div
      className={clsx(
        'flex justify-between border-b p-4 sm:px-6 sm:py-5',
        className,
      )}
    >
      {children}
      {isCloseButtonVisible ? (
        <button
          onClick={onClose}
          title="Close"
          aria-label="Close"
          className="outline-primary-700 rounded outline-2"
          type="button"
        >
          <XMarkIcon className="h-7 w-7 fill-gray-500" />
        </button>
      ) : null}
    </div>
  );
}

type TitleProps = PropsWithChildren<{
  as?: ElementType;
  className?: string;
}>;
function Title({ className, ...props }: TitleProps) {
  return <Dialog.Title {...props} className={clsx('font-bold', className)} />;
}

type BodyProps = PropsWithChildren<{ className?: string }>;
function Body({ className, ...props }: BodyProps) {
  return <div {...props} className={clsx('p-4 sm:px-6 sm:py-8', className)} />;
}

type DescriptionProps = PropsWithChildren<{ className?: string }>;
function Description(props: DescriptionProps) {
  return <Dialog.Description {...props} />;
}

type FooterProps = PropsWithChildren<{ className?: string }>;
function Footer({ className, ...props }: FooterProps) {
  return (
    <div
      {...props}
      className={clsx('rounded-b bg-gray-50 p-4 sm:p-6', className)}
    />
  );
}

type ModalRootProps = PropsWithChildren<{
  open: boolean;
  onClose?: () => void;
  className?: string;
}>;
function ModalRoot({ open, children, className, ...props }: ModalRootProps) {
  return (
    <ModalContext.Provider value={{ onClose: props.onClose }}>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          {...props}
          onClose={props.onClose ?? noop}
          open={open}
          className={clsx('relative z-10', className)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-gray-900 bg-opacity-40 transition-opacity"
              aria-hidden="true"
            />
          </Transition.Child>
          {children}
        </Dialog>
      </Transition.Root>
    </ModalContext.Provider>
  );
}

export const Modal = Object.assign(ModalRoot, {
  Panel,
  Header,
  /**
   * @param children the text content in children will set the aria-labelledby on the Modal.
   */
  Title,
  Body,
  /**
   * @param children the text content in children will set the aria-describedby on the Modal.
   */
  Description,
  Footer,
});
