import clsx from 'clsx';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const SuccessBox = ({ children, className }: Props): JSX.Element => (
  <div
    className={clsx(
      'border-success-700 bg-success-100 text-success-700 rounded border',
      className,
    )}
  >
    {children}
  </div>
);
