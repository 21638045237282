import { AlertBanner, Anchor } from '@qogita/ui';

import { useUser } from '#hooks/api/useUser';
import { useTrackEvent } from '#lib/report/tracking';

const BannerContent = () => {
  const { trackContactBannerClicked } = useTrackEvent();
  return (
    <AlertBanner.Content>
      <AlertBanner.Title>
        Want to pre-order or discuss a large order?
      </AlertBanner.Title>
      <AlertBanner.Body>
        <Anchor
          href="mailto:support@qogita.com"
          target="_blank"
          onClick={() => {
            trackContactBannerClicked({
              bannerType: 'generic', //TODO: check this tracking event type for new banner
              contactType: 'email',
            });
          }}
        >
          Speak to support
        </Anchor>
      </AlertBanner.Body>
    </AlertBanner.Content>
  );
};

export const InfoBanner = () => {
  const { data: user } = useUser();
  if (user && user.accountManager) return null; //hide banner for key accounts as they already have a dedicated account manager contact link in the account menu

  return (
    <AlertBanner variant="neutral" className="min-h-[5rem] sm:min-h-full">
      <AlertBanner.Inner className="max-w-site-content">
        <AlertBanner.Icon />
        <BannerContent />
      </AlertBanner.Inner>
    </AlertBanner>
  );
};
