import { logError } from '@qogita/logging';
import axios from 'axios';

export const getAccessExpiry = () => {
  return Number(
    document.cookie
      .split('; ')
      .find((row) => row.startsWith('access-expiry='))
      ?.split('=')[1],
  );
};

// TODO: handle non-ok responses by logging the user out
const getRefreshToken = async () => {
  return fetch('/api/auth/refresh/', {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-qogita-trace': 'intercepted',
    },
  });
};

let refreshPromise: ReturnType<typeof getRefreshToken> | undefined = undefined;

axios.interceptors.request.use(async (request) => {
  // This header is set so that we can see in logs if a request
  // was successfully intercepted here.
  request.headers.set('x-qogita-auth-interceptor', 'intercepted');
  // TODO: use a library to get cookies
  const expiry = getAccessExpiry();

  // If we don't have an expiry then we're not authed and
  // should just try and make the request.
  if (!expiry) {
    return request;
  }
  // We give this a 10 second buffer so that we don't incorrectly regard a token
  // as valid that could become expired by the time canary gets the request.
  const isExpired = expiry - Date.now() < 10_000;

  if (isExpired) {
    if (!refreshPromise) {
      refreshPromise = getRefreshToken();
    }

    try {
      await refreshPromise;
      return request;
    } catch (error) {
      refreshPromise = undefined;
      logError(error);
    } finally {
      refreshPromise = undefined;
    }
  } else {
    return request;
  }

  throw new Error('Should not be able to get here');
});
