import { logError } from '@qogita/logging';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Layout } from '#components/Layout';
import { NextAnchor } from '#components/NextAnchor';
import { FetchError } from '#lib/error';

type Props = { children: ReactNode };
export function RootErrorBoundary({ children }: Props): JSX.Element {
  const { pathname } = useRouter();
  return (
    <ErrorBoundary
      onError={(error) => {
        if (error instanceof FetchError) {
          logError(error, error.context);
          return;
        }

        logError(
          new Error(`RootErrorBoundary: ${error.message}`, { cause: error }),
        );
      }}
      FallbackComponent={FallbackComponent}
      key={pathname}
    >
      {children}
    </ErrorBoundary>
  );
}

function FallbackComponent() {
  return (
    <Layout title="Something went wrong" description="">
      <div className="container mx-auto px-2 sm:px-4">
        <div className="rounded bg-white p-4 shadow">
          <h1 className="mb-4 font-bold">Something went wrong</h1>
          <p>
            We are working to resolve this. Please{' '}
            <NextAnchor href="/contact/">contact us</NextAnchor> if it persists.
          </p>
        </div>
      </div>
    </Layout>
  );
}
