// @ts-check
// This is a .mjs file, so that it can be imported into
// environment.mjs, which can be imported into next.config.mjs to get build
// time environment variable validation.
import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';
/**
 * Zod coerce.boolean acts simply. It pipes the value throught the Boolean() constructor, so "false" will be true.
 * We should allow to use "0", "false" as falsy and "1", "true" as truthy values!
 */
export const booleanEnvironmentSchema = z
  .enum(['0', '1', 'true', 'false', ''])
  .transform((value) => value === 'true' || value === '1')
  .default('false')
  .or(z.boolean().default(false))
  .or(z.number().transform((value) => value === 1));

/**
 * Function to create a ZodString method with trim
 * @returns a zod string with trim method
 */
export const trimmedString = () => z.string().trim();

/**
 * client-side variable object with zod schema
 */
const client = {
  NEXT_PUBLIC_ENABLE_MSW: booleanEnvironmentSchema,
  NEXT_PUBLIC_ENABLE_BOT_DETECTION: booleanEnvironmentSchema,
  NEXT_PUBLIC_VERCEL_URL: trimmedString(),
  NEXT_PUBLIC_BASE_URL: trimmedString().url(),
  NEXT_PUBLIC_API_BASE_URL: z.literal('/api/gateway'),
  NEXT_PUBLIC_IMAGE_BASE_URL: trimmedString().url(),
  NEXT_PUBLIC_MEILISEARCH_INDEX_NAME: z.enum([
    'qogita-catalog-dev',
    'qogita-catalog-prod',
    'qogita-catalog-preview',
  ]),
  NEXT_PUBLIC_MEILISEARCH_SUGGESTIONS_INDEX_NAME: z.literal(
    'qogita-catalog-suggestions',
  ),
  NEXT_PUBLIC_MEILISEARCH_URL: trimmedString().url(),
  NEXT_PUBLIC_MEILISEARCH_API_KEY: trimmedString(),
  NEXT_PUBLIC_SEGMENT_WRITE_KEY: trimmedString(),
  NEXT_PUBLIC_GTAG_ENABLED: booleanEnvironmentSchema,
  NEXT_PUBLIC_GA_MEASUREMENT_ID: trimmedString(),
  NEXT_PUBLIC_GOOGLE_ADS_TAG_ID: trimmedString(),
  NEXT_PUBLIC_UPTIME_ROBOT_READ_ONLY_API_KEY: trimmedString(),
  NEXT_PUBLIC_LOQATE_API_KEY: trimmedString(),
  NEXT_PUBLIC_SANITY_DATASET: trimmedString(),
  NEXT_PUBLIC_SANITY_PROJECT_ID: trimmedString(),
  NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID: trimmedString(),
  NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN: trimmedString(),
  NEXT_PUBLIC_DATADOG_RUM_ENABLED: booleanEnvironmentSchema,
  NEXT_PUBLIC_DATADOG_CSP_REPORT_TOKEN: trimmedString(),
  NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID: trimmedString(),
  NEXT_PUBLIC_CLAIM_BY_SALE_ENABLED: booleanEnvironmentSchema,
  NEXT_PUBLIC_ZAPIER_CONTACT_URL: z.literal(
    'https://hooks.zapier.com/hooks/catch/13184436/b7zeja8',
  ),
  // Vercel variables with defaults to allow local development
  NEXT_PUBLIC_VERCEL_ENV: z.enum(['development', 'preview', 'production']),
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA: trimmedString(),
  NEXT_PUBLIC_VERCEL_BRANCH_URL: trimmedString(),
  NEXT_PUBLIC_ENABLE_SUPPORT_WIDGET: booleanEnvironmentSchema,
};

/**
 * server-side variable object with zod schema
 */
const server = {
  API_BASE_DOMAIN: trimmedString().url(),
  SESSION_COOKIE_NAME: z.literal('qsession'),
  SESSION_PASSWORD: trimmedString().min(32),
  SITEMAP_BASE_URL: trimmedString().url(),
  CI: booleanEnvironmentSchema,
  MODE: trimmedString(),
  LAUNCHDARKLY_NODE_SDK_KEY: trimmedString(),
  DATADOG_NODE_API_KEY: trimmedString(),
  NODE_ENV: z.enum(['development', 'production', 'test', 'preview']),
  AWS_S3_REGION: trimmedString(),
  AWS_ACCESS_KEY_ID: trimmedString(),
  AWS_SECRET_ACCESS_KEY: trimmedString(),
  AWS_BUCKET_NAME: trimmedString(),
  // Vercel variables with defaults to allow local development
  VERCEL_ENV: z.enum(['development', 'preview', 'production']),
  VERCEL_GIT_COMMIT_SHA: trimmedString(),
};

/**
 * Environment object with manual declaration of runtime variables
 * (can't be destructured on the fly because of the way Next.js works)
 */
// Ignoring Prettier to not wrap the code to multiple lines so it's easier to read
// prettier-ignore
export const environment = createEnv({
  client,
  server,
  runtimeEnv: {
    DATADOG_NODE_API_KEY: process.env.DATADOG_NODE_API_KEY,
    API_BASE_DOMAIN: process.env.API_BASE_DOMAIN,
    SESSION_COOKIE_NAME: process.env.SESSION_COOKIE_NAME,
    SESSION_PASSWORD: process.env.SESSION_PASSWORD,
    SITEMAP_BASE_URL: process.env.SITEMAP_BASE_URL,
    CI: process.env.CI,
    MODE: process.env.MODE,
    LAUNCHDARKLY_NODE_SDK_KEY: process.env.LAUNCHDARKLY_NODE_SDK_KEY,
    NODE_ENV: process.env.NODE_ENV,
    VERCEL_ENV: process.env.VERCEL_ENV,
    AWS_S3_REGION: process.env.AWS_S3_REGION,
    AWS_ACCESS_KEY_ID: process.env.AWS_ACCESS_KEY_ID,
    AWS_SECRET_ACCESS_KEY: process.env.AWS_SECRET_ACCESS_KEY,
    AWS_BUCKET_NAME: process.env.AWS_BUCKET_NAME,
    VERCEL_GIT_COMMIT_SHA: process.env.VERCEL_GIT_COMMIT_SHA,
    // CLIENT SIDE ENVIRONMENT VARIABLES
    NEXT_PUBLIC_ENABLE_MSW: process.env.NEXT_PUBLIC_ENABLE_MSW,
    NEXT_PUBLIC_ENABLE_BOT_DETECTION: process.env.NEXT_PUBLIC_ENABLE_BOT_DETECTION,
    NEXT_PUBLIC_VERCEL_URL: process.env.NEXT_PUBLIC_VERCEL_URL,
    NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
    NEXT_PUBLIC_VERCEL_BRANCH_URL: process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL,
    NEXT_PUBLIC_BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
    NEXT_PUBLIC_API_BASE_URL: process.env.NEXT_PUBLIC_API_BASE_URL,
    NEXT_PUBLIC_IMAGE_BASE_URL: process.env.NEXT_PUBLIC_IMAGE_BASE_URL,
    NEXT_PUBLIC_MEILISEARCH_INDEX_NAME: process.env.NEXT_PUBLIC_MEILISEARCH_INDEX_NAME,
    NEXT_PUBLIC_MEILISEARCH_SUGGESTIONS_INDEX_NAME: process.env.NEXT_PUBLIC_MEILISEARCH_SUGGESTIONS_INDEX_NAME,
    NEXT_PUBLIC_MEILISEARCH_URL: process.env.NEXT_PUBLIC_MEILISEARCH_URL,
    NEXT_PUBLIC_MEILISEARCH_API_KEY: process.env.NEXT_PUBLIC_MEILISEARCH_API_KEY,
    NEXT_PUBLIC_SEGMENT_WRITE_KEY: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    NEXT_PUBLIC_GTAG_ENABLED: process.env.NEXT_PUBLIC_GTAG_ENABLED,
    NEXT_PUBLIC_GA_MEASUREMENT_ID: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
    NEXT_PUBLIC_GOOGLE_ADS_TAG_ID: process.env.NEXT_PUBLIC_GOOGLE_ADS_TAG_ID,
    NEXT_PUBLIC_UPTIME_ROBOT_READ_ONLY_API_KEY: process.env.NEXT_PUBLIC_UPTIME_ROBOT_READ_ONLY_API_KEY,
    NEXT_PUBLIC_LOQATE_API_KEY: process.env.NEXT_PUBLIC_LOQATE_API_KEY,
    NEXT_PUBLIC_SANITY_DATASET: process.env.NEXT_PUBLIC_SANITY_DATASET,
    NEXT_PUBLIC_SANITY_PROJECT_ID: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
    NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID: process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID,
    NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
    NEXT_PUBLIC_DATADOG_RUM_ENABLED: process.env.NEXT_PUBLIC_DATADOG_RUM_ENABLED,
    NEXT_PUBLIC_DATADOG_CSP_REPORT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_CSP_REPORT_TOKEN,
    NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID,
    NEXT_PUBLIC_CLAIM_BY_SALE_ENABLED: process.env.NEXT_PUBLIC_CLAIM_BY_SALE_ENABLED,
    NEXT_PUBLIC_ZAPIER_CONTACT_URL: process.env.NEXT_PUBLIC_ZAPIER_CONTACT_URL,
    NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    NEXT_PUBLIC_ENABLE_SUPPORT_WIDGET: process.env.NEXT_PUBLIC_ENABLE_SUPPORT_WIDGET,
  },
});
