import type { User } from '@qogita/canary-types';
import { PatchedUserRequest } from '@qogita/canary-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAuthentication } from '#contexts/Authentication';
import { useAuthenticatedFetch } from '#hooks/api/useAuthenticatedFetch';
import { environment } from '#lib/environment.mjs';

import { queryKeys } from './queryKeys';

type Variables = {
  data: PatchedUserRequest;
};

type PatchUsers = (params: Variables) => Promise<User>;

const ERROR_MESSAGE_CATCH_ALL =
  'We were unable to update your preferences. Please try again or speak to a member of our team if the issue persists.';
const ERROR_MESSAGE_INVALID_NUMBER =
  'The phone number you entered is invalid. Please try again or speak to a member of our team if the issue persists.';

const usePatchUser = () => {
  const authenticatedFetch = useAuthenticatedFetch();
  const patchUser: PatchUsers = async ({ data }) => {
    const response = await authenticatedFetch(
      `${environment.NEXT_PUBLIC_API_BASE_URL}/user/`,
      {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    );

    if (response.ok) {
      return response.json();
    }
    const { code, ...rest } = await response.json();
    if (response.status === 400 && code === 'invalid' && rest.lineNumber)
      throw new Error(ERROR_MESSAGE_INVALID_NUMBER);
    throw new Error(ERROR_MESSAGE_CATCH_ALL);
  };
  return patchUser;
};

export const usePatchUpdateUser = () => {
  const queryClient = useQueryClient();
  const patchUser = usePatchUser();
  const { status } = useAuthentication();
  return useMutation<User, TypeError | Error, Variables>({
    mutationFn: ({ data }) => patchUser({ data }),
    onSuccess: (user) => {
      queryClient.setQueryData(
        queryKeys.users.currentUser(status).queryKey,
        user,
      );
      queryClient.invalidateQueries();
    },
  });
};
