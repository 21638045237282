import { createContext, useContext } from 'react';

export const NavigationDepthContext = createContext(0);
export const IsCollapsedContext = createContext(false);
export const SetIsCollapsedContext = createContext<null | React.Dispatch<
  React.SetStateAction<boolean>
>>(null);
export const IsActiveContext = createContext<boolean | null>(null);

/**
 * @returns Returns the current depth of the navigation tree
 */
export function useNavigationDepth() {
  return useContext(NavigationDepthContext);
}

export function useIsCollapsedContext() {
  return useContext(IsCollapsedContext);
}

export function useSetIsCollapsedContext() {
  const collapse = useContext(SetIsCollapsedContext);
  if (!collapse) {
    throw new Error('SetIsCollapsedContext is not defined');
  }
  return collapse;
}

export function useIsSectionActiveContext() {
  const isSectionActive = useContext(IsActiveContext);
  if (isSectionActive === null) {
    throw new Error('IsActiveContext is not defined');
  }
  return isSectionActive;
}

export function useIsActiveContext() {
  const isActive = useContext(IsActiveContext);
  return Boolean(isActive);
}
