import { useRouter } from 'next/router';
import { ParsedUrlQueryInput } from 'querystring';
import { useCallback } from 'react';
import { SchemaOf } from 'yup';

/**
 * Either a new query object or a function to derive a new query object from the previous one.
 */
type Updater<T> = T | ((previousValue: T) => T);

type NavigationOptions = {
  /**
   * The navigation type, either 'push' (default) or 'replace'.
   */
  navigationType?: 'push' | 'replace';
  /**
   * Flag to indicate whether the navigation should be shallow or not (defaults to false).
   */
  shallow?: boolean;
  /**
   * Flag to enable scrolling to the updated URL (defaults to true).
   */
  scroll?: boolean;
  /**
   * If provided the URL path will be updated too. Otherwise onlythe query params will be updated
   */
  pathname?: string;
};

/**
 * A convenience hook for getting and setting the url params
 * Works very similarly to `useState` but for url params
 * The setter accepts a new query object or a function to derive a new query object from the previous one.
 * To bail out of an update, return the previous query object.
 *
 * Inspiration for this taken from React Router useSearchParams https://reactrouter.com/en/main/hooks/use-search-params
 *
 * We don't accept an initializer because our pages render on the server
 */
export function useQueryParams<T extends ParsedUrlQueryInput>({
  validationSchema,
}: {
  validationSchema: SchemaOf<T>;
}) {
  const { query: currentRawQuery, push, replace } = useRouter();

  const validatedQuery = validationSchema.validateSync(currentRawQuery);

  const setQueryParams = useCallback(
    /**
     * Set the URL query parameters based on the provided updater function or query object.
     * If the updater is a function, it receives the previous query object as an argument and should return a new query object.
     * If the updater is a query object, it replaces the current query object.
     *
     */
    function setQueryParams(
      updater: Updater<T>,
      {
        navigationType = 'push',
        shallow = false,
        scroll = true,
        pathname,
      }: NavigationOptions = {},
    ) {
      const updatedQuery =
        typeof updater === 'function'
          ? // @ts-expect-error - I can't find a way to fix this type error
            updater(validatedQuery)
          : updater;

      // Bail early if the query hasn't changed
      if (updatedQuery === validatedQuery) {
        return;
      }

      // If any other parameter except page changes, we want to reset pagination
      const shouldResetPagination = Object.keys(updatedQuery).some(
        (key) => key !== 'page' && updatedQuery[key] !== validatedQuery[key],
      );
      if (shouldResetPagination) {
        delete updatedQuery.page;
      }

      // If the consumer provided a pathname, we want to update that too
      const url: { pathname?: string; query: ParsedUrlQueryInput } = {
        query: updatedQuery,
      };
      if (pathname) {
        url.pathname = pathname;
      }

      const navigate = navigationType === 'push' ? push : replace;

      navigate(url, undefined, { shallow, scroll });
    },
    [push, replace, validatedQuery],
  );

  return [validatedQuery, setQueryParams] as const;
}
