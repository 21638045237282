import { createIcon } from '#components/Icon';

export const VisaIcon = createIcon(
  <>
    <g clipPath="url(#visa-clip-path)" fillRule="evenodd" clipRule="evenodd">
      <path d="M0 .333h27v18H0v-18Z" fill="#0D0508" />
      <path d="M.792 17.527h25.416V1.14H.791v16.388Z" fill="#fff" />
      <path d="M1.878 5.933h23.173V2.5H1.878v3.433Z" fill="#323490" />
      <path d="M1.878 16.295h23.173v-3.433H1.878v3.433Z" fill="#F2982E" />
      <path
        d="M24.402 15.48a.13.13 0 0 0-.102-.046h-.216v.522h.05v-.224h.11l.149.224h.06l-.144-.224a.105.105 0 0 0 .085-.029.15.15 0 0 0 .044-.1.19.19 0 0 0-.036-.123Zm-.268.191v-.175h.148c.02 0 .043-.001.059.014s.026.04.027.069a.112.112 0 0 1-.023.076c-.016.017-.04.016-.063.016h-.148Zm.338-.365a.426.426 0 0 0-.427 0 .43.43 0 0 0-.214.376.441.441 0 0 0 .214.376.425.425 0 0 0 .427 0 .43.43 0 0 0 .214-.376.436.436 0 0 0-.214-.376Zm.093.196a.37.37 0 0 1 0 .362.362.362 0 0 1-.309.181.362.362 0 0 1-.356-.362.363.363 0 0 1 .356-.362.353.353 0 0 1 .309.181Z"
        fill="#fff"
      />
      <path
        d="M9.993 6.924h1.189l-3.226 5.325H6.554l-.127-4.213c-.006-.192-.01-.39-.082-.542a.692.692 0 0 0-.33-.335 1.138 1.138 0 0 0-.515-.115v-.12h2.314l.113 3.625 2.066-3.625ZM11.412 12.25l1.656-5.326h-1.474L9.938 12.25h1.474ZM12.268 11.799c.224.23.512.392.906.47.394.08.893.077 1.367-.005.473-.082.92-.244 1.237-.489.316-.245.5-.574.591-.93.09-.355.085-.737-.105-1.036-.19-.3-.565-.514-.877-.716-.311-.202-.56-.393-.664-.607a.578.578 0 0 1 .06-.604c.121-.153.321-.22.567-.236.246-.015.538.02.78.09.243.069.435.17.6.308l.491-.797c-.227-.166-.487-.284-.853-.37a4.348 4.348 0 0 0-1.25-.112c-.41.03-.76.148-1.07.357-.31.208-.579.507-.73.83a1.349 1.349 0 0 0-.092.965c.092.299.311.551.537.732.225.18.458.29.662.414.204.126.38.266.475.435.095.17.108.367-.006.53-.114.163-.355.294-.633.34a1.979 1.979 0 0 1-.885-.082 2.399 2.399 0 0 1-.799-.422l-.309.935ZM17.785 11.017l-.667 1.233h-1.189l3.162-5.326h1.34l.452 5.326h-1.395l-.098-1.233h-1.605Zm1.399-2.586-1.024 1.893h1.174l-.15-1.893ZM21.367 7.02a.128.128 0 0 0-.102-.046h-.216v.522h.05v-.224h.11l.149.224h.061l-.145-.224a.105.105 0 0 0 .085-.029.15.15 0 0 0 .044-.1.189.189 0 0 0-.036-.124Zm-.268.191v-.175h.148c.02 0 .043 0 .059.014.016.015.026.04.027.069a.111.111 0 0 1-.023.076c-.016.017-.04.016-.063.016h-.148Zm.338-.365a.425.425 0 0 0-.428 0 .43.43 0 0 0-.214.376.437.437 0 0 0 .214.376.426.426 0 0 0 .428 0 .43.43 0 0 0 .213-.376.442.442 0 0 0-.213-.376Zm.093.196a.37.37 0 0 1 0 .362.355.355 0 0 1-.486.133.362.362 0 0 1-.178-.314.366.366 0 0 1 .178-.314.355.355 0 0 1 .355 0 .362.362 0 0 1 .13.133Z"
        fill="#323490"
      />
    </g>
    <defs>
      <clipPath id="visa-clip-path">
        <path fill="#fff" transform="translate(0 .333)" d="M0 0h27v18H0z" />
      </clipPath>
    </defs>
  </>,
  'VisaIcon',
  '0 0 27 19',
);
