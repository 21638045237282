import clsx from 'clsx';

import { SvgAttr as SvgAttribute } from '../utils/icon.interface';
import { COLOR_THEME_MAP } from './QogitaLogoContained';

type EmblemProps = SvgAttribute & {
  /**
   * 'white-on-primary': White text with indigo background
   *
   * 'primary-on-white': Indigo text with white background
   *
   * 'primary-on-gray': Indigo text with gray background
   *
   * 'black-on-gray': Black text with gray background
   *
   * 'black-on-white': Black text with white background
   *
   * 'white-on-black': White text with black background
   */
  theme: keyof typeof COLOR_THEME_MAP;
};

export function QogitaLogoEmblem({ theme, className, ...props }: EmblemProps) {
  const selectedTheme = COLOR_THEME_MAP[theme];

  return (
    <svg
      {...props}
      className={clsx('fill-transparent', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 44"
    >
      <title>Qogita</title>

      <path
        className={selectedTheme.backgroundColor}
        d="M22 44c12.15 0 22-9.85 22-22S34.15 0 22 0 0 9.85 0 22s9.85 22 22 22Z"
      />

      <path
        className={selectedTheme.textColor}
        d="M34.566 31.207a10.916 10.916 0 0 0-4.53-2.73l.136-.454a2.248 2.248 0 0 0 2.039-.497c1.16-1.045 1.96-3.481 1.96-6.065a12.202 12.202 0 0 0-1.196-5.24 12.151 12.151 0 0 0-3.328-4.21 12.059 12.059 0 0 0-10.163-2.403c-4.56.96-8.246 4.664-9.205 9.243a12.208 12.208 0 0 0 2.392 10.212 12.115 12.115 0 0 0 4.197 3.343 12.064 12.064 0 0 0 5.221 1.198c2.79 0 4.724-.818 5.738-1.729 1.014-.91.926-2.436.926-2.436l.404-.095.021.067c.53 1.666 1.45 3.18 2.682 4.416l2.481 2.501L37 33.658l-2.434-2.451ZM22.09 29.263a7.739 7.739 0 0 1-4.312-1.314 7.788 7.788 0 0 1-2.858-3.498 7.826 7.826 0 0 1 1.683-8.495 7.732 7.732 0 0 1 8.459-1.69 7.769 7.769 0 0 1 3.483 2.87 7.82 7.82 0 0 1-.967 9.841 7.753 7.753 0 0 1-5.488 2.286Z"
      />
    </svg>
  );
}
