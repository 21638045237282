import type { ElementType } from 'react';

import { IsActiveContext, useNavigationDepth } from './context';
import { NavigationInsetMargin } from './NavigationInsetMargin';
import {
  NavigationListItem,
  NavigationListItemProps,
} from './NavigationListItem';

type NavigationItemProps<C extends ElementType> = NavigationListItemProps<C>;

export function NavigationItem<C extends ElementType>({
  children,
  isActive,
  ...componentProps
}: NavigationItemProps<C>) {
  const navigationDepth = useNavigationDepth();

  return (
    <IsActiveContext.Provider value={isActive}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore TODO: fix types */}
      <NavigationListItem {...componentProps} isActive={isActive}>
        {/*
          This fragment is important to prevent Playwright Component tests
          from incorrectly breaking during build when Link components are used with multiple children
         */}
        <>
          <NavigationInsetMargin insetLevel={navigationDepth} />
          {children}
        </>
      </NavigationListItem>
    </IsActiveContext.Provider>
  );
}
