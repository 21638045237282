import Image from 'next/image';
import { useState } from 'react';

import freeShipping from '/public/shipping/freeshipping.webp';
import { Modal } from '#components/Modal';
import { useTrackEvent } from '#lib/report/tracking';
import { vercelImageLoader } from '#lib/url';

export const ShippingBanner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { trackShippingBannerModalViewed } = useTrackEvent();

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="bg-primary-700 flex w-full justify-center py-1">
        <div className="q-text-body-xs flex gap-4 text-white">
          <span className="q-text-body-xs-bold">
            Shipping included with all orders
          </span>
          <button
            onClick={() => {
              trackShippingBannerModalViewed();
              setIsModalOpen(true);
            }}
            className="cursor-pointer text-white underline"
          >
            Learn more
          </button>
        </div>
      </div>
      <Modal open={isModalOpen} onClose={handleModalClose} size="normal">
        <Modal.Header>
          <Modal.Title>Shipping is now included</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex max-w-xl flex-col gap-6">
            <Image
              src={freeShipping}
              alt="graphic of lorry driving on road"
              loader={vercelImageLoader}
            />
            <div>
              <span className="text-gray-700">
                We restructured our pricing model. This means that from now on:
              </span>
              <ul className="list-inside list-disc pl-2.5 text-gray-700 marker:text-sm">
                <li>Shipping costs are included in unit prices</li>
                <li>It is easier to calculate product margins</li>
                <li>
                  Improved trade-offs between pricing and shipping = better
                  value for you
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
