import { ReactNode } from 'react';

type FieldProps = {
  as?: 'div' | 'fieldset';
  children: ReactNode;
  className?: string;
};

/**
 *
 * @deprecated
 */
export function DeprecatedField({ as = 'div', ...props }: FieldProps) {
  const isFieldset = as === 'fieldset';

  return isFieldset ? <fieldset {...props} /> : <div {...props} />;
}
