import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { ComponentProps, forwardRef, ReactNode } from 'react';

import { developmentDisruptError } from '../../Utils';

export type BreadcrumbProps = ComponentProps<'a'> & {
  children: ReactNode;
  /**
   * @description Typed optional but must be required!
   *
   * Since packages such as NextJs <Link /> use cloneElement to 'pass-on' href. So this needs to be optional until a better approach can be found.
   */
  href?: string;
  current?: boolean;
};

export const Breadcrumb = forwardRef<HTMLLIElement, BreadcrumbProps>(
  function BreadcrumbWithoutRef(breadcrumbProps, ref) {
    if (typeof breadcrumbProps.href !== 'string') {
      developmentDisruptError(
        `'href' is required! Received '${breadcrumbProps.href}' instead of a string.\n\nSome libraries internally pass on href, however Typescript cannot know this (hence optional type).`,
      );
    }

    const { current = false, className, ...props } = breadcrumbProps;

    return (
      <li ref={ref} className={className}>
        <div className="flex items-center">
          <ChevronRightIcon className="h-5 w-5 flex-shrink-0 rotate-180 text-gray-400 sm:rotate-0" />

          <a
            {...props}
            className="ml-1 text-sm font-medium text-gray-500 hover:text-gray-700 sm:ml-4"
            aria-current={current ? 'page' : undefined}
          />
        </div>
      </li>
    );
  },
);

Breadcrumb.displayName = 'Breadcrumb';
