export type FetchErrorContext = {
  statusCode: number;
  responseBody: unknown;
  url: string;
};

export class FetchError extends Error {
  constructor(
    message: string,
    public context: FetchErrorContext,
  ) {
    // We add a prefix to the message so we can identify it in datadog
    // React will always log thrown errors to the console, so we'll see them in the logs
    // even if we handle them with an error boundary
    const messagePrefix = 'QogitaFetchError';
    super(`${messagePrefix}: ${message}`);
    this.name = 'FetchError';
  }
}

export class PermissionDeniedError extends FetchError {
  constructor(message: string, context: Omit<FetchErrorContext, 'statusCode'>) {
    super(message, { ...context, statusCode: 403 });
  }
}

type DisallowedUserCurrencyErrorContext = {
  currency: string;
};

export class DisallowedUserCurrencyError extends Error {
  constructor(
    message: string,
    public context: DisallowedUserCurrencyErrorContext,
  ) {
    super(message);
    this.name = 'DisallowedUserCurrencyError';
  }
}
