import { Drawer } from '@qogita/ui';
import Link from 'next/link';
import { PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';

import { getCategorySlugArray } from '#lib/url';

type NavLinkListItemProps = PropsWithChildren<{ categoryPath: string }>;

type CategoriesBannerContext = {
  onClose: () => void;
};

const CategoriesBannerContext = createContext<CategoriesBannerContext | null>(
  null,
);

const useCategoriesBannerContext = () => {
  const context = useContext(CategoriesBannerContext);

  if (!context)
    throw new Error(
      'CategoriesBanner component should be used inside a <CategoriesBanner> component',
    );

  return context;
};

const NavLinkListItem = ({ categoryPath, children }: NavLinkListItemProps) => {
  const context = useCategoriesBannerContext();
  const slugArray = getCategorySlugArray(categoryPath);
  return (
    <li className="flex">
      <Link
        onClick={context.onClose}
        href={{
          pathname: '/categories/[...category]',
          query: {
            category: slugArray,
          },
        }}
        className="w-full whitespace-nowrap p-2 pl-8 text-2xl sm:p-4 sm:text-base sm:font-bold"
      >
        {children}
      </Link>
    </li>
  );
};

const CategoryListItems = () => (
  <>
    <NavLinkListItem categoryPath="Health & Beauty > Personal Care > Cosmetics > Perfume & Cologne">
      Perfume &amp; Cologne
    </NavLinkListItem>
    <NavLinkListItem categoryPath="Health & Beauty > Personal Care > Cosmetics > Make-Up">
      Make Up
    </NavLinkListItem>
    <NavLinkListItem categoryPath="Health & Beauty > Personal Care > Haircare">
      Hair Care
    </NavLinkListItem>
    <NavLinkListItem categoryPath="Health & Beauty > Personal Care > Cosmetics > Skincare">
      Skin Care
    </NavLinkListItem>
    <NavLinkListItem categoryPath="Health & Beauty > Personal Care > Cosmetics > Nail Care">
      Nail Care
    </NavLinkListItem>
    <NavLinkListItem categoryPath="Health & Beauty > Personal Care > Cosmetics > Bath & Body">
      Bath & Body
    </NavLinkListItem>
  </>
);

type CategoriesBannerProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const CategoriesBanner = ({
  isOpen,
  onClose,
}: CategoriesBannerProps) => {
  return (
    <CategoriesBannerContext.Provider value={{ onClose }}>
      <div className="sm:hidden">
        <Drawer isOpen={isOpen} onClose={onClose}>
          <Drawer.Backdrop />
          <Drawer.Content>
            <ul className="flex w-full flex-col justify-center gap-6 text-2xl">
              <CategoryListItems />
            </ul>
          </Drawer.Content>
        </Drawer>
      </div>
      <div className="hidden justify-center overflow-auto border-b border-gray-900 sm:flex">
        <ul className="align-center flex w-full max-w-5xl flex-row justify-between space-x-6 whitespace-nowrap p-0">
          <CategoryListItems />
        </ul>
      </div>
    </CategoriesBannerContext.Provider>
  );
};
