import { createIcon } from '#components/Icon';

export const CheckCircleIcon = createIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22.5a10.5 10.5 0 1 0 0-21 10.5 10.5 0 0 0 0 21Zm0 1.5a12 12 0 1 0 0-24 12 12 0 0 0 0 24Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.455 7.455a1.125 1.125 0 0 1 1.607 1.575l-5.989 7.485a1.124 1.124 0 0 1-1.62.03l-3.967-3.969a1.125 1.125 0 1 1 1.59-1.59l3.141 3.14 5.21-6.638a.352.352 0 0 1 .03-.033h-.002Z"
    />
  </>,
  'CheckCircleIcon',
  '0 0 24 24',
);
