import clsx from 'clsx';

import { NavigationAdornment } from './NavigationAdornment';
import { NavigationIcon } from './NavigationIcon';
import { NavigationInsetMargin } from './NavigationInsetMargin';
import { NavigationItem } from './NavigationItem';
import { NavigationSection } from './NavigationSection';
import { NavigationSectionHeading } from './NavigationSectionHeading';
import { NavigationSectionItems } from './NavigationSectionItems';

type RootProps = React.HTMLProps<HTMLUListElement>;

function Root({ className, ...rest }: RootProps) {
  return <ul className={clsx('flex flex-col', className)} {...rest} />;
}

export const Navigation = Object.assign(Root, {
  Item: NavigationItem,
  Section: NavigationSection,
  SectionHeading: NavigationSectionHeading,
  SectionItems: NavigationSectionItems,
  Adornment: NavigationAdornment,
  Icon: NavigationIcon,
  InsetMargin: NavigationInsetMargin,
});
