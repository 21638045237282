import { load } from '@fingerprintjs/botd';

export const getIsBotUser = async () => {
  return load({ monitoring: false })
    .then((botd) => botd.detect())
    .then(({ bot }) => {
      return bot;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
};
